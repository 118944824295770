<template>
<b-modal :id="this.modalID"
          :title="$t(this.modalTitle)"
          :ok-title="$t(this.modalOK)"
          :cancel-title="$t('Cancel')"
          :busy="form.busy"
          class="admin__catalogdata"
          @ok="handleOk">
    <div role="group">
      <label :for="`${this.ID}-input-name`" class="mb-0">{{ $t('Name') }}</label>
      <b-form-input
        :id="`${this.ID}-input-name`"
        v-model="form.name.data"
        :state="form.name.state"
        :required="true"
        class="mb-2"
        trim
      ></b-form-input>
      <b-form-invalid-feedback :id="`${this.ID}-input-name-feedback`">
        {{ form.name.error }}
      </b-form-invalid-feedback>

      <label :for="`${this.ID}-input-label`" class="mb-0">{{ $t('Label') }}</label>
      <LabelDropdown :id="`${this.ID}-input-label`"
                     :selectedLabel.sync="form.label.data" />
      <b-form-invalid-feedback :id="`${this.ID}-input-label-feedback`">
        {{ form.name.error }}
      </b-form-invalid-feedback>

      <label :for="`${this.ID}-input-file`" class="mb-0">{{ $t('File') }}</label>
      <b-form-file
        :id="`${this.ID}-input-file`"
        v-model="form.file.data"
        :state="form.file.state"
        :required="true"
        class="mb-2"
        trim
      ></b-form-file>
      <b-form-invalid-feedback :id="`${this.ID}-input-file-feedback`">
        {{ form.file.error }}
      </b-form-invalid-feedback>
  </div>
</b-modal>
</template>

<script>
import LabelDropdown from '@/components/admin/LabelDropdown'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'CatalogDataModal',
  components: {
    LabelDropdown
  },
  props: {
    catalog: [Object, Boolean]
  },
  data () {
    return {
      form: {
        name: {
          data: this.catalog ? this.catalog.name : '',
          state: null,
          error: null
        },
        label: {
          data: this.catalog ? this.catalog.label : null,
          state: null,
          error: null
        },
        file: {
          data: null,
          state: null,
          error: null
        },
        busy: false
      }
    }
  },
  computed: {
    ...mapState({
      labels: state => state.products.labels
    }),
    ID () {
      if (!this.catalog) {
        return 'create-'
      }
      return `${this.catalog.id}-`
    },
    modalID () {
      if (!this.catalog) {
        return 'modal-add-catalog'
      }
      return `edit-catalog-${this.catalog.id}`
    },
    modalTitle () {
      if (!this.catalog) {
        return this.$t('Add Catalog')
      }
      return this.$t('Edit Catalog')
    },
    modalOK () {
      if (!this.catalog) {
        return this.$t('Add')
      }
      return this.$t('Save')
    }
  },
  methods: {
    ...mapActions({
      createCatalog: 'cms/createCatalog',
      editCatalog: 'cms/editCatalog',
      fetchCatalogs: 'cms/fetchCatalogs'
    }),
    handleOk (event) {
      event.preventDefault()
      this.form.busy = true
      this.handleSubmit()
    },
    handleSubmit () {
      const catalogData = {
        name: this.form.name.data,
        product_label_id: this.form.label.data,
        file: this.form.file.data
      }
      if (this.catalog) {
        catalogData.id = this.catalog.id
        this.editCatalog(catalogData).then(response => {
          this.handleResponse(response)
        })
      } else {
        this.createCatalog(catalogData).then(response => {
          this.handleResponse(response)
        })
      }
    },
    handleResponse (response) {
      if (response.status === 'ok') {
        // TO-DO: Display success notification
        this.fetchCatalogs()
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalID)
        })
      } else {
        Object.keys(response.error.data.errors).forEach(key => {
          this.form[key].state = false
          this.form[key].error = response.error.data.errors[key][0]
        })
      }
      this.form.busy = false
    }
  }
}
</script>

<style lang="scss">
.admin__catalogdata {
  $vs-border-radius: 0;
  $vs-border-color: #ced4da;
  $vs-selected-bg: white;
  $vs-component-placeholder-color: var(--primary);
  $vs-state-active-bg: white;
  $vs-state-active-color: var(--primary);
  $vs-controls-color: var(--primary);
  $vs-component-line-height: 1.65;
  @import "vue-select/src/scss/vue-select.scss";
  @include style-select(true);
}
</style>
