<template>
<div class="admin__catalogs">
  <div class="w-100 d-flex flex-row justify-content-between">
    <h2>{{ $t('Catalog') }}</h2>
    <b-button variant="primary" v-b-modal.modal-add-catalog>{{ $t('Add') }}</b-button>
  </div>
  <b-table striped
            :items="catalogsData"
            :fields="fields.catalogs"
            :busy="catalogsMeta.isBusy">
    <template v-slot:head(product_label_name)="data">
      {{ data.label }} <LabelDropdown :selectedLabel.sync="catalogsLocalFilterLabel" />
    </template>
    <template v-slot:head(name)="data">
      {{ data.label }} <b-form-input autocomplete="off" v-model="catalogsLocalFilterName"></b-form-input>
    </template>

    <template v-slot:cell(actions)="data">
      <div style="">
        <b-button size="sm" variant="danger" @click="deleteCatalogAction(data.item)">
        <b-icon-trash />
        </b-button>

        <b-button size="sm" variant="primary" @click="editCatalogAction(data.item)">
          <b-icon-pencil-square />
        </b-button>

      </div>
      <!-- TO-DO: Look into lazy loading this -->
      <CatalogDataModal :catalog="data.item" />
    </template>
  </b-table>
    <b-row class="pagination-container col-12 justify-content-center">
      <b-pagination
        v-model="catalogsLocalCurrentPage"
        :total-rows="catalogsMeta.count"
        :per-page="catalogsMeta.rowsPerPage"
        :hide-ellipsis="true"
        :limit="7"
      >

      <template v-slot:first-text><span class="start">{{ $t('Start') }}</span></template>
      <template v-slot:prev-text><b-img class="arrow arrow-prev" :src="require('@/assets/icons/arrow.png')"></b-img></template>
      <template v-slot:next-text><b-img class="arrow arrow-right" :src="require('@/assets/icons/arrow.png')"></b-img></template>
      <template v-slot:last-text><span class="end">{{ $t('End') }}</span></template>
    </b-pagination>
  </b-row>

  <CatalogDataModal :user="false" />
</div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import CatalogDataModal from '@/components/admin/CatalogDataModal'
import LabelDropdown from '@/components/admin/LabelDropdown'

export default {
  name: 'CatalogList',
  components: {
    CatalogDataModal,
    LabelDropdown
  },
  data () {
    return {
      fields: {
        catalogs: [
          {
            key: 'product_label_name'
          },
          {
            key: 'name'
          },
          {
            key: 'actions'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      catalogsData: state => state.cms.catalogsData,
      catalogsMeta: state => state.cms.catalogsMeta,
      catalogsFilter: state => state.cms.catalogsFilter,
      labels: state => state.products.labels
    }),
    catalogsLocalCurrentPage: {
      get () {
        return this.catalogsMeta.currentPage
      },
      set (value) {
        this.setCatalogsCurrentPage({ currentPage: value })
      }
    },
    catalogsLocalFilterName: {
      get () {
        return this.catalogsFilter.name
      },
      set (value) {
        this.setCatalogsFilter({ name: value })
        this.eventuallyFetchCatalogs()
      }
    },
    catalogsLocalFilterLabel: {
      get () {
        return this.catalogsFilter.label
      },
      set (value) {
        this.setCatalogsFilter({ label: value })
        this.eventuallyFetchCatalogs()
      }
    }
  },
  created () {
    this.fetchCatalogs()
  },
  methods: {
    ...mapActions({
      setCatalogsCurrentPage: 'cms/setCatalogsCurrentPage',
      setCatalogsFilter: 'cms/setCatalogsFilter',
      eventuallyFetchCatalogs: 'cms/eventuallyFetchCatalogs',
      fetchCatalogs: 'cms/fetchCatalogs',
      createCatalog: 'cms/createCatalog',
      deleteCatalog: 'cms/deleteCatalog'
    }),
    editCatalogAction (catalog) {
      console.log('editCatalogAction', catalog.id)
      this.$bvModal.show(`edit-catalog-${catalog.id}`)
    },
    deleteCatalogAction (catalog) {
      // TO-DO: Notification and/or confirmation
      this.deleteCatalog(catalog.id)
    }
  }
}
</script>

<style lang="scss">
.admin__catalogs {
  .pagination {
    .arrow {
      &-prev {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
