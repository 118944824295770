<template>
<div class="admin__cms__frontpage">
  <div class="admin__cms__header d-flex flex-row justify-content-between mb-2">
    <div class="d-flex flex-row">
      <h2 class="mr-2">{{ pslug }}</h2>
      <b-form-select v-model="selectedLanguage" :options="this.languages"></b-form-select>
    </div>
    <div>
        <b-button @click="save">{{$t('Save')}}</b-button>
    </div>
  </div>
  <section class="content" v-if="this.staticPages[pslug] && this.staticPages[pslug][selectedLanguage]">
    <b-form-input v-model="title" placeholder="Title" class="mb-2"></b-form-input>
    <Editor
      api-key="no-api-key"
      :init="editorOpts"
      v-model="editorContent"
    />  </section>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import 'tinymce/models/dom'
import 'tinymce-i18n/langs6/de'

import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'CMSStaticPageAdmin',
  components: {
    Editor
  },
  props: {
    pslug: String
  },
  data () {
    return {
      selectedLanguage: 'de',
      editorInstance: null,
      editorContent: '',
      editorOpts: {
        content_css: false,
        skin: false,
        menubar: 'edit view insert format',
        language: 'de'
      }
    }
  },
  watch: {
    selectedLanguage (newVal) {
      this.fetchStaticPage({ pslug: this.pslug, language: this.selectedLanguage }).then(response => {
        this.initializeEditor()
      })
    },
    pslug (newVal) {
      this.fetchStaticPage({ pslug: this.pslug, language: this.selectedLanguage }).then(response => this.initializeEditor())
    }
  },
  computed: {
    ...mapFields('cms', ['staticPages']),
    ...mapState({
      languages: state => state.cms.languages
    }),
    // TO-DO: As unfortunately mapFields from vuex-map-fields does not work with nested objects, we have to use this 'hack'
    // we need to reingeer this and find a better solution
    title: {
      get: function () {
        return this.staticPages[this.pslug][this.selectedLanguage].title
      },
      set: function (newValue) {
        this.setStaticPageAttribute({ pslug: this.pslug, language: this.selectedLanguage, attribute: 'title', value: newValue })
      }
    },
    content: {
      get: function () {
        return this.staticPages[this.pslug][this.selectedLanguage].content
      },
      set: function (newValue) {
        this.setStaticPageAttribute({ pslug: this.pslug, language: this.selectedLanguage, attribute: 'content', value: newValue })
      }
    }
  },
  mounted () {
    this.fetchStaticPage({ pslug: this.pslug, language: this.selectedLanguage }).then(response => this.initializeEditor())
  },
  methods: {
    ...mapActions({
      fetchStaticPage: 'cms/fetchStaticPage',
      setStaticPageAttribute: 'cms/setStaticPageAttribute',
      saveStaticPage: 'cms/saveStaticPage'
    }),
    initializeEditor () {
      console.log('initializeEditor')
      this.editorContent = this.content
    },
    save () {
      this.content = this.editorContent
      this.saveStaticPage({ pslug: this.pslug, language: this.selectedLanguage })
      this.$bvToast.toast(`${this.pslug} ${this.selectedLanguage} successfully saved.`, {
        title: 'Success',
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'success'
      })
    }
  }
}
</script>

<style lang="scss">
@import '~tinymce/skins/ui/oxide/skin.min.css';
@import '~tinymce/skins/ui/oxide/content.min.css';
@import '~tinymce/skins/content/default/content.min.css';

.tox-promotion,
.tox-statusbar__branding {
  display: none !important;
}
</style>
