import {
  SET_USER,
  AUTHORIZE,
  SET_AUTHORIZING,
  UNSET_AUTHORIZING,
  DEAUTHORIZE,
  UNSET_USER
} from '@/store/mutation-types'

import client from 'api-client'

export default {
  namespaced: true,
  state: {
    user: null,
    isLoggedIn: false,
    isAuthorizing: false,
    isAdmin: false
  },
  mutations: {
    [SET_USER] (state, payload) {
      state.user = payload
    },
    [AUTHORIZE] (state, payload) {
      state.isLoggedIn = true
      state.user = payload.user
      if (state.user.roles.includes('administrator')) {
        state.isAdmin = true
      } else {
        state.isAdmin = false
      }
      state.isAuthorizing = false
    },
    [UNSET_USER] (state) {
      state.user = null
    },
    [DEAUTHORIZE] (state) {
      state.isLoggedIn = false
    },
    [SET_AUTHORIZING] (state) {
      state.isAuthorizing = true
    },
    [UNSET_AUTHORIZING] (state) {
      state.isAuthorizing = false
    }
  },
  actions: {
    authorize ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit(SET_AUTHORIZING)

        client.authorize(payload).then(response => {
          if (response.status === 'ok') {
            const resp = JSON.parse(response.response.request.response)
            commit(AUTHORIZE, {
              user: resp.user
            })
            dispatch('products/fetchLatestProducts', null, { root: true })
          }

          commit(UNSET_AUTHORIZING)
          resolve(response)
        })
      })
    },
    changePassword (context, payload) {
      // STUB: implement
      console.log(context)
      console.log(payload)
      return true
    },
    requestUserDataChange ({ dispatch }, payload) {
      return client
        .requestUserDataChange(payload)
    },
    deauthorize (context, payload) {
      return new Promise((resolve, reject) => {
        client.deauthorize(payload).then(response => {
          if (response.status === 'ok') {
            context.commit(DEAUTHORIZE)
            context.commit(UNSET_USER)
          }
          resolve(response)
        })
      })
    }
  }
}
