// auth
export const SET_USER = 'setUser'
export const UNSET_USER = 'unsetUser'
export const AUTHORIZE = 'authorize'
export const DEAUTHORIZE = 'deauthorize'
export const SET_AUTHORIZING = 'setAuthorizing'
export const UNSET_AUTHORIZING = 'unsetAuthorizing'

// products
export const SET_PRODUCTS = 'setProducts'
export const SET_PRODUCTS_FILTER = 'setProductsFilter'
export const SET_PRODUCTS_CURRENT_PAGE = 'setProductsCurrentPage'
export const SET_PRODUCTS_META = 'setProductsMeta'
export const SET_PRODUCT_MENU_GROUPS = 'setProductMenuGroups'
export const SET_PRODUCT_GROUPS = 'setProductGroups'
export const SET_HIGHLIGHTED_PRODUCTS = 'setHighlightedProducts'
export const SET_LATEST_PRODUCTS = 'setLatestProducts'

export const SET_ORDERHISTORY = 'setOrderHistory'
export const SET_FAVOURITES = 'setFavourites'

export const ADD_TO_CART = 'addToCart'
export const MODIFY_CART_AMOUNT = 'modifyCartAmount'
export const MODIFY_CART_PRICE = 'modifyCartPrice'
export const CLEAR_CART = 'clearCart'
export const REMOVE_UNKNOWN_FROM_CART = 'removeUnknownFromCart'

export const SET_REPORTS = 'setReports'

// cms
export const SET_LABELS = 'setLabels'
export const SET_BADGES = 'setBadges'
export const SET_CATALOGS = 'setCatalogs'
export const SET_STATIC_PAGE = 'setStaticPage'
export const SET_LANGUAGES = 'setLanguages'
export const SET_STATIC_PAGE_ATTRIBUTE = 'setStaticPageAttribute'
export const ADD_SLIDE_TO_HERO_SLIDER = 'addSlideToHeroSlider'
export const REMOVE_SLIDE_FROM_HERO_SLIDER = 'removeSlideFromHeroSlider'
export const ADD_SLIDE_TO_CENTER_MODE_SLIDER = 'addSlideToCenterModeSlider'
export const REMOVE_SLIDE_FROM_CENTER_MODE_SLIDER = 'removeSlideFromCenterModeSlider'
export const SET_PUBLIC_FRONT_PAGE = 'setPublicFrontPage'
export const SET_CATALOGS_FILTER = 'setCatalogsFilter'
export const SET_LABEL_HIGHLIGHTS = 'setLabelHighlights'

// admin
export const SET_BLACKLIST = 'setBlacklist'
export const SET_BLACKLIST_ISBUSY = 'setBlacklistIsBusy'
export const SET_USERS = 'setUsers'
export const SET_USERS_CURRENT_PAGE = 'setUsersCurrentPage'
export const SET_USERS_FILTER = 'setUsersFilter'
export const SET_USERS_ISBUSY = 'setUsersIsBusy'
export const SET_ADMIN_META = 'setAdminMeta'
export const SET_ALL_USER_ROLES = 'setAllUserRoles'
export const SET_FTPS = 'setFTPs'
export const SET_FTPS_CURRENT_PAGE = 'setFTPsCurrentPage'
export const SET_FTPS_FILTER = 'setFTPsFilter'
export const SET_FTPS_ISBUSY = 'setFTPsIsBusy'
