<template>
<div class="admin__users">
  <div class="w-100 d-flex flex-row justify-content-between">
    <h2>{{ $t('Users') }}</h2>
    <b-button variant="primary" v-b-modal.modal-add-user>{{ $t('Add') }}</b-button>
  </div>
  <b-table striped
            :items="usersData"
            :fields="fields.users"
            :busy="usersMeta.isBusy">
    <template v-slot:head(username)="data">
      {{ data.label }} <b-form-input autocomplete="off" v-model="usersLocalFilterUsername"></b-form-input>
    </template>
    <template v-slot:head(name)="data">
      {{ data.label }} <b-form-input autocomplete="off" v-model="usersLocalFilterName"></b-form-input>
    </template>
    <template v-slot:head(email)="data">
      {{ data.label }} <b-form-input autocomplete="off" v-model="usersLocalFilterEmail"></b-form-input>
    </template>
    <template v-slot:head(role_names)="data">
      {{ data.label }} <v-select id="input-0"
                                 :components="{OpenIndicator}"
                                 :clearable="true"
                                 :options="userRolesOptions"
                                 :reduce="role => role.id"
                                 v-model="usersLocalFilterRole"
                                 ></v-select>
    </template>

    <template v-slot:cell(actions)="data">
      <div v-if="data.item.deleted_at === null">
        <b-button size="sm" variant="danger" @click="deleteUserAction(data.item)">
        <b-icon-trash />
        </b-button>

        <b-button size="sm" variant="primary" @click="editUserAction(data.item)">
          <b-icon-pencil-square />
        </b-button>

      </div>
      <div v-else >
        <b-button size="sm" variant="primary" @click="restoreUserAction(data.item)">
          <b-icon-bootstrap-reboot />
        </b-button>
      </div>
      <UserDataModal :user="data.item" />
      <UserDisableModal :user="data.item" />
      <UserRestoreModal :user="data.item" />
    </template>
  </b-table>
    <b-row class="pagination-container col-12 justify-content-center">
      <b-pagination
        v-model="usersLocalCurrentPage"
        :total-rows="usersMeta.count"
        :per-page="usersMeta.rowsPerPage"
        :hide-ellipsis="true"
        :limit="7"
      >

      <template v-slot:first-text><span class="start">{{ $t('Start') }}</span></template>
      <template v-slot:prev-text><b-img class="arrow arrow-prev" :src="require('@/assets/icons/arrow.png')"></b-img></template>
      <template v-slot:next-text><b-img class="arrow arrow-right" :src="require('@/assets/icons/arrow.png')"></b-img></template>
      <template v-slot:last-text><span class="end">{{ $t('End') }}</span></template>
    </b-pagination>
  </b-row>

  <UserDataModal :user="false" />
</div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import UserDataModal from '@/components/admin/UserDataModal'
import UserDisableModal from '@/components/admin/UserDisableModal'
import UserRestoreModal from '@/components/admin/UserRestoreModal'

export default {
  name: 'UserList',
  components: {
    UserDataModal,
    UserDisableModal,
    UserRestoreModal
  },
  data () {
    return {
      OpenIndicator: {
        render: createElement => createElement('img', { attrs: { src: require('@/assets/icons/arrow.png') } })
      },
      fields: {
        users: [
          {
            key: 'username'
          },
          {
            key: 'name'
          },
          {
            key: 'email'
          },
          {
            key: 'role_names',
            label: this.$t('Roles'),
            formatter: this.roleFormat
          },
          {
            key: 'created_at',
            formatter: this.dateFormat
          },
          {
            key: 'last_login',
            formatter: this.dateFormat
          },
          {
            key: 'actions'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      usersData: state => state.admin.usersData,
      usersMeta: state => state.admin.usersMeta,
      usersFilter: state => state.admin.usersFilter,
      userRoles: state => state.admin.userRoles
    }),
    userRolesOptions: function () {
      return this.userRoles.map(e => {
        return { id: e.id, label: e.name }
      })
    },
    usersLocalCurrentPage: {
      get () {
        return this.usersMeta.currentPage
      },
      set (value) {
        this.setUsersCurrentPage({ currentPage: value })
      }
    },
    usersLocalFilterUsername: {
      get () {
        return this.usersFilter.username
      },
      set (value) {
        this.setUsersFilter({ username: value })
        this.eventuallyFetchUsers()
      }
    },
    usersLocalFilterName: {
      get () {
        return this.usersFilter.name
      },
      set (value) {
        this.setUsersFilter({ name: value })
        this.eventuallyFetchUsers()
      }
    },
    usersLocalFilterEmail: {
      get () {
        return this.usersFilter.email
      },
      set (value) {
        this.setUsersFilter({ email: value })
        this.eventuallyFetchUsers()
      }
    },
    usersLocalFilterRole: {
      get () {
        return this.usersFilter.role
      },
      set (value) {
        this.setUsersFilter({ role: value })
        this.eventuallyFetchUsers()
      }
    }
  },
  created () {
    this.fetchUsers()
    this.fetchAllUserRoles()
  },
  methods: {
    ...mapActions({
      setUsersCurrentPage: 'admin/setUsersCurrentPage',
      setUsersFilter: 'admin/setUsersFilter',
      eventuallyFetchUsers: 'admin/eventuallyFetchUsers',
      fetchUsers: 'admin/fetchUsers',
      fetchAllUserRoles: 'admin/fetchAllUserRoles',
      createUser: 'admin/createUser',
      deleteUser: 'admin/deleteUser'
    }),
    dateFormat (value, key, item) {
      const t = new Date(value)
      // TO-DO: localize this
      return t.toLocaleString('de-DE')
    },
    roleFormat (value, key, item) {
      return value.join(', ')
    },
    editUserAction (user) {
      this.$bvModal.show(`edit-user-${user.id}`)
    },
    deleteUserAction (user) {
      this.$bvModal.show(`disable-user-${user.id}`)
    },
    restoreUserAction (user) {
      this.$bvModal.show(`restore-user-${user.id}`)
    }
  }
}
</script>

<style lang="scss">
.admin__users {
    $vs-border-radius: 0;
    $vs-border-color: #ced4da;
    $vs-selected-bg: white;
    $vs-component-placeholder-color: var(--primary);
    $vs-state-active-bg: white;
    $vs-state-active-color: var(--primary);
    $vs-controls-color: var(--primary);
    $vs-component-line-height: 1.65;
    @import "vue-select/src/scss/vue-select.scss";
    @include style-select(true);

    .pagination-container {
      .arrow-prev {
        transform: rotate(180deg);
      }
    }
}
</style>
