<template>
<div>
  <div class="d-flex justify-content-center">
    <div class="catalogs col-12 col-lg-8">
      <div class="catalogs__header">
        <h2>{{ $t('Catalogs')}}</h2>
        <a href="#">{{$t('Stock list')}} <b-img :src="require('@/assets/icons/download.png')"></b-img></a>
      </div>
        <div role="tablist" class="accordion">
          <b-card :key="index" v-for="(label, index) in groupedCatalogs" no-body>
            <b-card-header block v-b-toggle="getAccordionID(index)" header-tag="header" class="p-1" role="tab">
              <span>{{ index }}</span>
              <b-img :src="require('@/assets/icons/plus.png')"></b-img>
            </b-card-header>
            <b-collapse :id="getAccordionID(index)" visible accordion="catalogs-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text :key="catalog.id" v-for="catalog in label">
                  <span>{{ catalog.name }}</span>
                  <div>
                    <a v-if="catalog.filter" :href="catalog.filter"><b-img :src="require('@/assets/icons/filter.png')" ></b-img></a>
                    <a :href="catalog.file_path"><b-img style="margin-left: 15px;" :src="require('@/assets/icons/download.png')" ></b-img></a>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
     </div>
    </div>
  <Labels />
  <Badges />
  <Callout :action="calloutAction"
           :title="$t('EAN/BARCODE BESTELLUNG')"
           text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua" />
  </div>
</template>

<script>

import Labels from '@/components/cms/Labels'
import Badges from '@/components/cms/Badges'
import Callout from '@/components/cms/Callout'

import _ from 'lodash'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'Catalogs',
  components: {
    Labels,
    Badges,
    Callout
  },
  data () {
    return {
      calloutAction: {
        type: 'ean_order',
        text: this.$t('Nummer Eingeben')
      }
    }
  },
  computed: {
    ...mapState({
      catalogsData: state => state.cms.catalogsData,
      catalogsMeta: state => state.cms.catalogsMeta
    }),
    groupedCatalogs: function () {
      return _.groupBy(this.catalogsData, catalog => catalog.product_label_name)
    }
  },
  methods: {
    ...mapActions({
      fetchCatalogs: 'cms/fetchCatalogs'
    }),
    getAccordionID (index) {
      return 'accordion-' + _.kebabCase(index)
    }
  },
  created () {
    this.fetchCatalogs()
  }
}
</script>

<style lang="scss">
.catalogs {
  margin-bottom: 145px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;

    h2 {
      text-transform: uppercase;
    }
  }

  .accordion {
    .card {
      &-header {
        text-transform: uppercase;
      }
    }
  }
}
</style>
