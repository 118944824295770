<template>
<div class="label_dropdown-container">
<v-select id="label-dropdown"
    :components="{OpenIndicator}"
    :clearable="true"
    :options="catalogLabelOptions"
    :reduce="label => label.id"
    v-model="catalogsLocalFilterLabel"
    @search="fetchOptions"
    :filterable="false"
    @input="setSelected"
    ></v-select>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LabelDropdown',
  props: {
    selectedLabel: Number
  },
  data () {
    return {
      OpenIndicator: {
        render: createElement => createElement('img', { attrs: { src: require('@/assets/icons/arrow.png') } })
      },
      catalogsLocalFilterLabel: false,
      labels: []
    }
  },
  computed: {
    catalogLabelOptions: function () {
      return this.labels.map(e => {
        return { id: e.id, label: e.name }
      })
    }
  },
  created () {
    this.fetchLabels()
  },
  methods: {
    /**
     * Triggered when the search text changes.
     *
     * @param search  {String}    Current search text
     * @param loading {Function}  Toggle loading class
     */
    fetchOptions (search, loading) {
      if (!this.catalogsLocalFilterLabel) {
        loading(true)
        this.fetchLabels(search).then(() => {
          loading(false)
        })
      }
    },
    fetchLabels (search) {
      return axios.get('/api/labels', { params: { name: search } }).then((res) => {
        this.labels = res.data
      })
    },
    setSelected (value) {
      this.$emit('update:selectedLabel', value)
    }
  }
}
</script>

<style lang="scss">
.label_dropdown {
  &-container {
    $vs-border-radius: 0;
    $vs-border-color: #ced4da;
    $vs-selected-bg: white;
    $vs-component-placeholder-color: var(--primary);
    $vs-state-active-bg: white;
    $vs-state-active-color: var(--primary);
    $vs-controls-color: var(--primary);
    $vs-component-line-height: 1.65;
    @import "vue-select/src/scss/vue-select.scss";
    @include style-select(true);
  }
}
</style>
