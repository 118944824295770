
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import auth from '@/store/modules/auth'
import products from '@/store/modules/products'
import cms from '@/store/modules/cms'
import admin from '@/store/modules/admin'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    products: {
      productMenuGroups: state.products.productMenuGroups,
      cart: state.products.cart,
      cartCount: state.products.cartCount,
      cartValue: state.products.cartValue
    },
    auth: {
      user: state.auth.user,
      isAdmin: state.auth.isAdmin,
      isLoggedIn: state.auth.isLoggedIn
    },
    admin: {
      adminMeta: state.admin.adminMeta
    },
    cms: {
      languages: state.cms.languages
    }
  })
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth: auth,
    products: products,
    cms: cms,
    admin: admin
  },
  plugins: [vuexLocal.plugin]
})
