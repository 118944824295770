<template>
  <b-container class="badges">
    <b-row class="badges__list">
      <b-col cols="6" md="3" v-for="badge in badges" v-bind:key="badge.id" class="badges__badge">
        <b-img :src="require(`@/assets/mock/badges/${badge.id}.png`)"></b-img>
        <span class="badges__badge__line1">{{ badge.line1 }}</span>
        <span class="badges__badge__line2">{{ badge.line2 }}</span>
        <span class="badges__badge__line3">{{ badge.line3 }}</span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'Badges',
  computed: {
    ...mapState({
      badges: state => state.cms.badges
    })
  },
  methods: {
    ...mapActions({
      fetchBadges: 'cms/fetchBadges'
    })
  },
  created: function () {
    if (this.badges.length === 0) {
      this.fetchBadges()
    }
  }
}
</script>

<style lang="scss">
.badges {
  margin-bottom: 95px;

  &__badge {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      color: var(--secondary)
    }

    img {
      margin-bottom: 15px;
      height: 70px;
    }

    &__line1 {
      font-size: 20px;
    }

    &__line2 {
      font-size: 45px;
      text-align: center;
    }

    &__line3 {
      text-align: center;
    }
  }
}
</style>
