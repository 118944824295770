<template>
  <b-container class="eanorder">
    <b-row class="justify-content-center">
      <b-col cols="8">
        <h1>{{ $t('EAN/Barcode order')}}</h1>
        <div class="eanorder__insturctions">
          {{ $t('This form allows you to check a list of EANs on theier availabilty. You can then automatically add found items to your shopping cart. It will propose you items on stock if you check stock only, otherwise it proposes you the best offer from our database. You can also use a tab-separated list with EAN->quantity to add more than one item per EAN to your cart.')}}
        </div>
      </b-col>
    </b-row>

    <b-row align-h="center" class="eanorder__header">
      <b-col v-if="mode == 'barcodes'" cols="2">{{ $t('Filter')}}</b-col>
      <b-col v-if="mode == 'barcodes'" cols="3">{{ $t('EAN/Barcode')}}</b-col>
      <b-col v-else cols="3">{{ $t('SKU')}}</b-col>
      <b-col :cols="mode === 'barcodes' ? 4 : 6">{{ $t('Title/Artist')}}</b-col>
      <b-col cols="1">{{ $t('Amount')}}</b-col>
      <b-col cols="1">{{ $t('Price')}}</b-col>
    </b-row>
    <b-row align-h="center" class="eanorder__table">
      <b-col v-if="mode == 'barcodes'" cols="2">
        <b-form-group>
          <b-form-radio v-model="filter" name="stock-radio" value="on_hand">{{ $t('In Stock') }}</b-form-radio>
          <b-form-radio v-model="filter" name="stock-radio" value="permanent">{{ $t('Permanent items') }}</b-form-radio>
          <b-form-radio v-model="filter" name="stock-radio" value="limited">{{ $t('Limited items') }}</b-form-radio>
          <b-form-radio v-model="filter" name="stock-radio" value="cheapest">{{ $t('Cheapest items') }}</b-form-radio>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-textarea
          debounce="500"
          v-model="input"
          @input="handleInput"
          @keydown.tab.prevent="handleTab($event)"
        ></b-form-textarea>
      </b-col>
      <b-col :cols="mode === 'barcodes' ? 6 : 8" style="margin-top: -10px">
        <b-table small thead-class="d-none" :items="saneTableData" :fields="saneTableFields"></b-table>
      </b-col>
    </b-row>
    <b-row align-h="between">
     <b-form-group v-if="isAdmin">
        <b-form-radio-group
          v-model="mode"
          :options="modeOptions"
          button-variant="outline-primary"
          buttons
        ></b-form-radio-group>
    </b-form-group>
      <b-button @click="handleAddToCart()" class="eanorder__order" variant="secondary"><b-img :src="require('@/assets/icons/warenkorb_white.png')"></b-img> <span>{{ $t('ADD TO CART') }}</span> <b-img :src="require('@/assets/icons/arrow_white.png')"></b-img></b-button>
    </b-row>
  </b-container>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import { debounce } from 'lodash'

export default {
  name: 'EANOrder',
  data () {
    return {
      filter: 'on_hand',
      input: '',
      mode: 'barcodes',
      modeOptions: [
        { text: 'Barcodes', value: 'barcodes' },
        { text: 'SKUs', value: 'skus' }
      ],
      parsedData: [],
      saneTableFields: ['title', 'amount', { key: 'price_display', tdClass: 'text-right' }]
    }
  },
  created: function () {
    // If the user is an admin, we default to skus
    if (this.isAdmin) {
      this.mode = 'skus'
    }
  },
  watch: {
    filter: {
      handler (val) {
        for (const [key, item] of Object.entries(this.parsedData)) {
          console.log(key, item)
          if (item.product) {
            let tmpVal = val
            if (tmpVal === 'cheapest') {
              tmpVal = item.cheapest
            }
            if (tmpVal in item.product.pricelist) {
              item.price = item.product.pricelist[tmpVal].price
              item.stock = tmpVal
            } else {
              item.stock = false
            }
          }
        }
      }
    },
    mode: {
      handler (val) {
        if (this.input !== '') {
          this.handleSearch(this, this.input)
        }
      }
    }
  },
  computed: {
    ...mapState({
      quickAddEANs: state => state.products.quickAddEANs,
      isAdmin: state => state.auth.isAdmin
    }),
    saneTableData: function () {
      const std = []
      Object.keys(this.parsedData).forEach(ident => {
        const item = this.parsedData[ident]
        if (item && item.product && item.stock) {
          std.push({
            key: ident,
            product: item.product,
            title: item.product.title,
            amount: item.amount,
            price: item.price,
            price_display: (item.amount * item.price).toFixed(2),
            stock: item.stock,
            _rowVariant: item.stock
          })
        } else {
          std.push({
            key: ident,
            title: 'UNBEKANNT',
            amount: 'X',
            price_display: 'X',
            _rowVariant: 'danger'
          })
        }
      })
      return std
    }
  },
  methods: {
    ...mapActions({
      fetchQuickAdd: 'products/fetchQuickAdd',
      addToCart: 'products/addToCart'
    }),
    handleAddToCart () {
      for (const [key, item] of Object.entries(this.parsedData)) {
        console.log('handleAddToCart', key, item)
        if (item.product && item.stock) {
          this.addToCart({ product: item.product, index: item.stock, amount: item.amount, price: item.price })
        } else {
          this.addToCart({ ident: key, amount: item.amount })
        }
      }
      this.$router.push({ name: 'cart' })
    },
    handleTab (event) {
      const text = this.input
      const originalSelectionStart = event.target.selectionStart
      const textStart = text.slice(0, originalSelectionStart)
      const textEnd = text.slice(originalSelectionStart)

      this.input = `${textStart}\t${textEnd}`
      event.target.value = this.input // required to make the cursor stay in place.
      event.target.selectionEnd = event.target.selectionStart = originalSelectionStart + 1
    },
    handleInput (value) {
      this.handleSearch(this, value)
    },
    handleSearch: debounce((pthis, value) => {
      const values = []
      const lines = value.split(/\r?\n/)
      const tmpParsedData = {}
      lines.forEach(line => {
        const tabs = line.split('\t')
        const tmpID = tabs[0].trim()
        let tmpAmount = 1
        let tmpPrice = false
        if (tabs.length > 1) {
          tmpAmount = tabs[1]
        }
        if (tabs.length > 2 && pthis.isAdmin) {
          tmpPrice = tabs[2]
          tmpPrice = tmpPrice.replace('€', '')
          tmpPrice = tmpPrice.replace(',', '.')
        }
        console.log('EANOrder ParsedLine', tabs, tmpID, tmpPrice, tmpAmount)
        values.push(tmpID)
        if (tmpID !== '') {
          if (tmpID in tmpParsedData) {
            tmpParsedData[tmpID].amount = tmpParsedData[tmpID].amount + parseInt(tmpAmount)
            tmpParsedData[tmpID].price = tmpPrice
          } else {
            tmpParsedData[tmpID] = { amount: parseInt(tmpAmount), price: tmpPrice }
          }
        }
      })

      console.log('handleSearch', lines)
      if (pthis.mode === 'skus') {
        pthis.fetchQuickAdd({ skus: values }).then(res => {
          for (const [key, value] of Object.entries(tmpParsedData)) {
            console.log(`${key}: ${value}`)
            tmpParsedData[key].product = res.product_map[key]
            if (tmpParsedData[key].product) {
              // We found a either current or historic product
              for (const [pkey, pvalue] of Object.entries(tmpParsedData[key].product.pricelist)) {
                if (pvalue.sku === key) {
                  tmpParsedData[key].stock = pkey
                  if (!tmpParsedData[key].price) {
                    tmpParsedData[key].price = pvalue.price
                  } else {
                    tmpParsedData[key].price = parseFloat(tmpParsedData[key].price)
                  }
                  break
                }
              }
            }
          }
          pthis.parsedData = tmpParsedData
        })
      } else {
        // Barcode mode
        pthis.fetchQuickAdd({ upcs: values }).then(res => {
          for (const [key, value] of Object.entries(tmpParsedData)) {
            console.log(`${key}: ${value}`)
            tmpParsedData[key].product = res.product_map[key]
            if (tmpParsedData[key].product) {
              // We found a either current or historic product
              let tmpCheapest = 9999999999999
              for (const [pkey, pvalue] of Object.entries(tmpParsedData[key].product.pricelist)) {
                if (pvalue.price < tmpCheapest) {
                  tmpParsedData[key].cheapest = pkey
                  tmpCheapest = pvalue.price
                }
              }
              if (pthis.filter === 'cheapest') {
                tmpParsedData[key].stock = tmpParsedData[key].cheapest
              } else {
                if (pthis.filter in tmpParsedData[key].product.pricelist) {
                  // if the filter/desired stock is available, then use that
                  // if not, we use cheapest
                  tmpParsedData[key].stock = pthis.filter
                } else {
                  tmpParsedData[key].stock = tmpParsedData[key].cheapest
                  tmpParsedData[key].missing_desired_stock = true
                }
                // TO-DO: how do we handle if this is false? UNBEKANT?! talk with I-DI ppl.
                if (!tmpParsedData[key].price && tmpParsedData[key].stock in tmpParsedData[key].product.pricelist) {
                  tmpParsedData[key].price = tmpParsedData[key].product.pricelist[tmpParsedData[key].stock].price
                }
              }
            }
          }
          pthis.parsedData = tmpParsedData
        })
      }
    }, process.env.VUE_APP_INPUT_DEBOUNCE)
  }
}
</script>

<style lang="scss">
.eanorder {
  h1 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  &__insturctions {
    margin-bottom: 55px;
  }

  &__header {
    text-transform: uppercase;
    margin-bottom: 40px;

    & > * {
      border-bottom: 1px solid var(--primary);
    }
  }

  &__table {
    text-transform: uppercase;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--primary);
    margin-bottom: 45px;
  }

  &__order {
    margin-bottom: 45px;

    span {
      vertical-align: middle;
    }
  }

  textarea {
    background-color: adjust-color(theme-color('primary'), $alpha: -0.95);
    border: 0;
    min-height: 300px;
    margin-top: -10px;
    resize: none;
  }

  .table-permanent, .table-permanent > th, .table-permanent > td {
    background-color: theme-color('stock_permanent');
  }

  .table-on_hand, .table-on_hand > th, .table-on_hand > td {
    background-color: theme-color('stock_on_hand');
  }

  .table-limited, .table-limited > th, .table-limited > td {
    background-color: theme-color('stock_limited');
  }
}
</style>
