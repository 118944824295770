<template>
<div class="admin__turnover">
  <div class="w-100 d-flex flex-row justify-content-between">
    <h2>{{ $t('Turnover') }}</h2>
  </div>
  <div>
    <label class="mb-0" for="input-datepicker">{{ $t('Date Range') }}:</label>
    <v-date-picker id="input-datepicker" v-model="dateRange" is-range>
      <template v-slot="{ inputValue, inputEvents }">
        <div class="flex justify-center items-center">
          <input
            :value="inputValue.start"
            v-on="inputEvents.start"
            class="border px-2 py-1 w-32 focus:outline-none focus:border-indigo-300"
          />
          -
          <input
            :value="inputValue.end"
            v-on="inputEvents.end"
            class="border px-2 py-1 w-32 focus:outline-none focus:border-indigo-300"
          />
        </div>
      </template>
    </v-date-picker>
    <label class="mt-2 mb-0" for="input-type">{{ $t('Type') }}:</label>

    <b-form-select id="input-type" style="display: block; width: auto;" v-model="type" :options="typeOptions"></b-form-select>
    <!--
    <b-form-input v-model="ean" placeholder="EAN/SKU"></b-form-input>
    <b-form-input v-model="customer" placeholder="Customer"></b-form-input>
    -->
    <b-button @click="generateReport()" class="mt-2" variant="outline-primary">{{ $t('Generate Report') }}</b-button>
  </div>
  <div>
    <b-table hover small :items="tabledata"></b-table>
  </div>
</div>
</template>

<script>
import client from 'api-client'

export default {
  name: 'Turnover',
  data () {
    return {
      dateRange: {
        start: new Date(),
        end: new Date()
      },
      type: null,
      typeOptions: [
        { value: null, text: this.$t('Please select an option') },
        { value: 1, text: this.$t('Per Day') },
        { value: 2, text: this.$t('Per Customer') },
        { value: 3, text: this.$t('Top Seller') },
        { value: 4, text: this.$t('Per Day/Customer') },
        { value: 5, text: this.$t('Per Customer/Day') }
      ],
      ean: null,
      customer: null,
      tabledata: null
    }
  },
  created () {
    console.log('created')
  },
  methods: {
    async generateReport () {
      const data = await client.getTurnover({ start_date: this.dateRange.start, end_date: this.dateRange.end, type: this.type })
      console.log('gen', data)
      this.tabledata = data.response
    }
  }
}
</script>

<style lang="scss">

</style>
