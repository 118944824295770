<template>
<div class="product_order">
  <div :class="['order__line', `order__line--${index}`]" :key="index" v-for="(data, index) in product.pricelist">
    <div class="order__line__avability"><b-img :src="require(`@/assets/icons/dots/${index}.png`)" ></b-img> {{ data.num }}</div>
    <div class="order__line__price">€ {{ data.price }}</div>
    <div class="order__line__order">
      <ProductQuantityControl v-model="numHolder[index]" :min="minOrder" :max="data.num" />

      <div class="order__line__order__warenkorb" @click="addToCartHandler(index)" style="">
        <b-img :src="require('@/assets/icons/warenkorb_white.png')"></b-img>
      </div>
    </div>
    <div v-if="isAdmin" class="order__line__admin small">
      <span class="text-muted">{{ data.sku }}</span>
      <ProductBlacklistControl :upc="data.sku" upc_type="sku" :round_icon="false" />
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ProductBlacklistControl from '@/components/products/ProductBlacklistControl'
import ProductQuantityControl from '@/components/products/ProductQuantityControl'

export default {
  name: 'ProductOrder',
  components: {
    ProductBlacklistControl,
    ProductQuantityControl
  },
  props: {
    product: {
      type: Object
    }
  },
  data: function () {
    return {
      // TO-DO: We can probably do better system with a watcher on numHolder inCart and modifyCartAmount instead of multiple calls down the line.
      numHolder: {},
      minOrder: 1
    }
  },
  computed: {
    ...mapState({
      cart: state => state.products.cart,
      isAdmin: state => state.auth.isAdmin
    })
  },
  methods: {
    ...mapActions({
      addToCart: 'products/addToCart',
      modifyCartAmount: 'products/modifyCartAmount'
    }),
    checkAmountIsValid (index) {
      // TO-DO: Show warning/toast
      if (this.numHolder[index] < 1) {
        this.numHolder[index] = this.minOrder
      }
      if (this.numHolder[index] > this.product.pricelist[index].num) {
        this.numHolder[index] = this.product.pricelist[index].num
      }
      return true
    },
    addToCartHandler (index) {
      if (this.checkAmountIsValid(index)) {
        this.addToCart({ product: this.product, index: index, amount: this.numHolder[index] })
        this.$bvToast.toast(`${this.product.title} successfully added to cart.`, {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success'
        })
      }
    }
  },
  beforeMount: function () {
    for (const i in this.product.pricelist) {
      this.$set(this.numHolder, i, 1)
    }
  }
}
</script>

<style lang="scss">
.product_order {
  .order__line {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &--on_hand {
      .order__line__avability,
      .order__line__price {
        border-top: 3px solid var(--stock_on_hand);
      }
    }

    &--permanent {
      .order__line__avability,
      .order__line__price {
        border-top: 3px solid var(--stock_permanent);
      }
    }

    &--limited {
      .order__line__avability,
      .order__line__price {
        border-top: 3px solid var(--stock_limited);
      }
    }

    &__admin {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }

    &__avability {
      display: flex;
      flex-grow: 1;
      align-items: center;

      img {
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }

    &__price {
      display: flex;
      align-items: center;
      padding-right: 10px;
    }

    &__order {
      padding-left: 0;
      display: flex;

      input {
        border: 1px solid black;
        width: 50px; // we could use "ch" measurement here with some calcs for better flexibility
      }

      &__warenkorb {
        background-color: black;
        padding: 2px 5px;
        cursor: pointer;

        img {
          width: 18px;
          height: 20px;
        }
      }
    }
  }
}
</style>
