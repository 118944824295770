<template>
  <b-container class="login">
    <h1>{{$t('PLEASE LOG IN TO THE SYSTEM:')}}</h1>
      <b-alert v-model="showError" variant="danger">
        {{ this.errorText }}
      </b-alert>
        <b-form-group
          id="fieldset-0"
          :label="$t('Language')"
          label-for="input-0"
        >
          <v-select id="input-0"
                    :components="{OpenIndicator}"
                    :clearable="false"
                    :options="this.language_list"
                    :placeholder="$t('Select Language')"
                    :value="this.language"
                    @input="setLanguage"
                    >
          </v-select>
        </b-form-group>
        <b-form-group
          id="fieldset-1"
          :label="$t('Username')"
          label-for="input-1"
        >
          <b-form-input id="input-1" v-model="username" trim></b-form-input>
        </b-form-group>
        <b-form-group
          id="fieldset-2"
          :label="$t('Password')"
          label-for="input-2"
        >
          <b-form-input id="input-2"
                        type="password"
                        v-model="password"
                        trim>
          </b-form-input>
        </b-form-group>
      <b-button v-on:click="login" :disabled="isAuthorizing" variant="secondary">{{$t('Login')}}</b-button>
  </b-container>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      OpenIndicator: {
        render: createElement => createElement('img', { attrs: { src: require('@/assets/icons/arrow.png') } })
      },
      language: { code: this.$i18n.locale, label: this.$t('languages.' + this.$i18n.locale) },
      username: '',
      password: '',
      showError: false,
      errorText: ''
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      isAuthorizing: state => state.auth.isAuthorizing,
      languages: state => state.cms.languages
    }),
    language_list () {
      return this.languages.map(lang => {
        return { code: lang, label: this.$t('languages.' + lang) }
      })
    }
  },
  methods: {
    ...mapActions({
      authorize: 'auth/authorize'
    }),
    login () {
      this.authorize({ username: this.username, password: this.password }).then(response => {
        if (response.status === 'ok') {
          this.$router.push('/')
        } else {
          this.errorText = this.$t('Error Logging you in')
          this.showError = true
        }
      })
    },
    setLanguage (language) {
      this.$i18n.locale = language.code
      this.language = language
      console.log(language.code, this.$i18n.locale)
    }
  }
}
</script>

<style lang="scss">
body.page-login {
  background-color: transparent;
  background-image: url('~@/assets/login_background.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.login {
  max-width: 400px !important;
  margin-bottom: 150px;
  display: flex;
  justify-self: center;
  flex-direction: column;

  $vs-border-radius: 0;
  $vs-border-color: transparent;
  $vs-selected-bg: white;
  $vs-component-placeholder-color: var(--primary);
  $vs-state-active-bg: white;
  $vs-state-active-color: var(--primary);
  $vs-controls-color: var(--primary);
  $vs-component-line-height: 1.65;
  @import "vue-select/src/scss/vue-select.scss";

  @include style-select(true);

  .v-select {
    width: 100%;
    border: 1px solid var(--primary);
    background-color: white;
  }
}
</style>
