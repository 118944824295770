const items = [
  {
    artist: 'COLDPLAY',
    title: 'EVERYDAY LIFE - LIMITED EDITION',
    label: 'WARNER',
    ean: '0190295337834',
    image: 'https://i-di.com/simg.php?r=0190295337834',
    format: 'CD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  },
  {
    artist: 'WILLIAMS ROBBIE',
    title: 'THE CHRISTMAS PRESENT',
    label: 'SONY MUSIC',
    ean: '0190759967126',
    image: 'https://i-di.com/simg.php?r=0190759967126',
    format: '2 CD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  },
  {
    artist: 'PINK FLOYD',
    title: 'THE LATER YEARS 1987-2019',
    label: 'WARNER',
    ean: '0190295413132',
    image: 'https://i-di.com/simg.php?r=0190295413132',
    format: '18 CD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  },
  {
    artist: 'CAVE NICK AND THE BAD SEEDS',
    title: 'GHOSTEEN',
    label: 'GHOSTEEN LTD',
    ean: '5056167114789',
    image: 'https://i-di.com/simg.php?r=5056167114789',
    format: '2 CD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  },
  {
    artist: 'YOUNG NEIL WITH CRAZY HORSE',
    title: 'COLORADO',
    label: 'WARNER',
    ean: '0093624898900',
    image: 'https://i-di.com/simg.php?r=0093624898900',
    format: 'CD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  },
  {
    artist: 'ROXETTE',
    title: 'A COLLECTION OF ROXETTE HITS',
    label: 'PARLOPHONE',
    ean: '0094636797823',
    image: 'https://i-di.com/simg.php?r=0094636797823',
    format: 'CD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  },
  {
    artist: 'DAVIS MILES',
    title: 'RUBBERBAN',
    label: 'WARNER',
    ean: '0603497850785',
    image: 'https://i-di.com/simg.php?r=0603497850785',
    format: 'CD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  }
]

const highLightedItems = [
  {
    artist: 'Bladerunner 2049',
    title: 'EVERYDAY Bladerunner 2049',
    label: '',
    ean: '0190295337834',
    image: 'https://i-di.com/simg.php?r=0190295337834',
    highlightImage: require('@/assets/mock/highlight_bladerunner.png'),
    format: 'BRD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  },
  {
    artist: '',
    title: 'GAME OF THRONES',
    label: '',
    ean: '0190759967126',
    image: 'https://i-di.com/simg.php?r=0190759967126',
    highlightImage: require('@/assets/mock/highlight_got.png'),
    format: '8 BRD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  }
]

const orderHistory = [
  {
    id: 1,
    date: '10.01.2019',
    orderedItems: [
      {
        num: 23,
        item: {
          artist: 'DAVIS MILES',
          title: 'RUBBERBAN',
          label: 'WARNER',
          ean: '0603497850785',
          image: 'https://i-di.com/simg.php?r=0603497850785',
          format: 'CD',
          pricelist: {
            on_hand: { num: 96, price: 11.11 },
            permanent: { num: 96, price: 11.11 },
            limited: { num: 96, price: 11.11 }
          }
        }
      },
      {
        num: 24,
        item: {
          artist: 'DAVIS MILES',
          title: 'RUBBERBAN',
          label: 'WARNER',
          ean: '0603497850785',
          image: 'https://i-di.com/simg.php?r=0603497850785',
          format: 'CD',
          pricelist: {
            on_hand: { num: 96, price: 11.11 },
            permanent: { num: 96, price: 11.11 },
            limited: { num: 96, price: 11.11 }
          }
        }
      }
    ]
  },
  {
    id: 2,
    date: '09.01.2019',
    orderedItems: [
      {
        num: 23,
        item: {
          artist: 'DAVIS MILES',
          title: 'RUBBERBAN',
          label: 'WARNER',
          ean: '0603497850785',
          image: 'https://i-di.com/simg.php?r=0603497850785',
          format: 'CD',
          pricelist: {
            on_hand: { num: 96, price: 11.11 },
            permanent: { num: 96, price: 11.11 },
            limited: { num: 96, price: 11.11 }
          }
        }
      },
      {
        num: 24,
        item: {
          artist: 'DAVIS MILES',
          title: 'RUBBERBAN',
          label: 'WARNER',
          ean: '0603497850785',
          image: 'https://i-di.com/simg.php?r=0603497850785',
          format: 'CD',
          pricelist: {
            on_hand: { num: 96, price: 11.11 },
            permanent: { num: 96, price: 11.11 },
            limited: { num: 96, price: 11.11 }
          }
        }
      }
    ]
  }
]

const favourites = [
  {
    artist: 'COLDPLAY',
    title: 'EVERYDAY LIFE - LIMITED EDITION',
    label: 'WARNER',
    ean: '0190295337834',
    image: 'https://i-di.com/simg.php?r=0190295337834',
    format: 'CD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  },
  {
    artist: 'WILLIAMS ROBBIE',
    title: 'THE CHRISTMAS PRESENT',
    label: 'SONY MUSIC',
    ean: '0190759967126',
    image: 'https://i-di.com/simg.php?r=0190759967126',
    format: '2 CD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  },
  {
    artist: 'PINK FLOYD',
    title: 'THE LATER YEARS 1987-2019',
    label: 'WARNER',
    ean: '0190295413132',
    image: 'https://i-di.com/simg.php?r=0190295413132',
    format: '18 CD',
    pricelist: {
      on_hand: { num: 96, price: 11.11 },
      permanent: { num: 96, price: 11.11 },
      limited: { num: 96, price: 11.11 }
    }
  }
]

export { items, highLightedItems, orderHistory, favourites }
