<template>
  <b-container class="contact">
    <h1>{{ $t('Contact Us') }}</h1>
        <b-form-group
          id="fieldset-0"
          :label="$t('Department')"
          label-for="input-0"
        >
          <v-select id="input-0" :components="{OpenIndicator}" :clearable="false" :options="departments" :placeholder="$t('Select a Department')">
          </v-select>
        </b-form-group>
        <b-row>
          <b-col cols="6">
            <b-form-group
              id="fieldset-1"
              :label="$t('Name')"
              label-for="input-1"
            >
              <b-form-input id="input-1" v-model="name" trim></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
          <b-form-group
            id="fieldset-2"
            :label="$t('E-Mail')"
            label-for="input-2"
          >
            <b-form-input id="input-2" v-model="email" trim></b-form-input>
          </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          id="fieldset-3"
          :label="$t('Subject')"
          label-for="input-3"
        >
          <b-form-input id="input-3" v-model="subject" trim></b-form-input>
        </b-form-group>
        <b-form-group
          id="fieldset-4"
          :label="$t('Message')"
          label-for="textarea"
        >
        <b-form-textarea
          id="textarea"
          v-model="message"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        </b-form-group>
        <b-form-group
          id="fieldset-5"

        >
          <b-form-checkbox
            id="checkbox-1"
            v-model="checkbox"
            name="checkbox-1"
            value="accepted"
            unchecked-value="not_accepted"
          >
        {{$t('I agree that my information incl. personal data will be stored and processed for contacting and processing my request. This declaration of consent can be revoked by me at any time by e-mail to mydata@inandout.at.')}}    </b-form-checkbox>
        </b-form-group>
        <div style="text-align: center;">
      <b-button v-on:click="send" variant="primary">{{$t('Send')}}</b-button>
      </div>
  </b-container>
</template>

<script>

export default {
  name: 'Contact',
  data () {
    return {
      OpenIndicator: {
        render: createElement => createElement('img', { attrs: { src: require('@/assets/icons/arrow.png') } })
      },
      departments: ['1', '2'],
      department: '',
      name: '',
      email: '',
      subject: '',
      message: '',
      checkbox: ''
    }
  },
  methods: {
    send () {
      console.log('Message Sent')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">
  body.page-contact {
    background-color: var(--secondary);

    .header__public__logo {
      content: url('~@/assets/idi-logo_white.png');
    }

    #exit_icon {
      content: url('~@/assets/icons/x_white.png');
    }
  }

  .contact {
    $vs-border-radius: 0;
    $vs-border-color: white;
    $vs-selected-bg: white;
    $vs-component-placeholder-color: var(--primary);
    $vs-state-active-bg: white;
    $vs-state-active-color: var(--primary);
    $vs-controls-color: var(--primary);
    $vs-component-line-height: 1.65;

    @import "vue-select/src/scss/vue-select.scss";

    max-width: 570px;
    margin-bottom: 150px;
    display: flex;
    justify-self: center;
    flex-direction: column;
    color: white;

    h1 {
      text-align: center;
      margin-bottom: 60px;
      text-transform: uppercase;
    }

    label:not([for="checkbox-1"]) {
      text-transform: uppercase;
    }

    .v-select {
      .vs__dropdown-toggle {
        background-color: white;
      }

      .vs__open-indicator {
        transform: rotate(90deg);
        transition: 0.5s ease-in-out;
      }

      &.vs--open {
        .vs__open-indicator {
          transform: rotate(-90deg);
        }
      }
    }

    .form-control {
      border: 0;
    }
  }
</style>
