<template>
<div class="reports">
  <b-table striped hover :items="items" :fields="fields">
      <template #cell(file_name)="data">
        <a :href="reportDownloadURL(data.item.id)" style="color: var(--secondary);">{{ data.value }}</a>
      </template>
  </b-table>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Reports',
  props: {
    types: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      fields: [
        {
          key: 'external_num',
          label: 'ID'
        },
        {
          key: 'report_type',
          label: 'Type'
        },
        {
          key: 'file_name',
          label: 'File'
        },
        {
          key: 'file_type',
          label: 'Format'
        },
        {
          key: 'date',
          label: 'Date'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      reports: state => state.products.reports
    }),
    items: function () {
      return this.reports.filter(elem => this.types.indexOf(elem.report_type) !== -1)
    }
  },
  methods: {
    ...mapActions({
      fetchReports: 'products/fetchReports'
    }),
    reportDownloadURL (id) {
      return `${process.env.VUE_APP_API_URL}/api/reports/download/${id}`
    }
  },
  created: function () {
    this.fetchReports().then(res => {
      console.log('Reports', this.reports)
    })
  }
}
</script>

<style lang="scss">
.reports {
}
</style>
