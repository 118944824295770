<template>
  <div class="user_data">
    <h2>{{ $t('Change Customer Data') }}</h2>
    <div class="form col-6">
      <b-row>
        <b-col>
          <b-form-group
            id="fieldset-1"
            :label="$t('Username')"
            label-for="input-username"
          >
            <b-form-input id='input-username'
                          v-model="form.username.data"
                          :state="form.username.state">
            </b-form-input>
            <b-form-invalid-feedback id="input-username-feedback">
              {{ form.username.error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="fieldset-2">
            <label for="input-email" class="mb-0">{{ $t('email') }}</label>

            <b-form-input id='input-email'
                          type='email'
                          v-model="form.email.data"
                          :state="form.email.state">
            </b-form-input>
            <b-form-invalid-feedback id="input-email-feedback">
              {{ form.email.error }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-end">
          <b-button :disabled="form.busy" variant="secondary" v-on:click="handleDataChange">{{ $t('Change Data') }}</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'UserData',
  data: function () {
    return {
      form: {
        username: {
          data: '',
          state: null,
          error: null
        },
        email: {
          data: '',
          state: null,
          error: null
        },
        busy: false
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  mounted: function () {
    if (this.user) {
      this.form.username.data = this.user.name
      this.form.email.data = this.user.email
    }
  },
  methods: {
    ...mapActions({
      requestUserDataChange: 'auth/requestUserDataChange'
    }),
    handleDataChange () {
      this.form.busy = true
      const newData = { user_id: this.user.id, username: this.form.username.data, email: this.form.email.data }
      this.requestUserDataChange(newData).then(response => {
        if (response.status === 'ok') {
          this.$bvToast.toast(this.$t('User data change request was successfully submitted. It will be reviewed by staff as soon as possible.'), {
            title: 'Success',
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'success'
          })
          this.form.username.state = null
          this.form.username.error = null
          this.form.email.state = null
          this.form.email.error = null
          /*
          this.$nextTick(() => {
            this.$router.push('/')
          })
          */
        } else {
          Object.keys(response.error.data.errors).forEach(key => {
            this.form[key].state = false
            this.form[key].error = response.error.data.errors[key][0]
          })
          this.form.busy = false
        }
      })
    }
  }
}
</script>

<style lang="scss">
.user_data {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-transform: uppercase;
    margin-bottom: 50px;
  }

  .form {
    margin-bottom: 100px;

    label {
      text-transform: uppercase;
    }

    input {
      background-color: adjust-color(theme-color('primary'), $alpha: -0.95);
      border: 0;
    }

    button {
      text-transform: uppercase;
    }
  }
}
</style>
