<template>
<div class="admin__blacklists">
  <h2>{{ $t('Blacklist') }}</h2>
  <b-table striped
            :items="blacklistData"
            :fields="fields.blacklist"
            :busy="blacklistMeta.isBusy">
    <template v-slot:table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong> {{ $t('Loading') }}...</strong>
      </div>
    </template>
    <template v-slot:cell(actions)="row">
      <b-button size="sm" variant="danger" @click.stop="deleteFromBlacklistAction(row)">
        {{ $t('Delete') }}
      </b-button>
    </template>
  </b-table>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ProductBlacklistList',
  data () {
    return {
      fields: {
        blacklist: [
          {
            key: 'upc',
            label: 'UPC'
          },
          {
            key: 'upc_type',
            label: 'UPC Type'
          },
          {
            key: 'note',
            label: this.$t('Note')
          },
          {
            key: 'created_at',
            label: this.$t('Created at'),
            formatter: this.dateFormat
          },
          {
            key: 'end_date',
            label: this.$t('End Date'),
            formatter: this.dateFormat
          },
          {
            key: 'created_by.name',
            label: this.$t('Created by')
          },
          {
            key: 'actions',
            label: this.$t('Actions')
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      blacklistData: state => state.admin.blacklistData,
      blacklistMeta: state => state.admin.blacklistMeta
    })
  },
  created () {
    this.fetchBlacklist()
  },
  methods: {
    ...mapActions({
      fetchBlacklist: 'admin/fetchBlacklist',
      deleteFromBlacklist: 'admin/deleteFromBlacklist'
    }),
    deleteFromBlacklistAction (row) {
      // TO-DO: Popup modal confirming this + success notification
      this.deleteFromBlacklist(row.item)
    },
    dateFormat (value, key, item) {
      if (value === null) {
        return 'N/A'
      }
      const t = new Date(value)
      // TO-DO: localize this
      return t.toLocaleString('de-DE')
    }
  }
}
</script>

<style lang="scss">

</style>
