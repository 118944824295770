<template>
  <b-container fluid class="callout">
    <b-container>
      <b-row class="callout__content justify-content-center">
        <b-col cols="12" lg="6">
          <h2>{{ title }}</h2>
          <div class="callout__content__text">{{ text }}</div>
          <b-button v-if="action.type === 'button'" :to="action.target">{{ action.text }}</b-button>
          <div class="callout__ean" v-else-if="action.type === 'ean_order'">
            <b-img class="callout__ean__barcode" :src="require('@/assets/icons/barcode_white.png')"></b-img>
            <b-form-input class="callout__ean__input"
                          v-model="ean_order"
                          :placeholder="action.text"
                          v-on:keyup.enter="submitEAN"
                          >
            </b-form-input>
            <b-img @click="submitEAN" class="callout__ean__arrow" :src="require('@/assets/icons/arrow_secondary@2x.png')"></b-img>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import router from '@/router'

export default {
  name: 'Callout',
  data () {
    return {
      ean_order: ''
    }
  },
  methods: {
    submitEAN () {
      console.log('submitEAN')
      router.push({ name: 'listing', query: { s: this.ean_order } })
    }
  },
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    action: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.callout {
  background-color: var(--secondary);
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;

  &__content {
    text-align: center;

    h2 {
      margin-bottom: 20px;
    }

    &__text {
      margin-bottom: 35px;
    }

    button {
      background-color: white;
      text-transform: uppercase;
      color: var(--secondary);
    }
  }

  &__ean {
    position: relative;

    &__barcode {
      position: absolute;
      left: 20px;
      top: 20px;
    }

    &__input {
      height: 60px !important;
      color: white !important;
      border: 0 !important;
      background-color: adjust-color(white, $alpha: -0.75) !important;
      padding-left: 70px !important;
      padding-right: 100px !important;

      &::placeholder {
        color: white !important;
        text-transform: uppercase !important;
      }

      &:focus {
        background-color: adjust-color(white, $alpha: -0.75) !important;
        color: white !important;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-transition-delay: 9999s !important;
        -webkit-transition: color 9999s ease-out, background-color 9999s ease-out !important;
      }
    }

    &__arrow {
      position: absolute;
      cursor: pointer;
      right: 0;
      top: 0;
      background-color: white;
      width: 70px;
      height: 60px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
</style>
