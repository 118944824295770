<template>
  <b-container fluid class="center_mode_slider">
    <VueSlickCarousel v-if="slides.length" v-bind="settings">
      <div v-for="slide in slides" v-bind:key="slide.id">
        <b-img :src="slide.image_url"></b-img>
      </div>
      <template #prevArrow>
        <div class="custom-arrow">
            <b-img style="transform: rotate(180deg);" :src="require('@/assets/icons/arrow_secondary.png')"></b-img>
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-arrow">
          <b-img :src="require('@/assets/icons/arrow_secondary.png')"></b-img>
        </div>
    </template>
    </VueSlickCarousel>
  </b-container>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

// import style
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'CenterModeSlider',
  components: { VueSlickCarousel },
  data () {
    return {
      settings: {
        dots: true,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: '130px',
        slidesToShow: 1,
        variableWidth: true,
        autoplay: true
      }
    }
  },
  props: {
    slides: Array
  }
}
</script>

<style lang="scss">
.center_mode_slider {
  padding: 0;
  margin-bottom: 75px;

  .slick-slider {
    position: relative;

    .slick-slide {
       height: 300px;
       // width: 570px;
    }

    .slick-prev {
      position: absolute;
      top: 150px;
      left: calc(100vw / 2 - 370px);
      z-index: 100;
    }

    .slick-next {
      position: absolute;
      top: 150px;
      left: calc(100vw / 2 + 350px);
      z-index: 100;
    }

    .slick-center {
      // -webkit-transform: scale(1.25);
      // -moz-transform: scale(1.25);
      // transform: scale(1.25);
      // TO-DO: After updating to 1.0.2 which contains some centerMode fixes, the first slide doesen't work but the subsequent ones do.
      // -> centerPadding should be the solution, but it looks like it doesen't work with this component
      padding-left: 130px;
      padding-right: 130px;
    }

    .slick-dots {
      list-style: none;
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin-top: 50px;

      li {
        height: 6px;
        width: 6px;
        background-color: var(--primary);
        border-radius: 50%;
        margin-right: 35px;

        &.slick-active {
          height: 10px;
          width: 10px;
          background-color: var(--secondary)
        }

        button {
          display: none;
        }
      }
    }
  }
}
</style>
