<template>
  <b-container fluid class="hero_slider">
    <div class="hero_slider--background"></div>
    <VueSlickCarousel v-if="slides.length" class="container" v-bind="settings">
      <b-container v-for="slide in slides" v-bind:key="slide.id">
        <b-row>
          <b-col class="hero_slider__left">
            <h3>{{ slide.pretitle }}</h3>
            <h1>{{ slide.title }}</h1>
            <b-button variant="secondary">{{$t('Secondary')}}</b-button>
          </b-col>
          <b-col class="hero_slider__image">
            <b-img :src="slide.image_url"></b-img>
          </b-col>
        </b-row>
      </b-container>
    </VueSlickCarousel>
  </b-container>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

// import style
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'HeroSlider',
  components: { VueSlickCarousel },
  data () {
    return {
      settings: {
        dots: true,
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true
      }
    }
  },
  props: {
    slides: Array
  }
}
</script>

<style lang="scss">
.hero_slider {
  margin: 0;
  padding-top: 130px;
  margin-bottom: 50px;
  position: relative;

  &--background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50px;
    background-color: var(--primary);
  }

  &__left {
    padding-left: 100px;
    color: white;

    h3 {
      margin-bottom: 25px;
    }

    h2 {
      margin-bottom: 30px;
    }
  }

  &__image {
    padding-right: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .slick-slider {
    .slick-dots {
      position: absolute;
      top: 25px; // TO-DO: find a better way
      right: 0;
      list-style: none;

      li {
        height: 10px;
        width: 10px;
        background-color: #fff;
        border-radius: 50%;
        opacity: 0.25;
        margin-bottom: 30px;

        &.slick-active {
          opacity: 1;
        }

        button {
          display: none;
        }
      }
    }
  }
}
</style>
