<template>
  <div>
    <PublicFrontPage v-if="!isLoggedIn" />
  </div>
</template>

<script>

import PublicFrontPage from './cms/PublicFrontPage'

import router from '@/router'

import { mapState } from 'vuex'

export default {
  name: 'FrontPageHolder',
  components: {
    PublicFrontPage
  },
  data () {
    return {
      options: {}
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    })
  },
  created: function () {
    if (this.isLoggedIn) {
      router.push({ name: 'listing' })
    }
  }
}
</script>

<style lang="scss">
</style>
