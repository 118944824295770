import Vue from 'vue'
import App from './views/App.vue'

import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import vSelect from 'vue-select'
import VCalendar from 'v-calendar'
import axios from 'axios'
import moment from 'moment'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import '@/styles/main.scss'

import router from './router'
import store from '@/store'
import i18n from './i18n'
import { mapActions } from 'vuex'
import './registerServiceWorker'

Vue.config.productionTip = false

// TO-DO: Check if we have a better place for this
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  // Do something with response error
  if (error.response.status === 401) {
    console.log('unauthorized, logging out ...')
    store.dispatch('auth/deauthorize').then(response => {
      router.push('/')
    })
  }
  return Promise.reject(error.response)
})

Vue.use(VueRouter)
Vue.use(BootstrapVue)
// TO-DO: import only specific icons we need when we are future complete and ready for production
Vue.use(BootstrapVueIcons)
Vue.component('v-select', vSelect)
Vue.use(VCalendar)

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY hh:mm')
  }
})

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

Sentry.init({
  Vue,
  dsn: 'https://80937ae2cfb1432daf3b4fedcfc10906@sentry.tate.conversory.net/5',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['api.staging.i-di.com']
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV
})

new Vue({
  router,
  store,
  i18n,
  components: { App },
  template: '<App/>',
  render: h => h(App),
  methods: {
    ...mapActions({
      setLanguages: 'cms/setLanguages'
    })
  },
  created () {
    const languages = process.env.VUE_APP_AVAILABLE_LANGUAGES
    this.setLanguages(languages.split(','))
  }
}).$mount('#app')
