<template>
<div class="admin__ftp">
  <div class="w-100 d-flex flex-row justify-content-between">
    <h2>{{ $t('External Order Config') }}</h2>
    <b-button variant="primary" v-b-modal.modal-add-ftp>{{ $t('Add') }}</b-button>
  </div>
  <b-table striped
            :items="ftpsData"
            :fields="fields.ftps"
            :busy="ftpsMeta.isBusy">
    <template v-slot:head(username)="data">
      {{ data.label }} <!--  <b-form-input autocomplete="off" v-model="ftpsLocalFilterUsername"></b-form-input> -->
    </template>

    <template v-slot:cell(actions)="data">
      <div v-if="data.item.deleted_at === null">
        <b-button size="sm" variant="danger" @click="deleteFTPAction(data.item)">
        <b-icon-trash />
        </b-button>

        <b-button size="sm" variant="primary" @click="editFTPAction(data.item)">
          <b-icon-pencil-square />
        </b-button>

      </div>
      <div v-else >
        <b-button size="sm" variant="primary" @click="restoreFTPAction(data.item)">
          <b-icon-bootstrap-reboot />
        </b-button>
      </div>
      <FTPDataModal :ftp="data.item" />
      <FTPDisableModal :ftp="data.item" />
      <FTPRestoreModal :ftp="data.item" />
    </template>
  </b-table>
    <b-row class="pagination-container col-12 justify-content-center">
      <b-pagination
        v-model="ftpsLocalCurrentPage"
        :total-rows="ftpsMeta.count"
        :per-page="ftpsMeta.rowsPerPage"
        :hide-ellipsis="true"
        :limit="7"
      >

      <template v-slot:first-text><span class="start">{{ $t('Start') }}</span></template>
      <template v-slot:prev-text><b-img class="arrow arrow-prev" :src="require('@/assets/icons/arrow.png')"></b-img></template>
      <template v-slot:next-text><b-img class="arrow arrow-right" :src="require('@/assets/icons/arrow.png')"></b-img></template>
      <template v-slot:last-text><span class="end">{{ $t('End') }}</span></template>
    </b-pagination>
  </b-row>

  <FTPDataModal :ftp="false" />
</div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import FTPDataModal from '@/components/admin/FTPDataModal'
import FTPDisableModal from '@/components/admin/FTPDisableModal'
import FTPRestoreModal from '@/components/admin/FTPRestoreModal'

export default {
  name: 'FTPList',
  components: {
    FTPDataModal,
    FTPDisableModal,
    FTPRestoreModal
  },
  data () {
    return {
      fields: {
        ftps: [
          {
            label: this.$t('User'),
            key: 'user.username'
          },
          {
            key: 'path'
          },
          {
            key: 'type'
          },
          {
            key: 'created_at',
            formatter: this.dateFormat
          },
          {
            key: 'actions'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      ftpsData: state => state.admin.ftpsData,
      ftpsMeta: state => state.admin.ftpsMeta,
      ftpsFilter: state => state.admin.ftpsFilter
    }),
    ftpsLocalCurrentPage: {
      get () {
        return this.ftpsMeta.currentPage
      },
      set (value) {
        this.setFTPsCurrentPage({ currentPage: value })
      }
    },
    ftpsLocalFilterUsername: {
      get () {
        return this.ftpsFilter.username
      },
      set (value) {
        this.setFTPsFilter({ username: value })
        this.eventuallyFetchFTPs()
      }
    }
  },
  created () {
    this.fetchFTPs()
  },
  methods: {
    ...mapActions({
      setFTPsCurrentPage: 'admin/setFTPsCurrentPage',
      setFTPsFilter: 'admin/setFTPsFilter',
      eventuallyFetchFTPs: 'admin/eventuallyFetchFTPs',
      fetchFTPs: 'admin/fetchFTPs'
    }),
    dateFormat (value, key, item) {
      const t = new Date(value)
      // TO-DO: localize this
      return t.toLocaleString('de-DE')
    },
    roleFormat (value, key, item) {
      return value.join(', ')
    },
    editFTPAction (ftp) {
      this.$bvModal.show(`edit-ftp-${ftp.id}`)
    },
    deleteFTPAction (ftp) {
      this.$bvModal.show(`disable-ftp-${ftp.id}`)
    },
    restoreFTPAction (ftp) {
      console.log('ftp restore', ftp)
      this.$bvModal.show(`restore-ftp-${ftp.id}`)
    }
  }
}
</script>

<style lang="scss">
.admin__ftp {
  .pagination-container {
    .arrow-prev {
      transform: rotate(180deg);
    }
  }
}
</style>
