import {
  SET_BADGES,
  SET_CATALOGS,
  SET_STATIC_PAGE,
  SET_LANGUAGES,
  SET_STATIC_PAGE_ATTRIBUTE,
  ADD_SLIDE_TO_HERO_SLIDER,
  REMOVE_SLIDE_FROM_HERO_SLIDER,
  REMOVE_SLIDE_FROM_CENTER_MODE_SLIDER,
  ADD_SLIDE_TO_CENTER_MODE_SLIDER,
  SET_PUBLIC_FRONT_PAGE,
  SET_CATALOGS_FILTER,
  SET_LABEL_HIGHLIGHTS
} from '@/store/mutation-types'

import client from 'api-client'
import { getField, updateField } from 'vuex-map-fields'
import Vue from 'vue'
import { debounce } from 'lodash'

export default {
  namespaced: true,
  state: {
    heroSlides: [],
    centerModeSlides: [],
    firstPublicTextBlock: '',
    ourGoalPitch: {},
    badges: [],
    staticPages: {},
    label_highlights: [],
    languages: [],
    catalogsData: [],
    catalogsMeta: {
      isBusy: false,
      count: 0,
      currentPage: 1,
      rowsPerPage: 20
    },
    catalogsFilter: {
      offset: 0,
      limit: 20,
      name: null,
      label: null
    },
    catalogsDataState: {
      currentPage: 1,
      fetched: null
    }
  },
  mutations: {
    [ADD_SLIDE_TO_HERO_SLIDER] (state, item) {
      state.heroSlides.push(item)
    },
    [REMOVE_SLIDE_FROM_HERO_SLIDER] (state, id) {
      state.heroSlides = state.heroSlides.filter(elem => elem.id !== id)
    },
    [ADD_SLIDE_TO_CENTER_MODE_SLIDER] (state, slide) {
      state.centerModeSlides.push(slide)
    },
    [REMOVE_SLIDE_FROM_CENTER_MODE_SLIDER] (state, id) {
      state.centerModeSlides = state.centerModeSlides.filter(elem => elem.id !== id)
    },
    [SET_PUBLIC_FRONT_PAGE] (state, data) {
      const contentData = JSON.parse(data.content)
      Object.keys(contentData).forEach(element => {
        state[element] = contentData[element]
      })
      console.log('state', state)
    },
    setBadges (state, badges) {
      state.badges = badges
    },
    [SET_LABEL_HIGHLIGHTS] (state, lh) {
      state.label_highlights = lh
    },
    [SET_CATALOGS] (state, cats) {
      state.catalogsMeta.count = cats.count
      state.catalogsData = cats.catalogs
    },
    [SET_CATALOGS_FILTER] (state, data) {
      let resetOffset = true
      Object.entries(data).forEach(([key, value]) => {
        state.catalogsFilter[key] = value
        if (key === 'offset') {
          resetOffset = false
        }
      })
      if (resetOffset) {
        state.catalogsFilter.offset = 0
        state.catalogsDataState.currentPage = 1
      }
    },
    [SET_STATIC_PAGE] (state, page) {
      if (state.staticPages[page.pslug] === undefined) {
        Vue.set(state.staticPages, page.pslug, { [page.language]: page })
      } else {
        Vue.set(state.staticPages[page.pslug], page.language, page)
      }
    },
    [SET_LANGUAGES] (state, languages) {
      state.languages = languages
    },
    [SET_STATIC_PAGE_ATTRIBUTE] (state, payload) {
      Vue.set(state.staticPages[payload.pslug][payload.language], payload.attribute, payload.value)
    },
    updateField
  },
  actions: {
    fetchBadges ({ commit }) {
      return client
        .fetchBadges()
        .then(badges => commit(SET_BADGES, badges))
    },
    fetchLabelHighlights ({ commit }) {
      return client
        .fetchLabelHighlights()
        .then(resp => {
          if (resp.status === 'ok') {
            commit(SET_LABEL_HIGHLIGHTS, resp.response.data)
          }
        })
    },
    fetchCatalogs ({ commit, state }) {
      return client
        .fetchCatalogs(state.catalogsFilter)
        .then(cats => {
          commit(SET_CATALOGS, cats.response.data)
        })
    },
    eventuallyFetchCatalogs: debounce(({ dispatch }, payload) => {
      dispatch('fetchCatalogs')
    }, process.env.VUE_APP_INPUT_DEBOUNCE),
    createCatalog ({ dispatch }, catalog) {
      return client
        .createCatalog(catalog)
    },
    editCatalog ({ dispatch }, catalog) {
      return client
        .editCatalog(catalog)
        .then(response => {
          if (response.status === 'ok') {
            dispatch('cms/fetchCatalogs')
          }
          // TO-DO: Handle Error form
        })
    },
    deleteCatalog ({ dispatch }, catalog) {
      return client
        .deleteCatalog(catalog)
        .then(response => {
          if (response.status === 'ok') {
            dispatch('cms/fetchCatalogs')
          }
          // TO-DO: Handle Error
        })
    },
    setCatalogsFilter ({ commit }, data) {
      // TO-DO: Prefetch some more data? so subsequent requests will be faster/smooother.
      commit(SET_CATALOGS_FILTER, data)
    },
    fetchStaticPage ({ commit }, payload) {
      return client
        .fetchStaticPage(payload)
        .then(response => {
          if (response.status === 'ok') {
            commit(SET_STATIC_PAGE, response.response.data)
          } else if (response.status === 'error' && response.error.status === 404) {
            commit(SET_STATIC_PAGE, { pslug: payload.pslug, language: payload.language, title: '', content: '', id: false })
          }
        })
    },
    setStaticPageAttribute ({ commit }, payload) {
      commit(SET_STATIC_PAGE_ATTRIBUTE, payload)
    },
    saveStaticPage ({ commit, state }, payload) {
      return client
        .saveStaticPage(state.staticPages[payload.pslug][payload.language])
        .then(response => {
          if (response.status === 'ok') {
            commit(SET_STATIC_PAGE, response.response.data)
          }
          // TO-DO: Handle errors
        })
    },
    addSlideToHeroSlider ({ commit }, item) {
      commit(ADD_SLIDE_TO_HERO_SLIDER, item)
    },
    addSlideToCenterModeSlider ({ commit }, slide) {
      commit(ADD_SLIDE_TO_CENTER_MODE_SLIDER, slide)
    },
    fetchPublicFrontPage ({ commit }) {
      const payload = { pslug: 'publicfrontpage', language: 'de' }
      return client
        .fetchStaticPage(payload)
        .then(response => {
          if (response.status === 'ok') {
            commit(SET_PUBLIC_FRONT_PAGE, response.response.data)
          }
        })
    },
    savePublicFrontPage ({ commit, state }, files) {
      console.log('savePublicFrontPage', files)
      // We serialize all the data and send it in as content w/ hardcoded URL?
      const payload = {
        id: 9999,
        pslug: 'publicfrontpage',
        title: 'Front Page',
        language: 'de',
        content: JSON.stringify({
          heroSlides: state.heroSlides,
          centerModeSlides: state.centerModeSlides,
          firstPublicTextBlock: state.firstPublicTextBlock,
          ourGoalPitch: state.ourGoalPitch
        }),
        files: files
      }
      return client
        .saveStaticPage(payload)
        .then(response => {
          commit(SET_PUBLIC_FRONT_PAGE, response.response.data)
        })
    },
    setLanguages ({ commit }, payload) {
      commit(SET_LANGUAGES, payload)
      return true
    }
  },
  getters: {
    getField
  }
}
