import axios from 'axios'

import { highLightedItems, favourites } from './data/items'
import { badges } from './data/cms'

const fetch = (mockData, time = 0) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockData)
    }, time)
  })
}

const transformProduct = (oitem) => {
  if (!oitem) {
    return false
  }
  Object.keys(oitem.price_list).forEach(pli => {
    oitem.price_list[pli].list_price = oitem.price_list[pli].price
  })

  const tmpItem = {
    id: oitem.id,
    artist: oitem.artist,
    title: oitem.title,
    upc: oitem.upc,
    image: oitem.display_image,
    format: oitem.format,
    display_format: oitem.display_format,
    units: oitem.units,
    updated_at: oitem.updated_at,
    pricelist: oitem.price_list
  }
  if (oitem.label) {
    tmpItem.label = oitem.label.name
  }

  return tmpItem
}

const transformResponse = (products) => {
  const items = []
  // For now we just transform the data to what we expect
  // Will probably rewrite all the other parts once we get all the data in the DB
  products.forEach(oitem => {
    items.push(transformProduct(oitem))
  })
  return items
}

export default {
  // Mocks to Implement
  fetchHighlightedProducts () {
    return fetch(highLightedItems, 0)
  },
  fetchBadges () {
    return fetch(badges, 0)
  },
  fetchFavourites () {
    return fetch(favourites, 0)
  },
  // Implemented
  fetchOrderHistory () {
    return new Promise((resolve, reject) => {
      axios.get('/api/orders').then(response => {
        resolve({
          status: 'ok',
          orders: response.data.orders
        })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchProducts (productsFilter) {
    return new Promise((resolve, reject) => {
      axios.get('/api/products', { params: productsFilter }).then(response => {
        const products = transformResponse(response.data.products)
        resolve({
          status: 'ok',
          products: products
        })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchProductsMeta (productsFilter) {
    return new Promise((resolve, reject) => {
      axios.get('/api/products/meta', { params: productsFilter }).then(response => {
        resolve({
          status: 'ok',
          productsAvailableCount: response.data.products_available_count,
          productsMinPrice: response.data.products_min_price,
          productsMaxPrice: response.data.products_max_price
        })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchLatestProducts () {
    return new Promise((resolve, reject) => {
      axios.get('/api/products/latest/').then(response => {
        const products = transformResponse(response.data.products)
        resolve(products)
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchProductMenuGroups () {
    return new Promise((resolve, reject) => {
      axios.get('/api/products/groups/menu/').then(response => {
        resolve(response.data)
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchProductGroups () {
    return new Promise((resolve, reject) => {
      axios.get('/api/products/groups/').then(response => {
        resolve(response.data)
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  createProductGroup (payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/products/groups/', { ...payload }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  editProductGroup (payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/products/groups/group/${payload.id}`, { ...payload }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  deleteProductGroup (payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/products/groups/group/${payload}`).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  authorize (payload) {
    return new Promise((resolve, reject) => {
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/login', payload).then(response => {
          resolve({ status: 'ok', response: response })
        }).catch(error => {
          resolve({ status: 'error', error: error })
        })
      })
    })
  },
  deauthorize (payload) {
    return new Promise((resolve, reject) => {
      axios.post('/logout').then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  placeOrder (order) {
    return new Promise((resolve, reject) => {
      axios.post('/api/orders', { ...order }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  blacklistProduct (payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/blacklists', { ...payload }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchBlacklist () {
    return new Promise((resolve, reject) => {
      axios.get('/api/blacklists').then(response => {
        resolve(response.data)
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  deleteFromBlacklist (id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/blacklists/${id}`).then(response => {
        resolve(response.data)
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  editProduct (payload) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      for (const [key, value] of Object.entries(payload)) {
        formData.append(key, value)
      }
      axios.post(`/api/products/product/${payload.id}`, formData).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchUsers (usersFilter) {
    return new Promise((resolve, reject) => {
      axios.get('/api/users', { params: usersFilter }).then(response => {
        resolve(response.data)
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchAllUserRoles () {
    return new Promise((resolve, reject) => {
      axios.get('/api/users/roles').then(response => {
        resolve(response.data)
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  createUser (payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/users/', { ...payload }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  editUser (payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/users/${payload.id}`, { ...payload }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  requestUserDataChange (payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/users/${payload.user_id}/requestchange`, { ...payload }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  deleteUser (payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/users/${payload}`).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  restoreUser (payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/users/${payload}/restore`).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchFTPs (ftpsFilter) {
    return new Promise((resolve, reject) => {
      axios.get('/api/eoc', { params: ftpsFilter }).then(response => {
        resolve(response.data)
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  createFTP (payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/eoc/', { ...payload }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  editFTP (payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/eoc/${payload.id}`, { ...payload }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  deleteFTP (payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/eoc/${payload}`).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  restoreFTP (payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/eoc/${payload}/restore`).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchAdminMeta () {
    return new Promise((resolve, reject) => {
      axios.get('/api/admin/meta').then(response => {
        resolve(response.data)
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchStaticPage (payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/cms/staticpage/${payload.pslug}`, { params: { language: payload.language } }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  saveStaticPage (payload) {
    return new Promise((resolve, reject) => {
      let saveOrCreate = '/api/cms/staticpage/'
      if (payload.id > 0) {
        saveOrCreate = `/api/cms/staticpage/${payload.pslug}`
      }
      console.log('saveStaticPage payload', payload)
      const formData = new FormData()
      for (const [key, value] of Object.entries(payload)) {
        if (key === 'files') {
          for (const [fkey, fvalue] of Object.entries(value)) {
            console.log('floop', fvalue)
            if (fvalue === null) {
              // sometimes we get null here, meh.
            } else if (fvalue.constructor === Array) {
              fvalue.forEach((item, index) => {
                formData.append(fkey + '_image[]', item, index)
              })
            } else if (fvalue.constructor === File) {
              formData.append(fkey + '_image', fvalue)
            }
          }
        } else {
          formData.append(key, value)
        }
      }
      console.log('saveStaticPage formData', formData)
      axios.post(saveOrCreate, formData).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchQuickAdd (params) {
    return new Promise((resolve, reject) => {
      axios.get('/api/products/quickadd', { params: params }).then(response => {
        const products = transformResponse(response.data.products)
        const productMap = {}
        for (const [index, product] of Object.entries(response.data.product_map)) {
          productMap[index] = transformProduct(product)
        }
        resolve({ status: 'ok', products: products, product_map: productMap })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchLabelHighlights () {
    return new Promise((resolve, reject) => {
      axios.get('/api/cms/labels/highlights').then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchCatalogs (catalogsFilter) {
    return new Promise((resolve, reject) => {
      axios.get('/api/cms/catalogs', { params: catalogsFilter }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  createCatalog (payload) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      for (const [key, value] of Object.entries(payload)) {
        formData.append(key, value)
      }
      axios.post('/api/cms/catalogs/', formData).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  editCatalog (payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/cms/catalogs/${payload.id}`, { ...payload }).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  deleteCatalog (payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/cms/catalogs/${payload}`).then(response => {
        resolve({ status: 'ok', response: response })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchLabels () {
    return new Promise((resolve, reject) => {
      axios.get('/api/labels/').then(response => {
        resolve({ status: 'ok', response: response.data })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  fetchReports () {
    return new Promise((resolve, reject) => {
      axios.get('/api/reports/').then(response => {
        resolve({ status: 'ok', response: response.data })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  getTurnover (tunroverParams) {
    return new Promise((resolve, reject) => {
      axios.get('/api/turnover', { params: tunroverParams }).then(response => {
        console.log(response)
        resolve({ status: 'ok', response: response.data })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  },
  getProductsExcel (productsFilter) {
    return new Promise((resolve, reject) => {
      axios.get('/api/products/export', { params: productsFilter }).then(response => {
        console.log(response)
        resolve({ status: 'ok', blob: response.data })
      }).catch(error => {
        resolve({ status: 'error', error: error })
      })
    })
  }
}
