<template>
<div class="admin__ftpdata">
  <b-modal :id="this.modalID"
           :title="this.modalTitle"
           :ok-title="this.modalOK"
           :cancel-title="$t('Cancel')"
           :busy="form.busy"
           @show="handleShow"
           @ok="handleOk">
      <div role="group">
        <b-form-invalid-feedback :id="`${this.ID}-input-user_id-feedback`">
          {{ form.user_id.error }}
        </b-form-invalid-feedback>

        <UserDropdown v-model="form.user_id.data" />

        <label :for="`${this.ID}-input-type`" class="mb-0">{{ $t('Type') }}</label>
        <b-form-select
          :id="`${this.ID}-input-type`"
          v-model="form.type.data"
          :state="form.type.state"
          :options="type_list"
          :required="true"
          class="mb-2"
        ></b-form-select>
        <b-form-invalid-feedback :id="`${this.ID}-input-type-feedback`">
          {{ form.type.error }}
        </b-form-invalid-feedback>

        <div v-if="form.type.data == 'email' || form.type.data == 'ftp_external'">
          <label :for="`${this.ID}-input-connection`" class="mb-0">{{ $t('Connection') }}</label>
          <b-form-textarea
            :id="`${this.ID}-input-connection`"
            v-model="form.connection.data"
            :state="form.connection.state"
            :required="false"
            class="mb-2"
            rows="5"
          ></b-form-textarea>
        </div>
        <b-form-invalid-feedback :id="`${this.ID}-input-connection-feedback`">
          {{ form.connection.error }}
        </b-form-invalid-feedback>

        <label :for="`${this.ID}-input-path`" class="mb-0">{{ $t('Path') }}</label>
        <b-form-input
          :id="`${this.ID}-input-path`"
          v-model="form.path.data"
          :state="form.path.state"
          :required="true"
          class="mb-2"
          trim
        ></b-form-input>
        <b-form-invalid-feedback :id="`${this.ID}-input-path-feedback`">
          {{ form.path.error }}
        </b-form-invalid-feedback>

        <label :for="`${this.ID}-input-delimiter`" class="mb-0">{{ $t('Delimiter') }}</label>
        <b-form-select
          :id="`${this.ID}-input-delimiter`"
          v-model="form.delimiter.data"
          :state="form.delimiter.state"
          :options="delimiter_list"
          :required="true"
          class="mb-2"
        ></b-form-select>
        <b-form-invalid-feedback :id="`${this.ID}-input-delimiter-feedback`">
          {{ form.delimiter.error }}
        </b-form-invalid-feedback>

        <b-form-checkbox
          :id="`${this.ID}-checkbox-hasheader`"
          v-model="form.has_header.data"
          name="checkbox-hasheader"
          :value="true"
          :unchecked-value="false"
        >
            {{ $t('Has Header (first line of file contains header definition)') }}
        </b-form-checkbox>

        <label :for="`${this.ID}-input-header`" class="mb-0">{{ $t('Header (manual)') }}</label>
        <b-form-input
          :id="`${this.ID}-input-header`"
          v-model="form.header.data"
          :state="form.header.state"
          :required="true"
          class="mb-2"
          trim
        ></b-form-input>
        <b-form-invalid-feedback :id="`${this.ID}-input-header-feedback`">
          {{ form.header.error }}
        </b-form-invalid-feedback>

        <label class="mb-0">{{ $t('Header (Drag&Drop)') }}</label>

        <draggable class="dragArea d-flex border border-dark"
                  style="height: 2.3rem; margin-bottom: 0.5rem;"
                  :list="header"
                  :group="{ name: 'header', put: true }"
                  >
          <div class=""
                v-for="element in header"
                :key="element.name"
          >
            <b-badge class="p-2 m-1">{{ element.name }}</b-badge>
          </div>
        </draggable>

        <draggable class="dragArea d-flex"
                  :list="header_list"
                  :group="{ name: 'headers', pull: true, put: true }"
                  >
          <div class=""
                v-for="element in header_list"
                :key="element.name"
          >
            <b-badge variant="info" class="p-2 m-1">{{ element.name }}</b-badge>
          </div>
        </draggable>

        <draggable class="dragArea d-flex"
                  :list="header_repeat_list"
                  :group="{ name: 'header_repeat_list', pull: 'clone', put: false }"
                  >
          <div class=""
                v-for="element in header_repeat_list"
                :key="element.name"
          >
            <b-badge variant="dark" class="p-2 m-1">{{ element.name }}</b-badge>
          </div>
        </draggable>
    </div>
  </b-modal>
</div>
</template>

<script>

import draggable from 'vuedraggable'

import { mapActions } from 'vuex'

import UserDropdown from '@/components/admin/UserDropdown'

function boolNormalize (qb) {
  if (qb === 1) {
    return true
  }
  return false
}

function getFormData (ftp, delimiterText) {
  return {
    form: {
      user_id: {
        data: ftp ? ftp.user_id : '',
        state: null,
        error: null
      },
      type: {
        data: ftp ? ftp.type : 'ftp_external',
        state: null,
        error: null
      },
      connection: {
        data: ftp ? ftp.connection : '',
        state: null,
        error: null
      },
      path: {
        data: ftp ? ftp.path : '',
        state: null,
        error: null
      },
      has_header: {
        data: ftp ? boolNormalize(ftp.has_header) : true,
        state: null,
        error: null
      },
      header: {
        data: ftp ? ftp.header : '',
        state: null,
        error: null
      },
      delimiter: {
        data: ftp ? ftp.delimiter : '\\t',
        state: null,
        error: null
      },
      busy: false
    },
    delimiter_list: [
      { value: '\\t', text: '<tab>' },
      { value: ' ', text: '<space>' },
      { value: ',', text: '<,>' },
      { value: ';', text: '<;>' }
    ],
    type_list: [
      { value: 'ftp', text: 'FTP' },
      { value: 'ftp_external', text: 'FTP External' },
      { value: 'email', text: 'email' }
    ],
    header_repeat_list: [
      { name: delimiterText, id: 5, content: 'delimiter' },
      { name: '<skip>', id: 6, content: 'skip' }
    ],
    header_list: [
      { name: 'ean', id: 1, content: 'ean' },
      { name: 'sku', id: 2, content: 'sku' },
      { name: 'quantity', id: 3, content: 'quantity' },
      { name: 'price', id: 4, content: 'price' }
    ],
    delimiter: [],
    header: []
  }
}
export default {
  name: 'FTPDataModal',
  components: {
    UserDropdown,
    draggable
  },
  props: {
    ftp: [Object, Boolean]
  },
  data () {
    return getFormData(this.ftp, this.delimiterText)
  },
  computed: {
    ID () {
      if (!this.ftp) {
        return 'create-'
      }
      return `${this.ftp.id}-`
    },
    modalID () {
      if (!this.ftp) {
        return 'modal-add-ftp'
      }
      return `edit-ftp-${this.ftp.id}`
    },
    modalTitle () {
      if (!this.ftp) {
        return this.$t('Add FTP')
      }
      return this.$t('Edit FTP')
    },
    modalOK () {
      if (!this.ftp) {
        return this.$t('Add')
      }
      return this.$t('Save')
    },
    delimiterText () {
      const tmpD = this.delimiter_list.find(el => el.value === this.form.delimiter.data)
      return tmpD.text
    }
  },
  methods: {
    ...mapActions({
      createFTP: 'admin/createFTP',
      editFTP: 'admin/editFTP',
      fetchFTPs: 'admin/fetchFTPs'
    }),
    handleOk (event) {
      event.preventDefault()
      this.form.busy = true
      let tmpHeader = ''
      console.log('Delimiter Data', this.form.delimiter.data)
      this.header.forEach(el => {
        if (typeof el !== 'object') {
          return
        }
        let tel = el.content
        console.log('element', el, el.content)
        if (tel === 'delimiter') {
          tel = this.form.delimiter.data
        }
        tmpHeader = `${tmpHeader}${tel}`
      })
      console.log(tmpHeader)
      this.form.header.data = tmpHeader
      this.handleSubmit()
    },
    handleSubmit () {
      const ftpData = {
        user_id: this.form.user_id.data,
        path: this.form.path.data,
        header: this.form.header.data,
        delimiter: this.form.delimiter.data,
        type: this.form.type.data,
        connection: this.form.connection.data,
        has_header: 0
      }
      console.log(ftpData)
      if (this.form.has_header.data) {
        ftpData.has_header = 1
      }

      if (this.ftp) {
        ftpData.id = this.ftp.id
        this.editFTP(ftpData).then(response => {
          this.handleResponse(response)
        })
      } else {
        this.createFTP(ftpData).then(response => {
          this.handleResponse(response)
        })
      }
    },
    handleResponse (response) {
      if (response.status === 'ok') {
        // TO-DO: Display success notification
        this.fetchFTPs()
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalID)
        })
      } else {
        Object.keys(response.error.data.errors).forEach(key => {
          this.form[key].state = false
          this.form[key].error = response.error.data.errors[key][0]
        })
      }
      this.form.busy = false
    },
    handleShow () {
      Object.assign(this.$data, this.$options.data.apply(this))
      const tmpH = this.form.header.data.split(this.form.delimiter.data)

      tmpH.forEach((element, index) => {
        if (element === '') {
          return
        }
        if (index !== 0) {
          this.header.push({ name: this.delimiterText, id: 5, content: 'delimiter' })
        }
        if (element === 'skip') {
          this.header.push({ name: '<skip>', id: 6, content: 'skip' })
        } else {
          this.header.push(this.header_list.find(el => el.content === element))
          this.header_list = this.header_list.filter(el => el.content !== element)
        }
      })
      console.log(this.header)
    }
  }
}
</script>

<style lang="scss">
.user_dropdown-container .v-select {
  margin-right: 0;
}
</style>
