<template>
  <b-container class="textblock d-flex justify-content-center">
    <div class="col-12 col-lg-6">
      <div class="small_separator"> </div>
      <div>
        {{ text }}
      </div>
    </div>
  </b-container>
</template>

<script>

export default {
  name: 'TextBlock',
  props: {
    text: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.textblock {
  font-size: 20px;
  margin-bottom: 75px;
  text-transform: uppercase;
}

</style>
