import Router from 'vue-router'
import VueBodyClass from 'vue-body-class'

import store from './store/'

import FrontPageHolder from './views/FrontPageHolder'
import Listing from './views/products/Listing'
import Login from './views/account/Login'
import Catalogs from './views/cms/Catalogs'
import Contact from './views/cms/Contact'
import EANOrder from './views/products/EANOrder'
import UserAccount from './views/account/UserAccount'
import Cart from './views/products/Cart'
import AdminPanel from './views/admin/AdminPanel'
import PageNotFound from './views/PageNotFound'
import AdminPanelHome from '@/components/admin/AdminPanelHome'
import ProductBlacklistList from '@/components/admin/ProductBlacklistList'
import UserList from '@/components/admin/UserList'
import CMSFrontPageAdmin from '@/components/admin/cms/FrontPage'
import CMSStaticPageAdmin from '@/components/admin/cms/StaticPage'
import CMSStaticPage from '@/views/cms/StaticPage'
import CatalogList from '@/components/admin/cms/CatalogList'
import LabelList from '@/components/admin/LabelList'
import Turnover from '@/components/admin/Turnover'
import FTPList from '@/components/admin/FTPList'
import ProductGroupList from '@/components/admin/ProductGroupList'

const routes = [
  {
    path: '/',
    name: 'frontpage',
    component: FrontPageHolder,
    meta: {
      header: {
        type: 'full',
        contact: true
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true,
    meta: {
      requiresVisitor: true,
      bodyClass: 'page-login',
      header: {
        type: 'light',
        contact: true,
        right: 'back'
      }
    }
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: async (to, from, next) => {
      await store.dispatch('auth/deauthorize')
      return next('/')
    }
  },
  {
    path: '/konto',
    name: 'konto',
    component: UserAccount
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: {
      footer: {
        show: false
      }
    }
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: Contact,
    props: true,
    meta: {
      requiresVisitor: true,
      bodyClass: 'page-contact',
      header: {
        type: 'light',
        right: 'back'
      }
    }
  },
  {
    path: '/listing/:group?',
    name: 'listing',
    component: Listing,
    props: true
  },
  {
    path: '/kataloge',
    name: 'kataloge',
    component: Catalogs,
    props: true
  },
  {
    path: '/ean',
    name: 'eanorder',
    component: EANOrder,
    props: true,
    meta: {
      header: {
        type: 'light',
        right: 'back'
      },
      footer: {
        show: false
      }
    }
  },
  {
    path: '/page/:pslug',
    name: 'staticpage',
    component: CMSStaticPage,
    props: true
  },
  {
    path: '/admin',
    component: AdminPanel,
    children: [
      {
        path: '',
        name: 'admin',
        component: AdminPanelHome
      },
      {
        path: 'blacklists',
        name: 'admin/blacklists',
        component: ProductBlacklistList
      },
      {
        path: 'product_groups',
        name: 'admin/product_groups',
        component: ProductGroupList
      },
      {
        path: 'turnover',
        name: 'admin/turnover',
        component: Turnover
      },
      {
        path: 'users',
        name: 'admin/users',
        component: UserList
      },
      {
        path: 'ftp',
        name: 'admin/ftp',
        component: FTPList
      },
      {
        path: 'labels',
        name: 'admin/labels',
        component: LabelList
      },
      {
        path: 'cms/frontpage',
        name: 'admin/cms/frontpage',
        component: CMSFrontPageAdmin
      },
      {
        path: 'cms/kataloge',
        name: 'admin/cms/kataloge',
        component: CatalogList
      },
      {
        path: 'cms/agb',
        name: 'admin/cms/agb',
        component: CMSStaticPageAdmin,
        props: {
          pslug: 'agb'
        }
      },
      {
        path: 'cms/datenschutz',
        name: 'admin/cms/datenschutz',
        component: CMSStaticPageAdmin,
        props: {
          pslug: 'datenschutz'
        }
      },
      {
        path: 'cms/impressum',
        name: 'admin/cms/impressum',
        component: CMSStaticPageAdmin,
        props: {
          pslug: 'impressum'
        }
      },
      {
        path: 'cms/team',
        name: 'admin/cms/team',
        component: CMSStaticPageAdmin,
        props: {
          pslug: 'team'
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      if (!store.state.auth.isAdmin) {
        return next('/')
      }

      next()
    }
  },
  {
    path: '*',
    component: PageNotFound
  }
]
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

const vueBodyClass = new VueBodyClass(routes)
router.beforeEach((to, from, next) => {
  // Default meta values
  if (to.meta.footer === undefined) {
    to.meta.footer = { show: true }
  }
  if (to.meta.header === undefined) {
    to.meta.header = { type: 'full' }
  }
  if (to.meta.auth === undefined) {
    to.meta.auth = true
  }
  vueBodyClass.guard(to, next)
})

export default router
