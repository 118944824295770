<template>
<div class="admin__labels">
  <div class="w-100 d-flex flex-row justify-content-between">
    <h2>{{ $t('Labels') }}</h2>
  </div>
  <b-table striped
            :items="labelsData"
            :fields="fields.labels"
            :busy="labelsMeta.isBusy">
    <template v-slot:head(name)="data">
      {{ data.label }} <b-form-input autocomplete="off" v-model="usersLocalFilterName"></b-form-input>
    </template>
    <template v-slot:head(display_in_frontpage)="data">
      {{ data.label }} <b-form-input autocomplete="off" v-model="labelsLocalFilterDisplayFrontpage"></b-form-input>
    </template>

    <template v-slot:cell(actions)="data">
      <div style="">
        <b-button size="sm" variant="primary" @click="editLabelAction(data.item)">
          <b-icon-pencil-square />
        </b-button>
      </div>
      <!-- TO-DO: Look into lazy loading this -->
      <UserDataModal :user="data.item" />
    </template>
  </b-table>
    <b-row class="pagination-container col-12 justify-content-center">
      <b-pagination
        v-model="labelsLocalCurrentPage"
        :total-rows="labelsMeta.count"
        :per-page="labelsMeta.rowsPerPage"
        :hide-ellipsis="true"
        :limit="7"
      >

      <template v-slot:first-text><span class="start">{{ $t('Start') }}</span></template>
      <template v-slot:prev-text><b-img class="arrow arrow-prev" :src="require('@/assets/icons/arrow.png')"></b-img></template>
      <template v-slot:next-text><b-img class="arrow arrow-right" :src="require('@/assets/icons/arrow.png')"></b-img></template>
      <template v-slot:last-text><span class="end">{{ $t('End') }}</span></template>
    </b-pagination>
  </b-row>
</div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import UserDataModal from '@/components/admin/UserDataModal'

export default {
  name: 'LabelList',
  components: {
    UserDataModal
  },
  data () {
    return {
      fields: {
        labels: [
          {
            key: 'name'
          },
          {
            key: 'display_in_frontpage'
          },
          {
            key: 'actions'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      labelsData: state => state.admin.labelsData,
      labelsMeta: state => state.admin.labelsMeta,
      labelsFilter: state => state.admin.labelsFilter
    }),
    labelsLocalCurrentPage: {
      get () {
        return this.labelsMeta.currentPage
      },
      set (value) {
        this.setLabelsCurrentPage({ currentPage: value })
      }
    },
    labelsLocalFilterName: {
      get () {
        return this.labelsFilter.name
      },
      set (value) {
        this.setLabelsFilter({ name: value })
        this.eventuallyFetchLabels()
      }
    }
  },
  created () {
    this.fetchLabels()
  },
  methods: {
    ...mapActions({
      setLabelsCurrentPage: 'admin/setLabelsCurrentPage',
      setLabelsFilter: 'admin/setLabelsFilter',
      eventuallyFetchLabels: 'admin/eventuallyFetchLabels',
      fetchLabels: 'admin/fetchLabels'
    }),
    editLabelAction (user) {
      this.$bvModal.show(`edit-label-${user.id}`)
    }
  }
}
</script>

<style lang="scss">

</style>
