<template>
  <b-row class="product_row">
    <b-col v-if="index_box" cols="1" style="display: flex; align-items: baseline;">
      <div class="product_row__index_box">
      {{ index_box }}
      </div>
    </b-col>
    <b-col v-if="products_count" cols="2">
      <span>{{ $t('Number')}}: {{ products_count }}</span>
    </b-col>
    <b-col class="product_row__mediainfo" cols="3">
      <b-card-img class="product_row__image" :src="product.image"></b-card-img>
      <div class="product_row__sideinfo">
        <span>{{ product.display_format }}</span>
     <!--   <b-img :src="require('@/assets/icons/info_round.png')"></b-img>
        <b-img :src="require('@/assets/icons/checkmark_round.png')"></b-img>
        <b-img :src="require('@/assets/icons/price_round.png')"></b-img> -->
        <div class="controls">
          <ProductBlacklistControl v-if="isAdmin" :upc="product.upc" upc_type="ean" />
          <ProductEditControl v-if="isAdmin" :product="product" />
        </div>
      </div>
    </b-col>
    <b-col class="product_row__textinfo" :cols="textinfoCols">
      <span class="product_row__textinfo__author">{{ product.artist }}</span>
      <span class="product_row__textinfo__title">{{ product.title }}</span>
      <div>
        <span class="product_row__label small">{{ product.label }} </span>
        <span class="product_row__ean small text-muted">{{ product.upc }}</span>
      </div>
    </b-col>
    <b-col cols="3">
      <ProductOrder :product="product" />
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'

import ProductOrder from '@/components/products/ProductOrder'
import ProductBlacklistControl from '@/components/products/ProductBlacklistControl'
import ProductEditControl from '@/components/products/ProductEditControl'

export default {
  name: 'ProductRow',
  data: function () {
    return {
      base_textinfo_cols: 6
    }
  },
  components: {
    ProductOrder,
    ProductBlacklistControl,
    ProductEditControl
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    index_box: {
      type: Number
    },
    products_count: {
      type: Number
    }
  },
  computed: {
    ...mapState({
      isAdmin: state => state.auth.isAdmin
    }),
    textinfoCols () {
      let tmpSpace = 0
      if (this.index_box) {
        tmpSpace = tmpSpace + 1
      }
      if (this.products_count) {
        tmpSpace = tmpSpace + 2
      }
      return this.base_textinfo_cols - tmpSpace
    }
  }
}
</script>

<style lang="scss">
.product_row {
  &__index_box {
    background-color: var(--primary);
    color: white;
    padding: 15px 20px;
    display: inline;
  }

  &__image {
    max-width: 90px;
    height: fit-content;
  }

  &__mediainfo {
    display: flex;
    flex-direction: row;
  }

  &__textinfo {
    display: flex;
    flex-direction: column;

    &__author {
      font-weight: bold;
    }
  }

  &__sideinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 40px;

    span {
      display: block;
      font-weight: 700;
    }

    img {
      margin-right: 10px;
    }

    .controls {
      display: flex;
    }
  }
}
</style>
