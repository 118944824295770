<template>
  <div class="product_edit">
    <a href="#" @click.stop="$bvModal.show(`product_edit_modal${product.upc}`)"><b-img :src="require('@/assets/icons/edit_round.png')"></b-img></a>
      <b-modal :id="`product_edit_modal${product.upc}`" @ok="handleChange" :title="`${$t('Editing')} - ${product.upc}`">
        <img :src="fileURL" class="mb-2" style="max-width: 100%;" />
        <b-form-file v-model="file"
                     class="mb-2"
                     @change="onFileChange"
                     accept="image/*"
                     >
        </b-form-file>
        <b-form-input v-model="artist" class="mb-2"></b-form-input>
        <b-form-input v-model="title" class="mb-2"></b-form-input>
        <b-form-input v-model="label" class="mb-2"></b-form-input>
        <b-form-input v-model="format" class="mb-2"></b-form-input>

          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button size="sm" variant="success" @click="ok()">
              {{ $t('Save') }}
            </b-button>
            <b-button size="sm" variant="secondary" @click="cancel()">
              {{ $t('Cancel') }}
            </b-button>
          </template>
      </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProductEditControl',
  props: {
    product: Object
  },
  data () {
    return {
      file: null,
      fileURL: this.product.image,
      artist: this.product.artist,
      title: this.product.title,
      format: this.product.format,
      label: this.product.label
    }
  },
  methods: {
    ...mapActions({
      editProduct: 'products/editProduct'
    }),
    handleChange () {
      // TO-DO: There has to be a cleaner way of doing this
      const newData = { id: this.product.id }
      if (this.artist !== this.product.artist) {
        newData.artist = this.artist
      }
      if (this.title !== this.product.title) {
        newData.title = this.title
      }
      if (this.format !== this.product.format) {
        newData.format = this.format
      }
      if (this.label !== this.product.label) {
        newData.label = this.label
      }

      if (this.file) {
        newData.image = this.file
      }
      this.editProduct(newData)
    },
    onFileChange (e) {
      const file = e.target.files[0]
      this.fileURL = URL.createObjectURL(file)
    }
  }
}
</script>

<style lang="scss">
.product_edit {
  text-align: center;
}
</style>
