<template>
<div class="admin__userdata">
  <b-modal :id="this.modalID"
           :title="$t(this.modalTitle)"
           :ok-title="$t(this.modalOK)"
           :cancel-title="$t('Cancel')"
           :busy="form.busy"
           @show="handleShow"
           @ok="handleOk">
      <div role="group">
        <label :for="`${this.ID}-input-username`" class="mb-0">{{ $t('Username') }}</label>
        <b-form-input
          :id="`${this.ID}-input-username`"
          v-model="form.username.data"
          :state="form.username.state"
          :required="true"
          class="mb-2"
          trim
        ></b-form-input>
        <b-form-invalid-feedback :id="`${this.ID}-input-username-feedback`">
          {{ form.username.error }}
        </b-form-invalid-feedback>

        <label :for="`${this.ID}-input-name`" class="mb-0">{{ $t('Name') }}</label>
        <b-form-input
          :id="`${this.ID}-input-name`"
          v-model="form.name.data"
          :state="form.name.state"
          :required="true"
          class="mb-2"
          trim
        ></b-form-input>
        <b-form-invalid-feedback :id="`${this.ID}-input-name-feedback`">
          {{ form.name.error }}
        </b-form-invalid-feedback>

        <label :for="`${this.ID}-input-email`" class="mb-0">{{ $t('E-Mail') }}</label>
        <b-form-input
          :id="`${this.ID}-input-email`"
          v-model="form.email.data"
          :state="form.email.state"
          :required="true"
          class="mb-2"
          trim
        ></b-form-input>
        <b-form-invalid-feedback :id="`${this.ID}-input-email-feedback`">
          {{ form.email.error }}
        </b-form-invalid-feedback>

        <label :for="`${this.ID}-input-password`" class="mb-0">{{ $t('Password') }}</label>
        <b-form-input
          :id="`${this.ID}-input-password`"
          v-model="form.password.data"
          :state="form.password.state"
          :required="true"
          class="mb-2"
          trim
        ></b-form-input>
        <b-form-invalid-feedback :id="`${this.ID}-input-password-feedback`">
          {{ form.password.error }}
        </b-form-invalid-feedback>

        <b-form-checkbox
          :id="`${this.ID}-checkbox-isadmin`"
          v-model="form.admin"
          name="checkbox-isadmin"
          :value="true"
          :unchecked-value="false"
        >
      {{ $t('Administrator') }}
    </b-form-checkbox>
    </div>
  </b-modal>
</div>
</template>

<script>

import { mapActions } from 'vuex'

function checkIsAdmin (user) {
  if (user) {
    if (user.role_names.includes('administrator')) {
      return true
    }
  }

  return false
}

function getFormData (user) {
  return {
    form: {
      username: {
        data: user ? user.username : '',
        state: null,
        error: null
      },
      name: {
        data: user ? user.name : '',
        state: null,
        error: null
      },
      password: {
        data: '',
        state: null,
        error: null
      },
      email: {
        data: user ? user.email : '',
        state: null,
        error: null
      },
      admin: checkIsAdmin(user),
      busy: false
    }
  }
}
export default {
  name: 'UserDataModal',
  props: {
    user: [Object, Boolean]
  },
  data () {
    return getFormData(this.user)
  },
  computed: {
    ID () {
      if (!this.user) {
        return 'create-'
      }
      return `${this.user.id}-`
    },
    modalID () {
      if (!this.user) {
        return 'modal-add-user'
      }
      return `edit-user-${this.user.id}`
    },
    modalTitle () {
      if (!this.user) {
        return this.$t('Add User')
      }
      return this.$t('Edit User')
    },
    modalOK () {
      if (!this.user) {
        return this.$t('Add')
      }
      return this.$t('Save')
    }
  },
  methods: {
    ...mapActions({
      createUser: 'admin/createUser',
      editUser: 'admin/editUser',
      fetchUsers: 'admin/fetchUsers'
    }),
    handleOk (event) {
      event.preventDefault()
      this.form.busy = true
      this.handleSubmit()
    },
    handleSubmit () {
      const userData = {
        name: this.form.name.data,
        username: this.form.username.data,
        password: this.form.password.data,
        email: this.form.email.data
      }
      if (this.form.admin) {
        userData.admin = true
      }

      if (this.user) {
        userData.id = this.user.id
        this.editUser(userData).then(response => {
          this.handleResponse(response)
        })
      } else {
        this.createUser(userData).then(response => {
          this.handleResponse(response)
        })
      }
    },
    handleResponse (response) {
      if (response.status === 'ok') {
        // TO-DO: Display success notification
        this.fetchUsers()
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalID)
        })
      } else {
        Object.keys(response.error.data.errors).forEach(key => {
          this.form[key].state = false
          this.form[key].error = response.error.data.errors[key][0]
        })
      }
      this.form.busy = false
    },
    handleShow () {
      Object.assign(this.$data, this.$options.data.apply(this))
    }
  }
}
</script>

<style lang="scss">

</style>
