<template>
  <b-container class="header__public">
    <div class="header__public__side">
      <router-link v-if="$route.meta.header.contact" to="/kontakt">{{ $t('Contact')}} <b-img :src="require('@/assets/icons/arrow_secondary.png')"></b-img> </router-link>
    </div>
    <b-img class="header__public__logo" :src="require('@/assets/idi-logo.png')"></b-img>

    <div class="header__public__side d-flex justify-content-end">
      <a v-if="$route.meta.header.right === 'back'" @click="$router.go(-1)"><b-img id="exit_icon" :src="require('@/assets/icons/x_secondary.png')"></b-img></a>
      <b-button class="login_button" v-else variant="secondary" to="/login"><b-img :src="require('@/assets/icons/person.png')"></b-img> <span>{{$t('Login')}}</span> <b-img :src="require('@/assets/icons/arrow_white.png')"></b-img></b-button>
    </div>
  </b-container>
</template>

<script>

export default {
  name: 'PublicHeader'
}
</script>

<style lang="scss">
.header__public {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  text-transform: uppercase;

  &__side {
    flex-basis: 0;
    flex-grow: 1;

    a.login_button {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > *:nth-child(1),
      & > *:nth-child(2) {
        margin-right: 10px;
      }
    }
  }

  &__logo {
    width: 100px;
    height: 100px;
  }

  #exit_icon {
    cursor: pointer;
  }
}

.header {
  &__loginb {
    padding: 10px 25px;
    background-color: var(--secondary);
    color: white;
  }
  &__email {
    img {
      width: 25px;
      height: 18px;
    }
  }
}

</style>
