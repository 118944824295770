<template>
<div class="product_quantity">
<b-input-group>
  <template v-slot:append>
    <!--
    <b-input-group-text>
      <b-img @click="increase()" :src="require('@/assets/icons/arrow_small.png')"></b-img>
      <b-img @click="decrease()" style="transform: rotate(180deg); margin-top: 1px;" :src="require('@/assets/icons/arrow_small.png')"></b-img>
    </b-input-group-text>
    -->
  </template>
  <template v-if="showAvailable" v-slot:prepend>
    <b-input-group-text v-bind:class="{ overmax: isOverMax }">
      {{ max }}
    </b-input-group-text>
  </template>
  <b-form-input size="sm"
                type="number"
                :class="{ nonzero: isNonZero }"
                v-model.number="localValue"
                :min="min"
                :max="max">
  </b-form-input>
  </b-input-group>
</div>
</template>

<script>
export default {
  name: 'ProductQuantityControl',
  props: {
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    showAvailable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (localValue) {
        this.$emit('input', localValue)
      }
    },
    isNonZero: function () {
      if (this.localValue !== 0) {
        return true
      }
      return false
    },
    isOverMax: function () {
      if (this.value > this.max) {
        return true
      }
      return false
    }
  },
  methods: {
    increase () {
      if ((this.localValue + 1) <= this.max) {
        this.localValue += 1
      }
    },
    decrease () {
      if ((this.localValue - 1) >= this.min) {
        this.localValue -= 1
      }
    }
  }
}
</script>

<style lang="scss">
.product_quantity {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance:textfield;
  }

  .input-group-append {
    position: absolute;
    z-index: 100;
    right: 5px;
    top: 9px;

    .input-group-text {
      display: flex;
      flex-direction: column;
      border: 0;
      padding: 0;
      background-color: transparent;

      img {
        cursor: pointer;
      }
    }
  }

  .input-group-prepend {
    .input-group-text {
      padding: 0 5px;
      font-size: 0.92rem;

      &.overmax {
        background-color: var(--orange);
      }
    }
  }
}
</style>
