<template>
  <div ref="filter_input" class="filter_input">
    <div class="filter_input__trigger" @click.stop="triggerFilter" >
      <span v-if="filter_start !== null && filter_end !== null"> € {{ filter_start }} - € {{ filter_end }}</span>
      <span v-else >{{ $t('Price') }}</span>
      <b-img :src="require('@/assets/icons/arrow.png')"></b-img>
    </div>
    <div class="filter_input__popup" v-click-outside="triggerClickOutside">
      <div class="input_row">
        <b-input-group prepend="€">
            <b-form-input v-model="sync_range[0]" :min="min" :max="max"></b-form-input>
        </b-input-group>
         <div class="separator"> </div>
        <b-input-group prepend="€">
            <b-form-input v-model="sync_range[1]" :min="min" :max="max"></b-form-input>
        </b-input-group>
      </div>
      <vue-slider :height="2" :min="Math.floor(min)" :max="Math.ceil(max)" tooltip="none" :enableCross="false" v-model="sync_range"></vue-slider>
      <div class="button_row">
        <b-button variant="white" size="sm" @click="resetToDefault()">{{ $t('Reset to default')}}</b-button>
        <b-button variant="primary" size="sm" @click="confirm()">{{ $t('Save')}}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import '@/styles/vue-slider-component/custom-theme.scss'

export default {
  name: 'PriceFilterInput',
  components: {
    VueSlider
  },
  props: {
    min: Number,
    max: Number,
    filter_start: Number,
    filter_end: Number
  },
  computed: {
    sync_range: {
      get () {
        if (this.filter_start === null && this.filter_end === null) {
          return [Math.floor(this.min), Math.ceil(this.max)]
        }
        return [this.filter_start, this.filter_end]
      },
      set (value) {
        if (this.filter_start !== value[0]) {
          this.$emit('update:filter_start', value[0])
        }
        if (this.filter_end !== value[1]) {
          this.$emit('update:filter_end', value[1])
        }
      }
    }
  },
  methods: {
    triggerFilter () {
      this.$refs.filter_input.classList.toggle('opened')
    },
    triggerClickOutside () {
      this.$refs.filter_input.classList.remove('opened')
    },
    resetToDefault () {
      this.sync_range = [null, null]
      this.$refs.filter_input.classList.remove('opened')
    },
    confirm () {
      // TO-DO: Decide on this functionaliy or the live one with debounce
      this.$refs.filter_input.classList.remove('opened')
    }
  }
}
</script>

<style lang="scss">
.filter_input {
  position: relative;

  &__trigger {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--primary);
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    user-select: none;
    width: 160px;
    margin-right: 10px;
    cursor: pointer;

    img {
      transform: rotate(90deg);
      transition: 0.5s ease-in-out;
      object-fit: none;
    }
  }

  &__popup {
    position: absolute;
    display: none;
    top: calc(1.5em + 0.75rem + 15px);
    border: 1px solid var(--primary);
    background-color: white;
    z-index: 100;
    padding: 10px;

    .input_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      input {
        margin-right: 0 !important;
        border-left: 0 !important;

        text-align: right;
      }

      .input-group-prepend {
        .input-group-text {
          border: 1px solid var(--primary);
          border-right: 0;
          background-color: white;
        }
      }

      .separator {
        width: 30px;
        margin-left: 15px;
        margin-right: 15px;
        border-bottom: 1px solid var(--primary);
        height: 20px;
      }
    }

    .vue-slider {
      margin-bottom: 10px;
    }

    .button_row {
      display: flex;
      flex-direction: row;

      button {
        padding: 8px 16px !important;
        font-size: 15px !important;
      }
    }
  }

  &.opened {
    .filter_input {
      &__popup {
        display: block;
      }

      &__trigger {
        img {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
</style>
