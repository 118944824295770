<template>
<div class="admin__product_groups">
  <div class="w-100 d-flex flex-row justify-content-between">
    <h2>{{ $t('Product Groups') }}</h2>
    <b-button variant="primary" v-b-modal.modal-add-product_group>{{ $t('Add') }}</b-button>
  </div>
  <b-table striped
            :items="productGroupsData"
            :fields="fields.productGroups"
            sort-by="order"
            :busy="productGroupsMeta.isBusy">

    <template v-slot:table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong> {{ $t('Loading') }}...</strong>
      </div>
    </template>

    <template v-slot:cell(actions)="data">
        <div style="">
            <b-button size="sm" variant="primary" @click="editProductGroupAction(data.item)">
                <b-icon-pencil-square />
            </b-button>
            <b-button size="sm" variant="danger" @click="deleteProductGroupAction(data.item)">
                <b-icon-trash />
            </b-button>
        </div>
      <ProductGroupDataModal :product_group="data.item" />
      <ProductGroupDeleteModal :product_group="data.item" />
    </template>
  </b-table>

  <ProductGroupDataModal :product_group="false" />
</div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import ProductGroupDataModal from '@/components/admin/ProductGroupDataModal'
import ProductGroupDeleteModal from '@/components/admin/ProductGroupDeleteModal'

export default {
  name: 'ProductGroupList',
  components: {
    ProductGroupDataModal,
    ProductGroupDeleteModal
  },
  data () {
    return {
      fields: {
        productGroups: [
          {
            key: 'id'
          },
          {
            key: 'name'
          },
          {
            key: 'order'
          },
          {
            key: 'show_in_menu'
          },
          {
            key: 'actions',
            label: this.$t('Actions')
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      productGroupsData: state => state.admin.productGroupsData,
      productGroupsMeta: state => state.admin.productGroupsMeta
    })
  },
  created () {
    this.fetchProductGroups()
  },
  methods: {
    ...mapActions({
      fetchProductGroups: 'admin/fetchProductGroups'
    }),
    editProductGroupAction (productGroup) {
      this.$bvModal.show(`edit-product_group-${productGroup.id}`)
    },
    deleteProductGroupAction (productGroup) {
      this.$bvModal.show(`delete-product_group-${productGroup.id}`)
    }
  }
}
</script>

<style lang="scss">

</style>
