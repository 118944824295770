<template>
<div class="user_dropdown-container">
<v-select id="user-dropdown"
    :components="{OpenIndicator}"
    :clearable="true"
    :options="userOptions"
    :reduce="user => user.id"
    v-model="catalogsLocalFilterLabel"
    @search="fetchOptions"
    :filterable="false"
    @input="setSelected"
    :placeholder="this.$t('Customer')"
    ></v-select>
</div>
</template>

<script>
import axios from 'axios'

import _ from 'lodash'

export default {
  name: 'UserDropdown',
  data () {
    return {
      OpenIndicator: {
        render: createElement => createElement('img', { attrs: { src: require('@/assets/icons/arrow.png') } })
      },
      catalogsLocalFilterLabel: false,
      users: []
    }
  },
  computed: {
    userOptions: function () {
      return this.users.map(e => {
        return { id: e.id, label: `${e.username} - ${e.name}` }
      })
    }
  },
  created () {
    this.fetchUsers()
  },
  methods: {
    /**
     * Triggered when the search text changes.
     *
     * @param search  {String}    Current search text
     * @param loading {Function}  Toggle loading class
     */
    fetchOptions (search, loading) {
      if (!this.catalogsLocalFilterLabel) {
        loading(true)
        this.fetchUsers(search).then(() => {
          loading(false)
        })
      }
    },
    fetchUsers: _.debounce(function (search) {
      return axios.get('/api/users', { params: { nameusername: search } }).then((res) => {
        this.users = res.data.users
      })
    }, process.env.VUE_APP_INPUT_DEBOUNCE),
    setSelected (value) {
      console.log('setSelected', value)
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.user_dropdown {
  &-container {
    $vs-border-radius: 0;
    $vs-border-color: #ced4da;
    $vs-selected-bg: white;
    $vs-component-placeholder-color: var(--primary);
    $vs-state-active-bg: white;
    $vs-state-active-color: var(--primary);
    $vs-controls-color: var(--primary);
    $vs-component-line-height: 1.65;
    @import "vue-select/src/scss/vue-select.scss";
    @include style-select(true);

    min-width: 300px;
  }
}
</style>
