<template>
  <div class="header--authenticated">
    <b-alert v-if="'has_valid_email' in user && !user.has_valid_email"
             show variant="warning">
             {{$t('You have an invalid email address associated with this account.')}} {{$t('Please update it in')}} <router-link :to="{ name: 'konto' }">{{$t('Account')}}</router-link>
    </b-alert>
    <div class="user-panel">
      <router-link :to="{ name: 'konto' }">{{ $t('Welcome') }} {{ user.name }}</router-link>
      <router-link v-if="isAdmin" :to="{ name: 'admin' }">{{$t('ADMIN AREA')}}</router-link>
    </div>
    <div class="navigation-panel">
      <b-img @click="$router.push('/').catch(err => {})"
            :src="require('@/assets/idi-logo.png')"
            class="logo"></b-img>
      <div class="menu-container">
        <div class="frow">
          <div class="language_flags">
            <b-img v-for="lang in languages"
                  :key="lang"
                  :src="require(`@/assets/flags/${lang}@2x.png`)"
                  @click="setLanguage(lang)"
                  role="button"
                  >
            </b-img>
          </div>
          <div class="search">
            <b-input-group>
              <b-form-input v-model="upcSearchQuery"
                            @input="handleSearchChange"
                            v-on:keyup.enter="handleSearch"
                            type="search"
                            :placeholder="$t('EAN/BARCODE-SUCHE')">
              </b-form-input>
              <template v-slot:append>
                <b-img @click="handleSearch" :src="require('@/assets/icons/search.png')"></b-img>
              </template>
            </b-input-group>
          </div>
          <div class="account-cart" @click="$router.push({ name: 'cart' })">
            <span class="align-bottom"> EUR {{ displayCartValue }}</span>
            <b-img :src="require('@/assets/icons/warenkorb.png')"></b-img>
            <span class="cart-counter">{{ cartCount }}</span>
          </div>
        </div>
        <div class="srow">
          <div class="menu">
            <router-link v-for="mi in productMenuGroups"
                        :to="{ name: 'listing', params: { group: mi.id } }"
                        :key="mi.id">{{ mi.name }}</router-link>
            <span class="separator"></span>
            <router-link :to="{ name: 'kataloge' }"
                        class="kataloge">
              <b-img :src="require('@/assets/icons/kataloge.png')"></b-img>
              {{ $t('Catalogs') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'

import router from '@/router'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'AuthenticatedHeader',
  data () {
    return {
      upcSearchQuery: null
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      isAdmin: state => state.auth.isAdmin,
      user: state => state.auth.user,
      cartCount: state => state.products.cartCount ? state.products.cartCount : 0,
      cartValue: state => state.products.cartValue ? state.products.cartValue : 0.00,
      productMenuGroups: state => state.products.productMenuGroups,
      languages: state => state.cms.languages
    }),
    displayCartValue: function () {
      const cv = this.cartValue.toFixed(2)
      if (cv === '-0.00') {
        return '0.00'
      }
      return cv
    }
  },
  methods: {
    ...mapActions({
      setProductsFilter: 'products/setProductsFilter',
      fetchProductMenuGroups: 'products/fetchProductMenuGroups',
      resetProductsFilter: 'products/resetProductsFilter',
      fetchProducts: 'products/fetchProducts'
    }),
    handleSearch () {
      if (this.upcSearchQuery) {
        router.push({ name: 'listing', query: { s: this.upcSearchQuery } })
      }
    },
    handleSearchChange () {
      if (this.upcSearchQuery === '') {
        this.setProductsFilter({ upc: null })
        this.fetchProducts()
      }
    },
    setLanguage (language) {
      this.$i18n.locale = language
    }
  },
  created () {
    this.fetchProductMenuGroups()
    if (this.isLoggedIn) {
      Sentry.setUser({ id: this.user.id, username: this.user.name })
    }
  }
}
</script>

<style lang="scss">
.header--authenticated {
  display: flex;
  flex-direction: column;
  width: 100%;

  .user-panel {
    text-align: right;
    margin-bottom: 25px;

    a:nth-child(2) {
      margin-left: 30px;
    }
  }

  .navigation-panel {
    display: flex;

    .logo {
      width: 100px;
      height: 100px;
    }

    .menu-container {
      flex-grow: 1;
      text-align: right;
      text-transform: uppercase;
      padding-left: 100px;

      .frow {
        display: flex;
        flex-direction: row;

        .language_flags {
          flex-grow: 1;
          text-align: start;

          img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
            vertical-align: bottom;
          }
        }

        .search {
          flex-basis: 25%;

          input[type='search'] {
            border: 0;
            border-bottom: 2px solid var(--primary);
            font-size: 14px;

            &::-webkit-input-placeholder {
              font-size: 14px;
            }
          }

          img {
            cursor: pointer;
          }
        }

        .input-group-append {
          margin-left: 0;

          img {
            object-fit: none;
            border-bottom: 2px solid var(--primary);
          }
        }

        .account-cart {
          margin-left: 5%;
          position: relative;
          cursor: pointer;

          span:nth-child(1) {
            padding-right: 20px;
          }

          img {
            width: 25px;
            height: 28px;
          }

          .cart-counter {
            background-color: var(--secondary);
            color: white;
            font-size: 0.8em;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            position: absolute;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            top: -8px;
            right: 12px;
          }
        }
      }

      .menu {
        margin-top: 35px;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.8px;

        a {
          color: var(--primary);
          margin-right: 30px;
          text-decoration: none;
          padding-bottom: 5px;

          &.router-link-exact-active {
            color: var(--secondary);
            border-bottom: 2px solid var(--secondary);

            &.kataloge {
              img {
                content: url('~@/assets/icons/kataloge_secondary.png');
              }
            }
          }
        }

        .separator {
          padding-right: 30px;
          border-left: 2px solid var(--primary);
        }

        .kataloge {
          margin-right: 0;

          img {
            width: 15px;
            height: 20px;
            margin-top: -5px;
          }
        }
      }
    }
  }
}
</style>
