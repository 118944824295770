<template>
<div class="admin__ftpdata">
  <b-modal :id="this.modalID"
           :title="$t(this.modalTitle)"
           :ok-title="$t(this.modalOK)"
           :cancel-title="$t('Cancel')"
           @show="handleShow"
           @ok="handleOk">
    <div>
        {{$t('Are you sure you want to restore')}} {{ this.ftp.user.username }}?
    </div>
  </b-modal>
</div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'FTPRestoreModal',
  props: {
    ftp: [Object, Boolean]
  },
  computed: {
    ID () {
      if (!this.ftp) {
        return 'restore-'
      }
      return `${this.ftp.id}-`
    },
    modalID () {
      return `restore-ftp-${this.ftp.id}`
    },
    modalTitle () {
      return `${this.$t('Restore FTP')}: ${this.ftp.user.username}`
    },
    modalOK () {
      return this.$t('Restore')
    }
  },
  methods: {
    ...mapActions({
      restoreFTP: 'admin/restoreFTP',
      fetchFTPs: 'admin/fetchFTPs'
    }),
    handleOk (event) {
      event.preventDefault()
      this.restoreFTP(this.ftp.id).then(response => {
        this.handleResponse(response)
      })
    },
    handleResponse (response) {
      if (response.status === 'ok') {
        // TO-DO: Display success notification
        this.fetchFTPs()
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalID)
        })
      }
    },
    handleShow () {
      Object.assign(this.$data, this.$options.data.apply(this))
    }
  }
}
</script>

<style lang="scss">

</style>
