<template>
  <div class="favourites">
    <div class="favourites__header">
      <span>{{ $t('My Favourites') }}</span>
          <v-select :components="{OpenIndicator}" :options="time_dropdown" :clearable="false" :value="selected"></v-select>

    </div>
    <ProductRow :index_box="index + 1" :key="product.id" v-for="(product, index) in this.favourites" :product="product" />

    <div class="d-flex justify-content-center">
      <b-button class="show_more" variant="white">{{ $t('Show More') }} <b-img :src="require('@/assets/icons/arrow.png')"></b-img></b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import ProductRow from '@/components/products/ProductRow'

export default {
  name: 'Favourites',
  components: {
    ProductRow
  },
  data: function () {
    return {
      OpenIndicator: {
        render: createElement => createElement('img', { attrs: { src: require('@/assets/icons/arrow.png') } })
      },
      time_dropdown: [
        this.$t('THE LAST 6 MONTHS')
      ],
      selected: this.$t('THE LAST 6 MONTHS')
    }
  },
  computed: {
    ...mapState({
      favourites: state => state.products.favourites
    })
  },
  methods: {
    ...mapActions({
      fetchFavourites: 'products/fetchFavourites'
    })
  },
  created: function () {
    this.fetchFavourites().then(res => {
      console.log('fetchFavourites', this.favourites)
    })
  }
}
</script>

<style lang="scss">
.favourites {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    border-top: 1px solid var(--primary);
    border-bottom: 1px solid var(--primary);
    padding-top: 45px;
    padding-bottom: 45px;
    text-transform: uppercase;

    $vs-border-radius: 0;
    $vs-border-color: transparent;
    $vs-selected-bg: white;
    $vs-component-placeholder-color: var(--primary);
    $vs-state-active-bg: white;
    $vs-state-active-color: var(--primary);
    $vs-controls-color: var(--primary);
    $vs-component-line-height: 1.65;
    @import "vue-select/src/scss/vue-select.scss";

    @include style-select(true);
  }

  .product_row {
    border-bottom: 1px solid var(--primary);
    padding-top: 25px;
  }

  button.show_more {
    text-transform: uppercase;
    margin-bottom: 100px;
    margin-top: 50px;

    img {
      transform: rotate(90deg);
    }
  }

}
</style>
