<template>
  <div id="eanorder_floater" v-if="isLoggedIn">
   <b-button to="/ean"><b-img :src="require('@/assets/icons/barcode_white.png')"></b-img> <span>{{ $t('EAN/Barcode order')}}</span> <b-img :src="require('@/assets/icons/arrow_white.png')"></b-img></b-button>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'EANOrderFloater',
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    })
  }
}
</script>

<style lang="scss">
  #eanorder_floater {
    position: fixed;
    z-index: 1000;
    right: 0;
    bottom: 0;

    a {
      span {
        vertical-align: middle;
        text-transform: uppercase;
      }
    }
  }
</style>
