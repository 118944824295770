<template>
<b-container class="admin_panel">
  <b-row>
    <b-col>
      <b-nav vertical class="w-25">
        <b-nav-item active :to="{ name: 'admin' }">{{ $t('Overview') }}</b-nav-item>
        <b-nav-item :to="{ name: 'admin/users' }">{{ $t('Users') }}</b-nav-item>
        <b-nav-item :to="{ name: 'admin/ftp' }">{{ $t('FTP') }}</b-nav-item>
        <b-nav-item :to="{ name: 'admin/formats' }">{{ $t('Formats') }}</b-nav-item>
        <b-nav-item :to="{ name: 'admin/labels' }">{{ $t('Labels') }}</b-nav-item>
        <b-nav-item :to="{ name: 'admin/product_groups' }">{{ $t('Groups') }}</b-nav-item>
        <b-nav-item :to="{ name: 'admin/blacklists' }">{{ $t('Blacklist') }}</b-nav-item>
        <b-nav-item :to="{ name: 'admin/turnover',  params: { page: 'turnover' } }">{{ $t('Turnover') }}</b-nav-item>
        <b-nav-item-dropdown text="CMS"
                             offset="15"
                             :class="{ show: this.$route.name.startsWith('admin/cms') }">
          <b-dropdown-item :to="{ name: 'admin/cms/frontpage' }">{{ $t('Frontpage') }}</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin/cms/agb' }">{{ $t('AGB') }} [s]</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin/cms/datenschutz' }">{{ $t('Datenschutz') }} [s]</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin/cms/impressum' }">{{ $t('Impressum') }} [s]</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin/cms/team' }">{{ $t('Unser Team') }} [s]</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin/cms/kataloge' }">{{ $t('Kataloge') }}</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin/cms/footer' }">{{ $t('Footer') }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav>
    </b-col>
    <b-col cols="10">
      <router-view></router-view>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
export default {
  name: 'AdminPanel'
}
</script>

<style lang="scss">
.admin__cms__header {
  border-bottom: 2px solid black;
}

.admin_panel {
  .router-link-exact-active {
    background-color: var(--light);
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

</style>
