<template>
<div class="admin__productgroupdata">
  <b-modal :id="this.modalID"
           :title="$t(this.modalTitle)"
           :ok-title="$t(this.modalOK)"
           :cancel-title="$t('Cancel')"
           :busy="form.busy"
           @show="handleShow"
           @ok="handleOk">
      <div role="group">
        <label :for="`${this.ID}-input-name`" class="mb-0">{{ $t('Name') }}</label>
        <b-form-input
          :id="`${this.ID}-input-name`"
          v-model="form.name.data"
          :state="form.name.state"
          :required="true"
          class="mb-2"
          trim
        ></b-form-input>
        <b-form-invalid-feedback :id="`${this.ID}-input-name-feedback`">
          {{ form.name.error }}
        </b-form-invalid-feedback>

        <label :for="`${this.ID}-input-order`" class="mb-0">{{ $t('Order in Menu') }}</label>
        <b-form-input
          :id="`${this.ID}-input-order`"
          v-model="form.order.data"
          :state="form.order.state"
          :required="true"
          type='number'
          class="mb-2"
          trim
        ></b-form-input>
        <b-form-invalid-feedback :id="`${this.ID}-input-order-feedback`">
          {{ form.order.error }}
        </b-form-invalid-feedback>

        <b-form-checkbox
          :id="`${this.ID}-checkbox-show_in_menu`"
          v-model="form.show_in_menu.data"
          name="checkbox-show_in_menu"
          :value="true"
          :unchecked-value="false"
        >
          {{ $t('Show In Menu') }}
        </b-form-checkbox>

        <label :for="`${this.ID}-input-priority_list`" class="mb-0">{{ $t('Priority List') }}</label>
        <b-form-textarea
          :id="`${this.ID}-input-priority_list`"
          v-model="form.priority_list.data"
          :state="form.priority_list.state"
          :required="true"
          class="mb-2"
          rows="10"
          trim
        ></b-form-textarea>
        <b-form-invalid-feedback :id="`${this.ID}-input-priority_list-feedback`">
          {{ form.priority_list.error }}
        </b-form-invalid-feedback>
    </div>
  </b-modal>
</div>
</template>

<script>

import { mapActions } from 'vuex'

function getFormData (productGroup) {
  return {
    form: {
      name: {
        data: productGroup ? productGroup.name : '',
        state: null,
        error: null
      },
      order: {
        data: productGroup ? productGroup.order : 99,
        state: null,
        error: null
      },
      show_in_menu: {
        data: productGroup ? Boolean(productGroup.show_in_menu) : false,
        state: null,
        error: null
      },
      priority_list: {
        data: productGroup ? productGroup.priority_list : '',
        state: null,
        error: null
      },
      busy: false
    }
  }
}
export default {
  name: 'ProductGroupDataModal',
  props: {
    product_group: [Object, Boolean]
  },
  data () {
    return getFormData(this.product_group)
  },
  computed: {
    ID () {
      if (!this.product_group) {
        return 'create-'
      }
      return `${this.product_group.id}-`
    },
    modalID () {
      if (!this.product_group) {
        return 'modal-add-product_group'
      }
      return `edit-product_group-${this.product_group.id}`
    },
    modalTitle () {
      if (!this.product_group) {
        return this.$t('Add Product Group')
      }
      return this.$t('Edit Product Group')
    },
    modalOK () {
      if (!this.product_group) {
        return this.$t('Add')
      }
      return this.$t('Save')
    }
  },
  methods: {
    ...mapActions({
      createProductGroup: 'admin/createProductGroup',
      editProductGroup: 'admin/editProductGroup',
      fetchProductGroups: 'admin/fetchProductGroups'
    }),
    handleOk (event) {
      event.preventDefault()
      this.form.busy = true
      this.handleSubmit()
    },
    handleSubmit () {
      const productGroupData = {
        name: this.form.name.data,
        order: this.form.order.data,
        show_in_menu: this.form.show_in_menu.data,
        priority_list: this.form.priority_list.data
      }

      if (this.product_group) {
        productGroupData.id = this.product_group.id
        this.editProductGroup(productGroupData).then(response => {
          this.handleResponse(response)
        })
      } else {
        this.createProductGroup(productGroupData).then(response => {
          this.handleResponse(response)
        })
      }
    },
    handleResponse (response) {
      if (response.status === 'ok') {
        // TO-DO: Display success notification
        this.fetchProductGroups()
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalID)
        })
      } else {
        Object.keys(response.error.data.errors).forEach(key => {
          this.form[key].state = false
          this.form[key].error = response.error.data.errors[key][0]
        })
      }
      this.form.busy = false
    },
    handleShow () {
      Object.assign(this.$data, this.$options.data.apply(this))
    }
  }
}
</script>

<style lang="scss">

</style>
