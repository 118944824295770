<template>
<b-container class="cart">
  <div class="cart__header">
    <h2>{{$t('CART')}}</h2>
    <div class="controls">
      <UserDropdown v-if="isAdmin" v-model="customer" />
      <b-form-input class="ml-4" v-model="po_number" placeholder="PO#"></b-form-input>
    </div>
  </div>
  <div class="cart__table">
    <div class="header">
      <div class="header__image">{{ $t('Image') }}</div>
      <div class="header__category">{{ $t('Category') }}</div>
      <div class="header__artist">{{ $t('Artist') }}, {{ $t('Title') }}</div>
      <div class="header__pricelist">
        <div class="header__sku">{{ $t('SKU') }}</div>
        <div class="header__price">{{ $t('Price') }}</div>
        <div class="header__quantity">{{ $t('Quantity') }}</div>
        <div class="header__summary">{{ $t('Summary') }}</div>
      </div>
    </div>
    <div v-for="line in this.cart"
         :key="line.product ? line.product.upc : line.unknown_ident">
      <CartProductRow v-if="line.product"
                      :product="line.product"
                      :lines="line.lines" />
      <div v-else class="cart_product_row cart_product_row--unknown">
        <span>UNBEKANNT {{ line.unknown_ident }}</span>
        <b-img @click="handleRemoveUnknown(line.unknown_ident)" :src="require('@/assets/icons/x.png')"></b-img>
      </div>
    </div>
  </div>
  <div class="cart__footer">
    <div class="actions">
      <b-button variant="outline-primary" @click="handleClearCart()">{{ $t('Clear') }}</b-button>
    </div>
    <div class="stock_keys">

    </div>
    <div class="checkout">
      <div class="summary">
        {{ Object.keys(cart).length }} x {{ $t('Article')}} <h2>€ {{ displayCartValue }}</h2>
      </div>
      <b-button :disabled="orderInProgress" @click="handleOrderOK()"> <b-spinner v-if="orderInProgress"></b-spinner> <span v-else>{{ $t('Order') }}</span></b-button>
    </div>
  </div>
</b-container>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import router from '@/router'

import CartProductRow from '@/components/products/CartProductRow'
import UserDropdown from '@/components/admin/UserDropdown'

export default {
  name: 'Cart',
  components: {
    CartProductRow,
    UserDropdown
  },
  data () {
    return {
      productsPerPage: 20,
      po_number: '',
      customer: null,
      orderInProgress: false
    }
  },
  computed: {
    ...mapState({
      isAdmin: state => state.auth.isAdmin,
      cart: state => state.products.cart,
      cartValue: state => state.products.cartValue ? state.products.cartValue : 0.00
    }),
    displayCartValue: function () {
      const cv = this.cartValue.toFixed(2)
      if (cv === '-0.00') {
        return '0.00'
      }
      return cv
    }
  },
  methods: {
    ...mapActions({
      modifyCartAmount: 'products/modifyCartAmount',
      clearCart: 'products/clearCart',
      placeOrderFromCart: 'products/placeOrderFromCart',
      removeUnknownFromCart: 'products/removeUnknownFromCart'
    }),
    handleOrderOK () {
      this.orderInProgress = true
      this.placeOrderFromCart({ buyer_id: this.customer, po: this.po_number })
        .then(response => {
          this.orderInProgress = false
          if (response.status === 'ok') {
            this.clearCart()
            this.$root.$bvToast.toast(`Order #${response.response.data.id} successfully created`, {
              title: 'Success',
              autoHideDelay: 5000,
              appendToast: true,
              variant: 'success'
            })
            router.push('/listing')
          } else {
            if (response.error.status === 409) {
              this.clearCart()
              this.$root.$bvToast.toast(response.error.data.message, {
                title: 'Warning',
                autoHideDelay: 5000,
                appendToast: true,
                variant: 'warning'
              })
              router.push('/listing')
            } else {
              console.log('error with creating order', response)
              this.$root.$bvToast.toast('Error Creating Order', {
                title: 'Error',
                autoHideDelay: 5000,
                appendToast: true,
                variant: 'danger'
              })
            }
          }
        })
    },
    handleClearCart () {
      this.clearCart()
    },
    handleRemoveUnknown (ident) {
      this.removeUnknownFromCart(ident)
    }
  }
}
</script>

<style lang="scss">
.cart {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3.125rem;

    .controls {
      display: flex;
      flex-direction: row;
    }
  }

  &__table {
    .header {
      display: flex;
      flex-direction: row;
      font-weight: 900;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      border-top: 1px solid var(--primary);
      border-bottom: 1px solid var(--primary);

      @include cart-product-row();
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1.5rem;

    .checkout {
      display: flex;
      flex-direction: column;
      flex-basis: 22%;

      .summary {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:center;
      }
    }

  }
  .cart_product_row--unknown {
    background-color: var(--danger);
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    justify-content: space-between;
    align-items: center;

    img {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
}
</style>
