<template>
  <b-container class="product_slider">
    <div class="small_separator"></div>
    <div class="product_slider__header">
      <h2>{{ $t('Brand New') }}</h2>
      <router-link to='listing'>{{ $t('Show All') }} <b-img :src="require('@/assets/icons/arrow.png')"></b-img> </router-link>
    </div>
    <VueSlickCarousel v-if="slideLoaded" v-bind="settings">

      <ProductCard v-bind:key="product.upc" v-for="product in this.products" :product="product" />

      <template #prevArrow>
        <div class="custom-arrow">
            <b-img style="transform: rotate(180deg);" :src="require('@/assets/icons/arrow_secondary.png')"></b-img>
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-arrow">
          <b-img :src="require('@/assets/icons/arrow_secondary.png')"></b-img>
        </div>
    </template>
    </VueSlickCarousel>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import ProductCard from '@/components/products/ProductCard'

import VueSlickCarousel from 'vue-slick-carousel'

// import style
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'ProductSlider',
  components: {
    VueSlickCarousel,
    ProductCard
  },
  data () {
    return {
      settings: {
        dots: true,
        infinite: true,
        arrows: true,
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        variableWidth: false,
        responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3

          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        ]
      },
      slideLoaded: false
    }
  },
  computed: {
    ...mapState({
      products: state => state.products.latestProducts
    })
  },
  methods: {
    ...mapActions({
      fetchLatestProducts: 'products/fetchLatestProducts'
    })
  },
  created: function () {
    if (this.products.length === 0) {
      this.fetchLatestProducts().then(response => {
        this.slideLoaded = true
      })
    } else {
      this.slideLoaded = true
    }
  }
}
</script>

<style lang="scss">
.product_slider {
  margin-bottom: 140px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
    text-transform: uppercase;
  }

  .slick-slider {
    position: relative;

    .slick-prev {
      position: absolute;
      top: 150px;
      left: -50px;
      z-index: 100;
    }

    .slick-next {
      position: absolute;
      top: 150px;
      right: -50px;
      z-index: 100;
    }

    .slick-dots {
      list-style: none;
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin-top: 50px;

      li {
        height: 6px;
        width: 6px;
        background-color: var(--primary);
        border-radius: 50%;
        margin-right: 35px;

        &.slick-active {
          height: 10px;
          width: 10px;
          background-color: var(--secondary)
        }

        button {
          display: none;
        }
      }
    }
  }
}
</style>
