import {
  SET_PRODUCTS,
  SET_HIGHLIGHTED_PRODUCTS,
  SET_LATEST_PRODUCTS,
  SET_ORDERHISTORY,
  SET_FAVOURITES,
  SET_PRODUCTS_FILTER,
  SET_PRODUCTS_CURRENT_PAGE,
  SET_PRODUCTS_META,
  SET_PRODUCT_MENU_GROUPS,
  ADD_TO_CART,
  MODIFY_CART_AMOUNT,
  SET_LABELS,
  SET_REPORTS,
  MODIFY_CART_PRICE,
  CLEAR_CART,
  REMOVE_UNKNOWN_FROM_CART
} from '@/store/mutation-types'

import client from 'api-client'

import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    products: [],
    productsFilter: {
      limit: 20,
      offset: 0,
      upc: null,
      artist: null,
      title: null,
      label: null,
      priceStart: null,
      priceEnd: null,
      format: null,
      onStock: null,
      group: null
    },
    productsDataState: {
      currentPage: 1,
      productsAvailableCount: 0,
      minPrice: 0,
      maxPrice: 0,
      fetched: null
    },
    labels: [],
    productMenuGroups: [],
    highlightedProducts: [],
    latestProducts: [],
    orderHistory: [],
    orderHistoryDataState: {
      currentPage: 1,
      ordersAvailableCount: 0
    },
    favourites: [],
    reports: [],
    cart: {},
    cartCount: 0,
    cartValue: 0.00
  },
  mutations: {
    setProducts (state, data) {
      state.products = data.products

      state.productsDataState.fetched = Date.now()
    },
    setProductsMeta (state, data) {
      state.productsDataState.productsAvailableCount = data.productsAvailableCount
      state.productsDataState.minPrice = data.productsMinPrice
      state.productsDataState.maxPrice = data.productsMaxPrice
    },
    setProductsFilter (state, data) {
      let resetOffset = true
      Object.entries(data).forEach(([key, value]) => {
        state.productsFilter[key] = value
        if (key === 'offset') {
          resetOffset = false
        }
      })
      if (resetOffset) {
        state.productsFilter.offset = 0
        state.productsDataState.currentPage = 1
      }
    },
    setProductsCurrentPage (state, data) {
      state.productsDataState.currentPage = data
    },
    setLatestProducts (state, products) {
      state.latestProducts = products
    },
    setHighlightedProducts (state, products) {
      state.highlightedProducts = products
    },
    setOrderHistory (state, oh) {
      state.orderHistory = oh
    },
    setFavourites (state, favs) {
      state.favourites = favs
    },
    setProductMenuGroups (state, groups) {
      state.productMenuGroups = groups
    },
    addToCart (state, productLine) {
      console.log('addToCart', productLine)
      if (productLine.product) {
        // We have a normal product line
        const key = productLine.product.upc
        if (key in state.cart) {
          if (productLine.index in state.cart[key].lines) {
            state.cart[key].lines[productLine.index] += productLine.amount
          } else {
            Vue.set(state.cart[key].lines, productLine.index, productLine.amount)
          }

          // TO-DO: Find a better way, maybe it's own key?
          // First need to figure out how they wish this to behave in edge cases (adding etc)
          if ('price' in productLine) {
            state.cart[key].product.pricelist[productLine.index].price = productLine.price
          }
        } else {
          if ('price' in productLine) {
            productLine.product.pricelist[productLine.index].price = productLine.price
          }

          const newLines = { [productLine.index]: productLine.amount }
          Object.keys(productLine.product.pricelist).forEach(st => {
            if (!(st in newLines)) {
              newLines[st] = 0
            }
          })

          Vue.set(state.cart, key, { product: productLine.product, lines: newLines })
        }
        state.cartCount += productLine.amount
        state.cartValue += productLine.amount * productLine.product.pricelist[productLine.index].price
      } else {
        // We have an unknown product line, just add it like that
        Vue.set(state.cart, productLine.ident, { unknown_ident: productLine.ident, amount: productLine.amount })
      }
    },
    modifyCartAmount (state, modifyLineInfo) {
      // TO-DO: Look for a better way to keep state in cartCount/cartValue, maybe with a vuex-store getter?
      state.cart[modifyLineInfo.upc].lines[modifyLineInfo.stockType] += modifyLineInfo.amount

      state.cartCount += modifyLineInfo.amount
      state.cartValue += modifyLineInfo.amount * state.cart[modifyLineInfo.upc].product.pricelist[modifyLineInfo.stockType].price

      if (state.cart[modifyLineInfo.upc].lines[modifyLineInfo.stockType] === 0) {
        // Check if we modified amount to 0 and other stockTypes are 0 too - then remove this item from the cart
        let removeFromCart = true
        Object.keys(state.cart[modifyLineInfo.upc].lines).forEach((key) => {
          if (state.cart[modifyLineInfo.upc].lines[key] > 0) {
            removeFromCart = false
          }
        })

        if (removeFromCart) {
          // TO-DO: Some notification or confirmation popup here.
          Vue.delete(state.cart, modifyLineInfo.upc)
        }
      }
    },
    [CLEAR_CART] (state) {
      state.cart = {}
      state.cartCount = 0
      state.cartValue = 0.00
    },
    [MODIFY_CART_PRICE] (state, modifyLineInfo) {
      // TO-DO: Look for a better way to keep state in cartCount/cartValue, maybe with a vuex-store getter?
      // TO-DO: ALSO LOOK FOR A UNIFIED WAY OF KEEPING THIS PRICE AND AMOUNT CART LINE STUFF
      console.log('ModifyPrice', modifyLineInfo)
      // Substract old price
      state.cartValue -= state.cart[modifyLineInfo.upc].lines[modifyLineInfo.stockType] * state.cart[modifyLineInfo.upc].product.pricelist[modifyLineInfo.stockType].price

      // Modify the price
      state.cart[modifyLineInfo.upc].product.pricelist[modifyLineInfo.stockType].price = modifyLineInfo.price

      // Update value again
      state.cartValue += state.cart[modifyLineInfo.upc].lines[modifyLineInfo.stockType] * state.cart[modifyLineInfo.upc].product.pricelist[modifyLineInfo.stockType].price
    },
    [SET_LABELS] (state, labels) {
      state.labels = labels
    },
    [SET_REPORTS] (state, reports) {
      state.reports = reports
    },
    [REMOVE_UNKNOWN_FROM_CART] (state, ident) {
      Vue.delete(state.cart, ident)
    }
  },
  actions: {
    fetchProducts ({ commit, state }) {
      const productsApiRequest = client
        .fetchProducts(state.productsFilter)
        .then(response => {
          if (response.status === 'ok') {
            commit(SET_PRODUCTS, response)
          } else {
            // TO-DO: Handle error. Display some error status?
            console.log('fetchProducts ERROR:', response)
          }
        })
      client.fetchProductsMeta(state.productsFilter).then(response => {
        if (response.status === 'ok') {
          commit(SET_PRODUCTS_META, response)
        } else {
          // TO-DO: Handle error. Display some error status?
          console.log('fetchProductsMeta ERROR:', response)
        }
      })
      return productsApiRequest
    },
    setProductsFilter ({ commit, dispatch }, data) {
      // TO-DO: Prefetch some more data? so subsequent requests will be faster/smooother.
      commit(SET_PRODUCTS_FILTER, data)
    },
    resetProductsFilter ({ commit, dispatch }) {
      // TO-DO: Use a single declaration of this data in here and top
      const data = {
        limit: 20,
        offset: 0,
        upc: null,
        artist: null,
        title: null,
        label: null,
        priceStart: null,
        priceEnd: null,
        format: null,
        onStock: null
      }
      commit(SET_PRODUCTS_FILTER, data)
    },
    setProductsCurrentPage ({ commit, dispatch }, data) {
      commit(SET_PRODUCTS_CURRENT_PAGE, data.currentPage)
      dispatch('setProductsFilter', { offset: (data.currentPage - 1) * data.productsPerPage })
      dispatch('fetchProducts')
    },
    fetchLatestProducts ({ commit }) {
      return client
        .fetchLatestProducts()
        .then(products => commit(SET_LATEST_PRODUCTS, products))
    },
    fetchHighlightedProducts ({ commit }) {
      return client
        .fetchHighlightedProducts()
        .then(products => commit(SET_HIGHLIGHTED_PRODUCTS, products))
    },
    fetchOrderHistory ({ commit }) {
      return client
        .fetchOrderHistory()
        .then(oh => commit(SET_ORDERHISTORY, oh.orders))
    },
    fetchFavourites ({ commit }) {
      return client
        .fetchFavourites()
        .then(favs => commit(SET_FAVOURITES, favs))
    },
    fetchProductMenuGroups ({ commit }) {
      return client
        .fetchProductMenuGroups()
        .then(groups => commit(SET_PRODUCT_MENU_GROUPS, groups))
    },
    fetchQuickAdd ({ commit }, payload) {
      console.log('fetchQuickAdd in products store', payload)
      return client
        .fetchQuickAdd(payload)
    },
    fetchLabels ({ commit }) {
      return client
        .fetchLabels()
        .then(response => {
          commit(SET_LABELS, response.response)
        })
    },
    fetchReports ({ commit }) {
      return client
        .fetchReports()
        .then(response => {
          commit(SET_REPORTS, response.response)
        })
    },
    addToCart ({ commit }, productLine) {
      commit(ADD_TO_CART, productLine)
    },
    modifyCartAmount ({ commit }, modifyLineInfo) {
      commit(MODIFY_CART_AMOUNT, modifyLineInfo)
    },
    modifyCartPrice ({ commit }, modifyLineInfo) {
      commit(MODIFY_CART_PRICE, modifyLineInfo)
    },
    removeUnknownFromCart ({ commit }, ident) {
      commit(REMOVE_UNKNOWN_FROM_CART, ident)
    },
    placeOrderFromCart ({ state }, additionalInfo) {
      const order = { lines: [] }
      if ('po' in additionalInfo) {
        order.po = additionalInfo.po
      }
      if ('buyer_id' in additionalInfo) {
        order.buyer_id = additionalInfo.buyer_id
      }
      Object.keys(state.cart).map((upc, index) => {
        if (state.cart[upc].product) {
          Object.keys(state.cart[upc].lines).map((lineKey, index) => {
            // Make sure that the ordered amount is not more than the stock we have
            let tmpAmount = state.cart[upc].lines[lineKey]
            if (tmpAmount > state.cart[upc].product.pricelist[lineKey].num) {
              tmpAmount = state.cart[upc].product.pricelist[lineKey].num
            }
            // TO-DO: This is surely not a good idea, should query and get all the other data from DB on backend side
            order.lines.push({
              artist: state.cart[upc].product.artist,
              title: state.cart[upc].product.title,
              image: state.cart[upc].product.image, // TO-DO: This could be stale data and removed sooner or later
              format: state.cart[upc].product.format,
              units: state.cart[upc].product.units,
              upc: upc,
              sku: state.cart[upc].product.pricelist[lineKey].sku,
              amount: tmpAmount,
              price: state.cart[upc].product.pricelist[lineKey].price,
              list_price: state.cart[upc].product.pricelist[lineKey].list_price,
              stock_type: lineKey
            })
          })
        }
      })
      return client
        .placeOrder(order)
    },
    clearCart ({ commit }) {
      commit(CLEAR_CART)
    },
    blacklistProduct ({ commit, dispatch }, payload) {
      return client
        .blacklistProduct(payload).then(response => {
          dispatch('fetchProducts')
        })
    },
    editProduct ({ dispatch }, payload) {
      return client
        .editProduct(payload)
        .then(response => {
          // TO-DO: Notifications for success/failure
          if (response.status === 'ok') {
            // TO-DO: smarter way to replace the affected elements only
            dispatch('fetchProducts')
          }
        })
    },
    getProductsExcel ({ state }) {
      return client.getProductsExcel(state.productsFilter)
    }
  }
}
