<template>
  <div id="app">
    <b-toast :visible="updateExists"
             id="update-notification"
             :title="$t('Update Available')"
             variant="info"
             :solid="true"
             no-auto-hide
             no-close-button
            >
      <b-button class="mb-2 w-100" variant="success" @click="refreshApp">
      {{ $t('Click to update') }}
      </b-button>
    </b-toast>
    <HeaderHolder />
    <router-view></router-view>
    <FooterHolder v-if="$route.meta.footer.show" />
  </div>
</template>

<script>

import HeaderHolder from '../components/HeaderHolder'
import FooterHolder from '../components/FooterHolder'
import update from '../mixins/update'

export default {
  name: 'app',
  components: {
    HeaderHolder,
    FooterHolder
  },
  mixins: [update],
  created () {
    document.title = 'I-DI GmbH B2B Store'
  }
}
</script>

<style>

</style>
