<template>
<div>
<EANOrderFloater />
<b-container class="listing">
  <b-row class="filter">
    <b-form-input v-on:input="handleArtistInput" v-model="filter.artist" :placeholder="$t('Author')"></b-form-input>
    <b-form-input v-on:input="handleTitleInput" v-model="filter.title" :placeholder="$t('Title')"></b-form-input>
    <b-form-input v-on:input="handleLabelInput" v-model="filter.label" :placeholder="$t('Label')"></b-form-input>
    <PriceFilterInput :min="productsDataState.minPrice"
                      :max="productsDataState.maxPrice"
                      :filter_start.sync="syncPriceStart"
                      :filter_end.sync="syncPriceEnd" />
    <v-select :components="{OpenIndicator}"
              :options="options.filter.format"
              :model="filter.format"
              @input="handleInputFormat"
              :placeholder="$t('Format')"></v-select>
    <v-select :components="{OpenIndicator}"
              :options="options.filter.avability"
              :model="filter.avability"
              @input="handleInputAvability"
              :placeholder="$t('Avability')"></v-select>
    <div class="filter__reset" @click="resetFilters(true)">
      <b-img :src="require('@/assets/icons/reset.png')"></b-img>
      <span class="align-middle">{{ $t('Reset') }}</span>
    </div>
  </b-row>
  <b-row class="toolbar">
    <span v-if="this.productsDataState.fetched" class="toolbar__number_results">{{ this.productsDataState.productsAvailableCount }} {{ $t('Results') }}</span>
    <div class="toolbar__download">
      <a v-if="this.productsDataState.fetched && this.productsDataState.productsAvailableCount < 100000" @click="productDownloadNotification()" role="button">
        <b-img class="toolbar__download--excel" :src="require('@/assets/icons/excel.png')"></b-img> {{ $t('Excel Tabelle herunterladen') }} <b-img :src="require('@/assets/icons/download.png')"></b-img>
      </a>
      <span v-else>
        <b-img class="toolbar__download--excel" :src="require('@/assets/icons/excel.png')"></b-img> {{ $t('There are too many results to download') }}</span>
    </div>
    <div class="toolbar__sort">
        <!-- <v-select :components="{OpenIndicator}" :options="options.filter.price" :placeholder="$t('Sort by')"></v-select> -->
        <b-img @click="switchToGrid" :src="gridViewIcon" class="toolbar__sort__gridview"></b-img>
        <b-img @click="switchToList" :src="listViewIcon" class="toolbar__sort__listview"></b-img>
    </div>
  </b-row>
  <div v-if="isList">
    <ProductRow :key="product.upc" v-for="product in this.products" :product="product" />
  </div>
  <b-row v-else class="grid row-cols-2 row-cols-md-3 row-cols-lg-4">
    <ProductCard v-bind:key="product.upc" v-for="product in this.products" :product="product" />
  </b-row>

  <b-row class="pagination-container col-12 justify-content-center">
    <b-pagination
      v-model="currentPage"
      :total-rows="productsDataState.productsAvailableCount"
      :per-page="productsPerPage"
      :hide-ellipsis="true"
      :limit="7"
    >
      <template v-slot:first-text><span class="start">{{ $t('Start') }}</span></template>
      <template v-slot:prev-text><b-img class="arrow arrow-prev" :src="require('@/assets/icons/arrow.png')"></b-img></template>
      <template v-slot:next-text><b-img class="arrow arrow-right" :src="require('@/assets/icons/arrow.png')"></b-img></template>
      <template v-slot:last-text><span class="end">{{ $t('End') }}</span></template>
    </b-pagination>
  </b-row>
</b-container>
</div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import _ from 'lodash'
import axios from 'axios'

import ProductCard from '@/components/products/ProductCard'
import ProductRow from '@/components/products/ProductRow'
import EANOrderFloater from '@/components/EANOrderFloater'
import PriceFilterInput from '@/components/products/PriceFilterInput'

export default {
  name: 'Listing',
  components: {
    ProductCard,
    ProductRow,
    PriceFilterInput,
    EANOrderFloater
  },
  data () {
    return {
      OpenIndicator: {
        render: createElement => createElement('img', { attrs: { src: require('@/assets/icons/arrow.png') } })
      },
      options: {
        filter: {
          price: ['1', '2'],
          avability: [
            { label: 'On Hand', code: 'on_hand' },
            { label: 'Limited', code: 'limited' },
            { label: 'Permanent', code: 'permanent' }
          ],
          format: ['DVD', 'CD']
        }
      },
      filter: {
        artist: '',
        title: '',
        label: '',
        price: '',
        format: '',
        avability: '',
        priceStart: null,
        priceEnd: null
      },
      productsPerPage: 20,
      isList: true
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      products: state => state.products.products,
      productsDataState: state => state.products.productsDataState,
      productsFilter: state => state.products.productsFilter
    }),
    listViewIcon () {
      if (this.isList) {
        return require('@/assets/icons/list_view_secondary.png')
      }
      return require('@/assets/icons/list_view.png')
    },
    gridViewIcon () {
      if (this.isList) {
        return require('@/assets/icons/grid_view.png')
      }
      return require('@/assets/icons/grid_view_secondary.png')
    },
    currentPage: {
      get () {
        return this.productsDataState.currentPage
      },
      set (value) {
        this.setProductsCurrentPage({ currentPage: value, productsPerPage: this.productsPerPage })
      }
    },
    downloadExcelURL () {
      // TO-DO: Check this export stuff, maybe it's better if we use the general API flow, but memory and data corruption could be an issue
      const params = Object.keys(this.productsFilter).filter(key => this.productsFilter[key] != null).map(key => `${key}=${encodeURIComponent(this.productsFilter[key])}`).join('&')
      return process.env.VUE_APP_API_URL + '/api/products/export?' + params
    },
    syncPriceStart: {
      get () {
        if (this.filter.priceStart === null) {
          return this.productsFilter.priceStart
        }
        return this.filter.priceStart
      },
      set (value) {
        this.filter.priceStart = value
        this.handleDragPriceStart()
      }
    },
    syncPriceEnd: {
      get () {
        if (this.filter.priceEnd === null) {
          return this.productsFilter.priceEnd
        }
        return this.filter.priceEnd
      },
      set (value) {
        this.filter.priceEnd = value
        this.handleDragPriceEnd()
      }
    }
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
      setProductsFilter: 'products/setProductsFilter',
      setProductsCurrentPage: 'products/setProductsCurrentPage',
      resetProductsFilter: 'products/resetProductsFilter',
      getProductsExcel: 'products/getProductsExcel'
    }),
    fetchData () {
      console.log('fetchData', this.$route.params.group)
      if (this.$route.query.s) {
        this.setProductsFilter({ upc: this.$route.query.s, group: null })
      } else {
        this.setProductsFilter({ upc: null })
      }
      // Preliminary feedback was that we do not reset the filters when switching categories
      // this.resetFilters(false)
      if (this.$route.params.group) {
        this.setProductsFilter({ group: this.$route.params.group })
      } else {
        this.setProductsFilter({ group: null })
      }

      this.fetchProducts().then(response => {
        console.log('Got Products', this.products)
      })
    },
    switchToList () {
      this.isList = true
    },
    switchToGrid () {
      this.isList = false
    },
    handleArtistInput: _.debounce(function (e) {
      this.setProductsFilter({ artist: this.filter.artist })
      this.fetchProducts()
    }, process.env.VUE_APP_INPUT_DEBOUNCE),
    handleTitleInput: _.debounce(function (e) {
      this.setProductsFilter({ title: this.filter.title })
      this.fetchProducts()
    }, process.env.VUE_APP_INPUT_DEBOUNCE),
    handleLabelInput: _.debounce(function (e) {
      this.setProductsFilter({ label: this.filter.label })
      this.fetchProducts()
    }, process.env.VUE_APP_INPUT_DEBOUNCE),
    handleDragPriceEnd: _.debounce(function () {
      this.setProductsFilter({ priceEnd: this.filter.priceEnd })
      this.fetchProducts()
    }, process.env.VUE_APP_INPUT_DEBOUNCE),
    handleDragPriceStart: _.debounce(function () {
      this.setProductsFilter({ priceStart: this.filter.priceStart })
      this.fetchProducts()
    }, process.env.VUE_APP_INPUT_DEBOUNCE),
    handleInputFormat: function (e) {
      this.setProductsFilter({ format: this.filter.format })
      this.fetchProducts()
    },
    handleInputAvability (value) {
      if (value) {
        this.setProductsFilter({ avability: value.code })
      } else {
        this.setProductsFilter({ avability: '' })
      }
      this.fetchProducts()
    },
    resetFilters (fetch) {
      // TO-DO: Better reset functionality. We can probably watch for this changes?
      // Also make a function that returns this data so that we can reuse it here and at declaration?
      this.filter = {
        artist: '',
        title: '',
        label: '',
        price: '',
        format: '',
        avability: '',
        priceStart: null,
        priceEnd: null
      }
      this.resetProductsFilter()
      if (fetch) {
        this.fetchProducts()
      }
    },
    productDownloadNotification () {
      // TO-DO: Proper flow for this, currently just hacky way last minute
      axios.get(this.downloadExcelURL)
      this.$bvToast.toast(this.$t('The Excel Download is being generated and will be emailed to you shortly.'), {
        title: this.$t('Success'),
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'success'
      })
    }
  },
  created: function () {
    this.fetchData()
  },
  beforeDestroy: function () {
    this.resetProductsFilter()
  }
}
</script>

<style lang="scss">
.listing {
  .filter {
    $vs-border-radius: 0;
    $vs-border-color: var(--primary);
    $vs-selected-bg: white;
    $vs-component-placeholder-color: var(--primary);
    $vs-state-active-bg: white;
    $vs-state-active-color: var(--primary);
    $vs-controls-color: var(--primary);
    $vs-component-line-height: 1.65;
    @import "vue-select/src/scss/vue-select.scss";
    @include style-select(true);

    margin-bottom: 35px;

    input {
      max-width: 170px;
      margin-right: 10px;
      border: 1px solid var(--primary);
      color: var(--primary);

      &::placeholder {
        color: var(--primary);
      }
    }

    &__reset {
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      margin-left: 20px;

      img {
        width: 22px;
        height: 18px;
      }

      span {
        padding-left: 5px;
      }
    }
  }

  .toolbar {
    padding-bottom: 35px;
    margin-bottom: 25px;
    border-bottom: 1px solid var(--primary);

    &__number_results {
      width: 170px;
    }

    &__download {
      display: flex;
      flex-grow: 1;

      a {
        margin-left: 30px;
        text-decoration: none;
      }

      &--excel {
        width: 20px;
        height: 20px;
        margin-top: -5px;
      }
    }

    &__sort {
      display: flex;
      align-items: center;

      $vs-border-radius: 0;
      $vs-border-color: transparent;
      $vs-selected-bg: white;
      $vs-component-placeholder-color: var(--primary);
      $vs-state-active-bg: white;
      $vs-state-active-color: var(--primary);
      $vs-controls-color: var(--primary);
      $vs-component-line-height: 1.65;
      @import "vue-select/src/scss/vue-select.scss";

      @include style-select(true);

      &__listview,
      &__gridview {
        cursor: pointer;
      }

      &__gridview {
        margin-right: 15px;
        margin-left: 45px;
      }
    }
  }

  .pagination-container {
    padding-bottom: 55px;
    border-bottom: 1px solid var(--primary);
    padding-top: 55px;
    border-top: 1px solid var(--primary);

    .pagination {

      .page-item {
        // &[role=separator] {
        //   display: none;
        // }

        &.disabled {
          visibility: hidden
        }

        &.active .page-link {
          background-color: transparent;
          color: var(--secondary);
        }

        .page-link {
          border: 0;
        }

        .start,
        .end {
          text-transform: uppercase;
        }

        .arrow {
          &-prev {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .product_row {
    border-bottom: 1px solid var(--primary);
    padding-top: 15px;
    padding-bottom: 15px;

    &:nth-child(1) {
      padding-top: 0;
    }
  }

  .grid {
    & > * {
      margin-bottom: 100px;
    }
  }
}
</style>
