<template>
  <b-container class="pagenotfound">
    <h1>{{$t('404 Not Found')}}</h1>
  </b-container>
</template>

<script>

export default {
  name: 'PageNotFound'
}
</script>

<style lang="scss">

</style>
