<template>
<div>
  <HeroSlider :slides="heroSlides" />
  <TextBlock :text="firstPublicTextBlock"/>
  <CenterModeSlider :slides="centerModeSlides" />
  <TextImage :title="ourGoalPitch.title" :text="ourGoalPitch.text" :image="ourGoalPitch.image_url" :button="ourGoalPitch.button" />
  <ProductSlider />
  <Labels />
  <Badges />
  <Callout :action="{ type: 'button', to: '/register', text: $t('REGISTER NOW')}" title="LOREM IPSUM DOLOR" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua" />
</div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import HeroSlider from '@/components/cms/HeroSlider'
import CenterModeSlider from '@/components/cms/CenterModeSlider'
import ProductSlider from '@/components/cms/ProductSlider'
import TextBlock from '@/components/cms/TextBlock'
import TextImage from '@/components/cms/TextImage'
import Labels from '@/components/cms/Labels'
import Badges from '@/components/cms/Badges'
import Callout from '@/components/cms/Callout'

export default {
  name: 'PublicFrontPage',
  components: {
    HeroSlider,
    TextBlock,
    TextImage,
    CenterModeSlider,
    ProductSlider,
    Labels,
    Badges,
    Callout
  },
  computed: {
    ...mapState({
      firstPublicTextBlock: state => state.cms.firstPublicTextBlock,
      ourGoalPitch: state => state.cms.ourGoalPitch,
      heroSlides: state => state.cms.heroSlides,
      centerModeSlides: state => state.cms.centerModeSlides
    })
  },
  methods: {
    ...mapActions({
      fetchPublicFrontPage: 'cms/fetchPublicFrontPage'
    })
  },
  created: function () {
    this.fetchPublicFrontPage()
  }
}
</script>

<style lang="scss">
</style>
