<template>
<div class="admin__userdata">
  <b-modal :id="this.modalID"
           :title="$t(this.modalTitle)"
           :ok-title="$t(this.modalOK)"
           :cancel-title="$t('Cancel')"
           @show="handleShow"
           @ok="handleOk">
    <div>
        {{$t('Are you sure you want to disable')}} {{ this.user.name }}?
    </div>
  </b-modal>
</div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'UserDisableModal',
  props: {
    user: [Object, Boolean]
  },
  computed: {
    ID () {
      if (!this.user) {
        return 'disable-'
      }
      return `${this.user.id}-`
    },
    modalID () {
      return `disable-user-${this.user.id}`
    },
    modalTitle () {
      return `${this.$t('Disable User')}: ${this.user.name}`
    },
    modalOK () {
      return this.$t('Disable')
    }
  },
  methods: {
    ...mapActions({
      deleteUser: 'admin/deleteUser',
      fetchUsers: 'admin/fetchUsers'
    }),
    handleOk (event) {
      event.preventDefault()
      this.deleteUser(this.user.id).then(response => {
        this.handleResponse(response)
      })
    },
    handleResponse (response) {
      if (response.status === 'ok') {
        // TO-DO: Display success notification
        this.fetchUsers()
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalID)
        })
      }
    },
    handleShow () {
      Object.assign(this.$data, this.$options.data.apply(this))
    }
  }
}
</script>

<style lang="scss">

</style>
