<template>
  <div class="history_product_row">
    <b-col cols="2">
      <span>{{ $t('Number')}}: {{ product.amount }}</span>
    </b-col>
    <div class="history_product_row__image">
      <b-card-img class="product_row__image" :src="product.image"></b-card-img>
    </div>
    <div class="history_product_row__category">
        <span>{{ product.display_format }}</span>
    </div>
    <div class="history_product_row__artist">
      <span class="author">{{ product.artist }}</span>
      <span class="title">{{ product.title }}</span>
      <div>
        <span class="label small">{{ product.label }} </span>
        <span class="ean small text-muted">{{ product.ean }}</span>
      </div>
    </div>
    <div class="history_product_row__sku">
      <b-img :src="require(`@/assets/icons/dots/${product.stock_type}.png`)" ></b-img> {{ product.sku }}
    </div>
    <div class="history_product_row__price">
      <span> € {{ product.price }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryProductRow',
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.history_product_row {
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--primary);

  @include cart-product-row();

  &__image {
    width: 90px !important;
    height: 90px !important;
  }

  &__artist {
    display: flex;
    flex-direction: column;
  }

  &__sku {
      flex-basis: auto;
  }

  &__price {
      flex-basis: unset;
      flex-grow: 1;
      text-align: right;
  }
}
</style>
