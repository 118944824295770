<template>
  <div class="product_blacklist">
    <a href="#" @click.stop="$bvModal.show(`product_blacklist_modal${upc}`)">
      <b-img v-if="round_icon" :src="require('@/assets/icons/x_round.png')"></b-img>
      <b-img v-else :src="require('@/assets/icons/x.png')"></b-img>
    </a>
      <b-modal :id="`product_blacklist_modal${upc}`" @ok="handleBlacklist" :title="`Blacklist: ${upc_type} ${upc}`">
        <label for="blacklist-end-date">{{$t('Blacklist untill')}}</label>
        <b-form-datepicker id="blacklist-end-date"
                           v-model="end_date"
                           :min="min_end_date"
                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                           class="mb-2"
                           ></b-form-datepicker>
        <label for="blacklist-note">{{$t('Note')}}</label>
        <b-form-textarea
          id="blacklist-note"
          v-model="note"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-button size="sm" variant="danger" @click="ok()">
              {{ $t('Blacklist') }}
            </b-button>
            <b-button size="sm" variant="secondary" @click="cancel()">
              {{ $t('Cancel') }}
            </b-button>
          </template>
      </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProductBlacklistControl',
  props: {
    upc: String,
    upc_type: String,
    round_icon: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      note: '',
      end_date: null,
      min_end_date: new Date()
    }
  },
  methods: {
    ...mapActions({
      blacklistProduct: 'products/blacklistProduct',
      fetchProducts: 'products/fetchProducts'
    }),
    handleBlacklist () {
      this.blacklistProduct({ upc: this.upc, upc_type: this.upc_type, note: this.note, end_date: this.end_date })
    }
  }
}
</script>

<style lang="scss">
.product_blacklist {
  text-align: center;
}
</style>
