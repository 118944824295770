const heroSlider = [
  {
    id: 1,
    pretitle: 'The Black Keys 1',
    title: 'Lets Rock',
    img: 'path_to_image',
    link: 'somelink'
  },
  {
    id: 2,
    pretitle: 'The Black Keys 2',
    title: 'Lets Rock 2',
    img: 'path_to_image',
    link: 'somelink'
  },
  {
    id: 3,
    pretitle: 'The Black Keys 3',
    title: 'Lets Rock 3',
    img: 'path_to_image',
    link: 'somelink'
  }
]

const firstPublicTextBlock = 'Since 2006 our wholesale department has been on of the leading experts in the sale of video and sound carriers. Our customers can choose between a wide range of CDs, DVDs, LPs and accessories from both well-known major and lesser-known independent labels. At the same time, the trade with records is still one of our strengths.'

const centerModeSlider = [
  {
    id: 1,
    img: 'http://...',
    title: 'title',
    alt: 'alt'
  },
  {
    id: 2,
    img: 'http://...',
    title: 'title',
    alt: 'alt'
  },
  {
    id: 3,
    img: 'http://...',
    title: 'title',
    alt: 'alt'
  },
  {
    id: 4,
    img: 'http://...',
    title: 'title',
    alt: 'alt'
  },
  {
    id: 5,
    img: 'http://...',
    title: 'title',
    alt: 'alt'
  },
  {
    id: 6,
    img: 'http://...',
    title: 'title',
    alt: 'alt'
  }
]

const ourGoalPitch = {
  title: 'Satisfied customers are our goal',
  text: 'Since 2006, our wholesale department has been one of the leading experts in the sale of video and sound carriers. Our customers can choose between a wide range of CDs, DVDs, LPs and accessories from both well- known major and lesser-known indepen- dent labels. At the same time, the trade with records is still one of our strengths.',
  image: require('@/assets/mock/slider/1.png'),
  button: {
    text: 'Jetzt Registrieren',
    action: 'boo'
  }
}

const labels = [
  {
    id: 1,
    name: 'Juicy Radio'
  },
  {
    id: 2,
    name: 'Unknown'
  },
  {
    id: 3,
    name: 'XL recording'
  },
  {
    id: 4,
    name: 'Sony'
  },
  {
    id: 5,
    name: 'Island'
  },
  {
    id: 6,
    name: 'SUB POP'
  }
]

const badges = [
  {
    id: 1,
    line1: 'ÜBER',
    line2: '100.000',
    line3: 'Produkte im Shop'
  },
  {
    id: 2,
    line1: 'MEHR ALS',
    line2: '18 Jahre',
    line3: 'Erfolg & Erfahrung'
  },
  {
    id: 3,
    line1: 'ÜBER',
    line2: '500',
    line3: 'zufriedene Kunden'
  },
  {
    id: 4,
    line1: 'IN NUR',
    line2: '0-1 Tage',
    line3: 'fertig für den Versand'
  }
]

const catalogs = {
  stock: 'url?',
  labels: [
    {
      id: 1,
      name: 'Warner',
      catalogs: [
        {
          id: 1,
          name: 'Exclusive Monthly Warner Vinyl Campaign',
          url: 'foobard'
        },
        {
          id: 2,
          name: 'Exclusive Warner New Releases',
          url: 'foobard'
        },
        {
          id: 3,
          name: 'Warner Total Cat',
          url: 'foobard'
        },
        {
          id: 4,
          name: 'Warner Total Cat',
          url: 'foobard'
        }
      ]
    },
    {
      id: 2,
      name: 'Universal',
      catalogs: [
        {
          id: 1,
          name: 'Exclusive Monthly Warner Vinyl Campaign',
          url: 'foobard'
        },
        {
          id: 2,
          name: 'Exclusive Warner New Releases',
          url: 'foobard'
        },
        {
          id: 3,
          name: 'Warner Total Cat',
          url: 'foobard'
        },
        {
          id: 4,
          name: 'Warner Total Cat',
          url: 'foobard'
        }
      ]
    },
    {
      id: 3,
      name: 'Sony',
      catalogs: [
        {
          id: 1,
          name: 'Exclusive Monthly Warner Vinyl Campaign',
          url: 'foobard'
        },
        {
          id: 2,
          name: 'Exclusive Warner New Releases',
          url: 'foobard'
        },
        {
          id: 3,
          name: 'Warner Total Cat',
          url: 'foobard'
        },
        {
          id: 4,
          name: 'Warner Total Cat',
          url: 'foobard'
        }
      ]
    },
    {
      id: 4,
      name: 'Music protection',
      catalogs: [
        {
          id: 1,
          name: 'Exclusive Monthly Warner Vinyl Campaign',
          url: 'foobard'
        },
        {
          id: 2,
          name: 'Exclusive Warner New Releases',
          url: 'foobard'
        },
        {
          id: 3,
          name: 'Warner Total Cat',
          url: 'foobard'
        },
        {
          id: 4,
          name: 'Warner Total Cat',
          url: 'foobard'
        }
      ]
    }
  ]
}

export { heroSlider, firstPublicTextBlock, centerModeSlider, ourGoalPitch, labels, badges, catalogs }
