<template>
  <b-container id="header">
    <AuthenticatedHeader v-if="isLoggedIn && $route.meta.header.type !== 'light'" />
    <PublicHeader v-else />
  </b-container>
</template>

<script>

import { mapState } from 'vuex'
import PublicHeader from './PublicHeader'
import AuthenticatedHeader from './AuthenticatedHeader'

export default {
  name: 'HeaderHolder',
  components: {
    PublicHeader,
    AuthenticatedHeader
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    })
  }
}
</script>

<style lang="scss">
  #header {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-top: 35px;
    margin-bottom: 35px;

    .logo {
      cursor: pointer;
    }
  }
</style>
