<template>
<div class="order_history">
  <div role="tablist" class="accordion">
    <b-card :key="order.id" v-for="order in this.orderHistory" no-body>
      <b-card-header block v-b-toggle="'accordion-' + order.id" header-tag="header" class="p-1" role="tab">
        <div>
        <span>{{ $t('Ordered')}}: {{ order.created_at | formatDate }}</span>
        <span style="display: block;" class="small text-muted">{{ $t('Order')}}: #{{ order.id }}</span>
        </div>
        <b-img :src="require('@/assets/icons/plus.png')"></b-img>
      </b-card-header>
      <b-collapse :id="'accordion-' + order.id" visible accordion="order_history-accordion" role="tabpanel">
        <b-card-body>
          <HistoryProductRow :key="ol.id" v-for="ol in order.lines" :product="ol" />
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
  <div v-if="orderHistoryDataState.ordersAvailableCount > orderHistory.length"
    class="d-flex justify-content-center">
  <b-button class="show_more" variant="white">{{ $t('Show More') }} <b-img :src="require('@/assets/icons/arrow.png')"></b-img></b-button>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import HistoryProductRow from '@/components/products/HistoryProductRow'

export default {
  name: 'OrderHistory',
  components: {
    HistoryProductRow
  },
  computed: {
    ...mapState({
      orderHistory: state => state.products.orderHistory,
      orderHistoryDataState: state => state.products.orderHistoryDataState
    })
  },
  methods: {
    ...mapActions({
      fetchOrderHistory: 'products/fetchOrderHistory'
    })
  },
  created: function () {
    this.fetchOrderHistory().then(res => {
      console.log('OrderHistory', this.orderHistory)
    })
  }
}
</script>

<style lang="scss">
.order_history {
  .accordion {
    border-bottom: 1px solid var(--primary);
    margin-bottom: 50px;

    .card {
      &-header {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
      }

      &-body {
        padding: 0;
      }
    }

    .product_row {
      margin-bottom: 35px;
    }
  }

  button.show_more {
    text-transform: uppercase;
    margin-bottom: 100px;

    img {
      transform: rotate(90deg);
    }
  }
}
</style>
