<template>
  <div class="cart_product_row">
    <div class="cart_product_row__image">
      <b-card-img class="product_row__image" :src="product.image"></b-card-img>
    </div>
    <div class="cart_product_row__category">
        <span>{{ product.display_format }}</span>
    </div>
    <div class="cart_product_row__artist">
      <span class="author">{{ product.artist }}</span>
      <span class="title">{{ product.title }}</span>
      <div>
        <span class="label small">{{ product.label }} </span>
        <span class="ean small text-muted">{{ product.upc }}</span>
      </div>
    </div>
    <div class="cart_product_row__pricelist">
      <template v-for="(stock, stockType) in product.pricelist">
        <div :key="`stock_${product.id}_sku_${stock.sku}`" class="">
          <b-img :src="require(`@/assets/icons/dots/${stockType}.png`)" ></b-img> {{ stock.sku }}
          <div v-if="'history' in lines" class="historic_product">
            {{ $t('PRODUCT TAKEN FROM B2B HISTORY - Last Update:') }} {{  new Intl.DateTimeFormat('de-de', {dateStyle: 'medium', timeStyle: 'short'}).format(new Date(product.updated_at)) }}
          </div>
        </div>
        <div :key="`stock_${product.id}_price_${stock.sku}`">
          <span v-if="isAdmin">
            <ProductPriceControl :value="cart[product.upc].product.pricelist[stockType].price"
                                 @input="updateProductPrice(stockType, $event)"
                                 :listValue="stock.list_price" />
          </span>
          <span v-else> € {{ stock.list_price }}</span>
        </div>
        <div :key="`stock_${product.id}_quantity_${stock.sku}`" :class="`stock_${stockType}`">
          <ProductQuantityControl :value="cart[product.upc].lines[stockType]"
                                  @input="updateProductQuantity(stockType, $event)"
                                  :min="0"
                                  :max="stock.num"
                                  :showAvailable="true" />
        </div>
        <div :key="`stock_${product.id}_end_${stock.sku}`" class="cart_product_row__summary">
          <span style="font-weight: bold;">€ {{ (stock.price * cart[product.upc].lines[stockType]).toFixed(2) }}</span>
          <b-img @click="handleRemove(stockType)" :src="require('@/assets/icons/x.png')"></b-img>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ProductQuantityControl from '@/components/products/ProductQuantityControl'
import ProductPriceControl from '@/components/products/ProductPriceControl'

export default {
  name: 'CartProductRow',
  components: {
    ProductQuantityControl,
    ProductPriceControl
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    lines: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      localPrice: {},
      localQuantity: {}
    }
  },
  computed: {
    ...mapState({
      isAdmin: state => state.auth.isAdmin,
      cart: state => state.products.cart
    })
  },
  methods: {
    ...mapActions({
      modifyCartAmount: 'products/modifyCartAmount',
      modifyCartPrice: 'products/modifyCartPrice'
    }),
    handleRemove (stockType) {
      // TO-DO: Decide on a confirmation popup?!
      this.modifyCartAmount({ upc: this.product.upc, stockType: stockType, amount: -this.cart[this.product.upc].lines[stockType] })
    },
    updateProductQuantity (stockType, event) {
      console.log('updateProductQuantity', stockType, event)
      this.modifyCartAmount({ upc: this.product.upc, stockType: stockType, amount: -(this.lines[stockType] - event) })
    },
    updateProductPrice (stockType, event) {
      console.log('updateProductPrice', stockType, event)
      this.modifyCartPrice({ upc: this.product.upc, stockType: stockType, price: event })
    }
  }
}
</script>

<style lang="scss">
.cart_product_row {
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--primary);

  @include cart-product-row();

  &__image {
    width: 90px !important;
    height: 90px !important;
  }

  &__artist {
    display: flex;
    flex-direction: column;
  }

  &__pricelist {
    .stock_permanent {
      input.nonzero {
        background-color: var(--stock_permanent);
      }
    }

    .stock_on_hand {
      input.nonzero {
        background-color: var(--stock_on_hand);
      }
    }

    .stock_limited {
      input.nonzero {
        background-color: var(--stock_limited);
      }
    }
  }

  .historic_product {
    font-weight: bold;
    color: var(--stock_historic);
  }
}
</style>
