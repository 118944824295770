<template>
<div>
  <b-container class="useraccount">

    <b-tabs align="end" lazy>
      <b-tab :title="$t('Order history')">
        <OrderHistory />
      </b-tab>
      <b-tab :title="$t('Favourites')">
        <Favourites />
      </b-tab>
      <b-tab :title="$t('Receipts')">
        <Reports :types="['invoice', 'creditnote']" />
      </b-tab>
      <b-tab :title="$t('Reports')">
        <Reports :types="['shelf', 'cancellation']" />
      </b-tab>
      <b-tab :title="$t('Userdata')">
        <UserData />
      </b-tab>
      <template v-slot:tabs-start>
        <h2 class="mr-auto">{{ $t('Your Account') }}</h2>
      </template>
      <template v-slot:tabs-end>
        <b-nav-item class="logout" href="#" @click="$router.push('/logout')">{{ $t('Logout') }} <b-img style="transform: rotate(-90deg);" :src="require('@/assets/icons/download.png')"></b-img></b-nav-item>
      </template>
    </b-tabs>
  </b-container>
  <Labels />
  <Badges />
  <Callout :action="calloutAction"
           :title="$t('EAN/BARCODE BESTELLUNG')"
           text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua" />
</div>
</template>

<script>

import { mapState } from 'vuex'

import Labels from '@/components/cms/Labels'
import Badges from '@/components/cms/Badges'
import Callout from '@/components/cms/Callout'
import OrderHistory from '@/components/account/OrderHistory'
import Favourites from '@/components/account/Favourites'
import UserData from '@/components/account/UserData'
import Reports from '@/components/account/Reports'

export default {
  name: 'UserAccount',
  components: {
    Labels,
    Badges,
    Callout,
    OrderHistory,
    Favourites,
    UserData,
    Reports
  },
  data () {
    return {
      calloutAction: {
        type: 'ean_order',
        text: this.$t('Nummer Eingeben')
      }
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    })
  }
}
</script>

<style lang="scss">
.useraccount {
  h2 {
    text-transform: uppercase;
  }

  .tabs {
    .nav-tabs {
      border: 0;
      margin-bottom: 30px;
    }

    .nav-link {
      border: 0;

      &.active {
        color: var(--secondary);
      }
    }

    .nav-item {
      &.logout {
        .nav-link {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
