<template>
<div class="admin__cms__frontpage">
  <div class="admin__cms__header d-flex flex-row justify-content-between mb-2">
    <h2>{{$t('FrontPage')}}</h2>
    <div>
        <b-button @click="handleSavePage()">{{$t('Save')}}</b-button>
    </div>
  </div>
  <section class="hero">
    <div class="d-flex flex-row">
      <h3>{{$t('Hero Slider')}}</h3><b-button size="sm" class="ml-2" @click="addSlide()">{{$t('Add Slide')}}</b-button>
    </div>
    <div
      class="slide d-flex flex-row"
      v-for="slide in this.heroSlides"
      :key="slide.id"
      >
      <div class="m-2 flex-grow-1">
        <b-form-input v-model="slide.pretitle" placeholder="Pretitle" class="mb-1"></b-form-input>
        <b-form-input v-model="slide.title" placeholder="Title" class="mb-1"></b-form-input>
        <b-form-input v-model="slide.link"
                      type="url"
                      placeholder="Link"
                      class="mb-1"
                      >
        </b-form-input>
        <b-form-file v-model="heroSliderLocalFiles[slide.id]"
                  class="mb-2"
                  @change="onHSFileChange($event, slide.id)"
                  accept="image/*"
                  >
        </b-form-file>
        <b-button variant="danger" @click="handleRemoveSlideHS(slide.id)">{{$t('Remove Slide')}}</b-button>
      </div>
      <b-img :src="heroSliderLocalURLs[slide.id] === undefined ? slide.image_url : heroSliderLocalURLs[slide.id]" />
    </div>
  </section>
  <section class="first_public_text_block">
    <h3>{{$t('First Text Block')}}</h3>
    <b-form-textarea
      id="textarea"
      v-model="firstPublicTextBlock"
      :placeholder="$t('Enter something...')"
      rows="10"
    ></b-form-textarea>
  </section>
  <section class="center_mode_slides">
    <div class="d-flex flex-row">
      <h3>{{$t('Center Mode Slider')}}</h3><b-button size="sm" class="ml-2" @click="addCenterModeSlide()">{{$t('Add Slide')}}</b-button>
    </div>
    <div v-for="slide in this.centerModeSlides"
         :key="slide.id"
         class="slide d-flex flex-row mb-2"
         >
      <div class="mr-2">
      <b-form-file v-model="cmSliderLocalFiles[slide.id]"
              class="mb-2"
              @change="onCMFileChange($event, slide.id)"
              accept="image/*"
              >
      </b-form-file>
      <b-button variant="danger" @click="handleRemoveSlideCM(slide.id)">{{$t('Remove Slide')}}</b-button>
      </div>
      <b-img :src="cmSliderLocalURLs[slide.id] === undefined ? slide.image_url : cmSliderLocalURLs[slide.id]" />

    </div>
  </section>
  <section id="our_goal">
    <h3>{{$t('Our Goal Pitch')}}</h3>
    <label for="our_goal_title">{{$t('Title')}}:</label>
    <b-form-input id="our_goal_title" v-model="ourGoalPitch.title"></b-form-input>
    <label for="our_goal_title">{{$t('Text')}}:</label>
    <b-form-textarea id="our_goal_text" v-model="ourGoalPitch.text" rows="10"></b-form-textarea>
    <label for="our_goal_button_action">{{$t('Button action')}}:</label>
    <b-form-input id="our_goal_button_action" v-if="ourGoalPitch.button" v-model="ourGoalPitch.button.action"></b-form-input>
    <label for="our_goal_button_text">{{$t('Button text')}}:</label>
    <b-form-input v-if="ourGoalPitch.button"
                  v-model="ourGoalPitch.button.text"
                  id="our_goal_button_text"
                  class="mb-2"
                  >
    </b-form-input>
      <b-form-file v-model="ourGoalPitchFile"
            class="mb-2"
            @change="onOGFileChange"
            accept="image/*"
            >
      </b-form-file>
    <b-img :src="ourGoalPitchURL === undefined ? ourGoalPitch.image : ourGoalPitchURL" />
  </section>
</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'

export default {
  name: 'CMSFrontPageAdmin',
  data () {
    return {
      heroSliderLocalFiles: [],
      heroSliderLocalURLs: [],
      cmSliderLocalFiles: [],
      cmSliderLocalURLs: [],
      ourGoalPitchFile: null,
      ourGoalPitchURL: null
    }
  },
  computed: {
    ...mapFields('cms', ['firstPublicTextBlock', 'ourGoalPitch']),
    ...mapMultiRowFields('cms', ['heroSlides', 'centerModeSlides'])
  },
  created () {
    this.fetchPublicFrontPage()
  },
  methods: {
    ...mapActions({
      addSlideToHeroSlider: 'cms/addSlideToHeroSlider',
      addSlideToCenterModeSlider: 'cms/addSlideToCenterModeSlider',
      savePublicFrontPage: 'cms/savePublicFrontPage',
      fetchPublicFrontPage: 'cms/fetchPublicFrontPage'
    }),
    ...mapMutations({
      removeSlideFromCenterModeSlider: 'cms/removeSlideFromCenterModeSlider',
      removeSlideFromHeroSlider: 'cms/removeSlideFromHeroSlider'
    }),
    getRandomInt (min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    addSlide () {
      this.addSlideToHeroSlider({ id: this.getRandomInt(1, 9999999), pretitle: '', title: '', link: '' })
    },
    addCenterModeSlide () {
      this.addSlideToCenterModeSlider({ id: this.getRandomInt(1, 9999999) })
    },
    handleSavePage () {
      this.savePublicFrontPage({ ourGoalPitch: this.ourGoalPitchFile, centerModeSlides: this.cmSliderLocalFiles, heroSlides: this.heroSliderLocalFiles })
    },
    // TO-DO: Rewrite this to a saner function
    handleRemoveSlideHS (id) {
      this.heroSliderLocalURLs = this.heroSliderLocalURLs.filter(elem => elem.id !== id)
      this.heroSliderLocalFiles = this.heroSliderLocalFiles.filter(elem => elem.id !== id)
      this.removeSlideFromHeroSlider(id)
    },
    handleRemoveSlideCM (id) {
      this.cmSliderLocalURLs = this.cmSliderLocalURLs.filter(elem => elem.id !== id)
      this.cmSliderLocalFiles = this.cmSliderLocalFiles.filter(elem => elem.id !== id)
      this.removeSlideFromCenterModeSlider(id)
    },
    onHSFileChange (e, index) {
      const file = e.target.files[0]
      this.heroSliderLocalURLs[index] = URL.createObjectURL(file)
    },
    onCMFileChange (e, index) {
      const file = e.target.files[0]
      this.cmSliderLocalURLs[index] = URL.createObjectURL(file)
    },
    onOGFileChange (e) {
      const file = e.target.files[0]
      this.ourGoalPitchURL = URL.createObjectURL(file)
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  margin-bottom: 1rem;
  padding-bottom: 0.3rem;
  border-bottom: 2px solid var(--dark);

  &#our_goal {
    label {
      font-size: 1rem;
      margin-bottom: 0.2rem;
      margin-top: 0.5rem;
    }
  }

  &.hero {
    .slide {
      img {
        width: 50%;
      }
    }
  }

  &.center_mode_slides {
    .slide {
      img {
        width: 100%;
      }
    }
  }
}

</style>
