<template>
  <b-container class="labels">
    <div class="labels__header">
      <div class="small_separator"> </div>
      <h2>{{ $t('Labels')}}</h2>
    </div>
    <b-row class="labels__list">
      <b-img v-for="label in labels" v-bind:key="label.id" :src="require(`@/assets/mock/labels/${label.id}.png`)" class="col-4 col-md-2"></b-img>
    </b-row>
    <div class="labels__more">
      <b-button variant="outline-primary">{{ $t('Show More') }}</b-button>
    </div>
  </b-container>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'Labels',
  computed: {
    ...mapState({
      labels: state => state.cms.label_highlights
    })
  },
  methods: {
    ...mapActions({
      fetchLabelHighlights: 'cms/fetchLabelHighlights'
    })
  },
  created: function () {
    if (this.labels.length === 0) {
      this.fetchLabelHighlights()
    }
  }
}
</script>

<style lang="scss">
.labels {
  margin-bottom: 95px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    text-transform: uppercase;
  }

  &__list {
    border-top: 1px solid var(--primary);
    border-bottom: 1px solid var(--primary);

    img {
      object-fit: none;
    }
  }

  &__more {
    display: flex;
    justify-content: center;

    button {
      border-top: 0;
      text-transform: uppercase;
    }
  }
}
</style>
