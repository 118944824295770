import {
  SET_BLACKLIST,
  SET_BLACKLIST_ISBUSY,
  SET_PRODUCT_GROUPS,
  SET_USERS,
  SET_USERS_CURRENT_PAGE,
  SET_USERS_FILTER,
  SET_USERS_ISBUSY,
  SET_ALL_USER_ROLES,
  SET_ADMIN_META,
  SET_FTPS,
  SET_FTPS_CURRENT_PAGE,
  SET_FTPS_FILTER,
  SET_FTPS_ISBUSY
} from '@/store/mutation-types'

import client from 'api-client'
import { debounce } from 'lodash'

export default {
  namespaced: true,
  state: {
    productGroupsData: [],
    productGroupsMeta: {
      isBusy: false
    },
    blacklistData: [],
    blacklistMeta: {
      isBusy: false
    },
    userRoles: [],
    usersData: [],
    usersMeta: {
      isBusy: false,
      count: 0,
      currentPage: 1,
      rowsPerPage: 20
    },
    usersFilter: {
      offset: 0,
      limit: 20,
      username: null,
      name: null,
      email: null,
      role: null
    },
    ftpsData: [],
    ftpsMeta: {
      isBusy: false,
      count: 0,
      currentPage: 1,
      rowsPerPage: 20
    },
    ftpsFilter: {
      offset: 0,
      limit: 20,
      username: null
    },
    adminMeta: {
      counts: {
        users: 0,
        products: 0,
        orders: 0
      }
    }
  },
  mutations: {
    [SET_PRODUCT_GROUPS] (state, payload) {
      state.productGroupsData = payload
      state.productGroupsMeta.isBusy = false
    },
    [SET_BLACKLIST] (state, payload) {
      state.blacklistData = payload
      state.blacklistMeta.isBusy = false
    },
    [SET_BLACKLIST_ISBUSY] (state, payload) {
      state.blacklistMeta.isBusy = payload
    },
    [SET_ALL_USER_ROLES] (state, payload) {
      state.userRoles = payload
    },
    [SET_USERS] (state, payload) {
      state.usersData = payload.users
      state.usersMeta.count = payload.count
      state.usersMeta.isBusy = false
    },
    [SET_USERS_ISBUSY] (state, payload) {
      state.usersMeta.isBusy = payload
    },
    [SET_USERS_CURRENT_PAGE] (state, payload) {
      state.usersMeta.currentPage = payload
      state.usersFilter.offset = (payload - 1) * state.usersMeta.rowsPerPage
    },
    [SET_USERS_FILTER] (state, data) {
      let resetOffset = true
      Object.entries(data).forEach(([key, value]) => {
        state.usersFilter[key] = value
        if (key === 'offset') {
          resetOffset = false
        }
      })
      if (resetOffset) {
        state.usersFilter.offset = 0
        state.usersMeta.currentPage = 1
      }
    },
    [SET_FTPS] (state, payload) {
      state.ftpsData = payload.eoc
      state.ftpsMeta.count = payload.count
      state.ftpsMeta.isBusy = false
    },
    [SET_FTPS_ISBUSY] (state, payload) {
      state.ftpsMeta.isBusy = payload
    },
    [SET_FTPS_CURRENT_PAGE] (state, payload) {
      state.ftpsMeta.currentPage = payload
      state.ftpsFilter.offset = (payload - 1) * state.ftpsMeta.rowsPerPage
    },
    [SET_FTPS_FILTER] (state, data) {
      let resetOffset = true
      Object.entries(data).forEach(([key, value]) => {
        state.ftpsFilter[key] = value
        if (key === 'offset') {
          resetOffset = false
        }
      })
      if (resetOffset) {
        state.ftpsFilter.offset = 0
        state.ftpsMeta.currentPage = 1
      }
    },
    [SET_ADMIN_META] (state, data) {
      state.adminMeta.counts = data.counts
    }
  },
  actions: {
    fetchProductGroups ({ commit }) {
      return client
        .fetchProductGroups()
        .then(groups => commit(SET_PRODUCT_GROUPS, groups))
    },
    createProductGroup ({ dispatch }, payload) {
      return client
        .createProductGroup(payload)
    },
    editProductGroup ({ dispatch }, payload) {
      return client
        .editProductGroup(payload)
    },
    deleteProductGroup ({ dispatch }, payload) {
      return client
        .deleteProductGroup(payload)
    },
    fetchBlacklist ({ commit, state }, payload) {
      commit(SET_BLACKLIST_ISBUSY, true)
      return client
        .fetchBlacklist()
        .then(blacklist => commit(SET_BLACKLIST, blacklist))
    },
    deleteFromBlacklist ({ commit, dispatch }, item) {
      console.log('deleteFromBlacklist store module', item)
      return client
        .deleteFromBlacklist(item.id)
        .then(response => {
          console.log('DeleteFromBlacklist response', response)
          dispatch('fetchBlacklist')
        })
    },
    fetchUsers ({ commit, state }, payload) {
      commit(SET_USERS_ISBUSY, true)
      return client
        .fetchUsers(state.usersFilter)
        .then(response => {
          commit(SET_USERS, response)
        })
    },
    eventuallyFetchUsers: debounce(({ dispatch }, payload) => {
      dispatch('fetchUsers')
    }, process.env.VUE_APP_INPUT_DEBOUNCE),
    fetchAllUserRoles ({ commit, state }) {
      return client
        .fetchAllUserRoles()
        .then(response => {
          commit(SET_ALL_USER_ROLES, response)
        })
    },
    setUsersCurrentPage ({ commit, dispatch }, payload) {
      commit(SET_USERS_CURRENT_PAGE, payload.currentPage)
      dispatch('fetchUsers')
    },
    setUsersFilter ({ commit, dispatch }, payload) {
      commit(SET_USERS_FILTER, payload)
    },
    createUser ({ dispatch }, payload) {
      return client
        .createUser(payload)
    },
    editUser ({ dispatch }, payload) {
      return client
        .editUser(payload)
    },
    deleteUser ({ dispatch }, payload) {
      return client
        .deleteUser(payload)
    },
    restoreUser ({ dispatch }, payload) {
      return client
        .restoreUser(payload)
    },
    fetchFTPs ({ commit, state }, payload) {
      commit(SET_FTPS_ISBUSY, true)
      return client
        .fetchFTPs(state.ftpsFilter)
        .then(response => {
          commit(SET_FTPS, response)
        })
    },
    eventuallyFetchFTPs: debounce(({ dispatch }, payload) => {
      dispatch('fetchFTPs')
    }, process.env.VUE_APP_INPUT_DEBOUNCE),
    setFTPsCurrentPage ({ commit, dispatch }, payload) {
      commit(SET_FTPS_CURRENT_PAGE, payload.currentPage)
      dispatch('fetchFTPs')
    },
    setFTPsFilter ({ commit, dispatch }, payload) {
      commit(SET_FTPS_FILTER, payload)
    },
    createFTP ({ dispatch }, payload) {
      return client
        .createFTP(payload)
    },
    editFTP ({ dispatch }, payload) {
      return client
        .editFTP(payload)
    },
    deleteFTP ({ dispatch }, payload) {
      return client
        .deleteFTP(payload)
    },
    restoreFTP ({ dispatch }, payload) {
      return client
        .restoreFTP(payload)
    },
    fetchAdminMeta ({ commit, state }, payload) {
      return client
        .fetchAdminMeta()
        .then(response => {
          commit(SET_ADMIN_META, response)
        })
    }
  }
}
