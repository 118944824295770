<template>
  <b-card class="product_card border-0 rounded-0">
    <div style="display: flex; flex-direction: column;">
 <!--   <div ref="price_popup" class="price__popup" v-click-outside="popupClickOutside">
      <span class="artist">{{ product.artist }}</span>
      <span class="title">{{ product.title }}</span>
      <div class="price__popup-table">
        <div class="srow head">
          <span>AB STÜCK</span>
          <span>PREIS</span>
        </div>
        <div class="srow">
          <span>50</span>
          <span>€ 11,99</span>
        </div>
        <div class="srow">
          <span>100</span>
          <span>€ 10,99</span>
        </div>
         <div class="srow">
          <span>150</span>
          <span>€ 11,99</span>
        </div>
         <div class="srow">
          <span>200</span>
          <span>€ 11,99</span>
        </div>
        <div class="srow">
          <span>250</span>
          <span>€ 11,99</span>
        </div>
      </div>
    </div> -->
      <div class="product_card__topinfo" style="">
        <span>{{ product.display_format }}</span>
     <!--    <b-img @click.stop="displayPricePopup" :src="require('@/assets/icons/info_round.png')"></b-img>
        <b-img @click.stop="displayPricePopup" :src="require('@/assets/icons/checkmark_round.png')"></b-img>
        <b-img @click.stop="displayPricePopup" :src="require('@/assets/icons/price_round.png')"></b-img> -->
        <div class="controls">
          <ProductBlacklistControl v-if="isAdmin" :upc="product.upc" upc_type="ean" />
          <ProductEditControl v-if="isAdmin" :product="product" />
        </div>
      </div>
      <b-card-img :src="product.image"></b-card-img>
    </div>
    <b-card-title>{{ product.artist }}</b-card-title>
    <b-card-text class="product_card__title">{{ product.title }}</b-card-text>
    <b-card-text class="product_card__label">{{ product.label }}</b-card-text>
    <b-card-text class="product_card__ean small text-muted">{{ product.upc }}</b-card-text>
    <div class="product_card__order" v-if="isLoggedIn">
      <ProductOrder :product="product" />
    </div>
  </b-card>
</template>

<script>

import { mapState } from 'vuex'

import ProductOrder from '@/components/products/ProductOrder'
import ProductBlacklistControl from '@/components/products/ProductBlacklistControl'
import ProductEditControl from '@/components/products/ProductEditControl'

export default {
  name: 'ProductCard',
  components: {
    ProductOrder,
    ProductBlacklistControl,
    ProductEditControl
  },
  props: {
    product: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      isAdmin: state => state.auth.isAdmin
    })
  },
  methods: {
    displayPricePopup () {
      if (this.$refs.price_popup.style.display === 'block') {
        this.$refs.price_popup.style.display = 'none'
      } else {
        this.$refs.price_popup.style.display = 'block'
      }
    },
    popupClickOutside () {
      if (this.$refs.price_popup.style.display === 'block') {
        this.$refs.price_popup.style.display = 'none'
      }
    }
  }
}
</script>

<style lang="scss">
.price__popup {
  display: none;
  position: absolute;
  top: 170px;
  width: 400px;
  background-color: white;
  z-index: 1000;
  box-shadow: 0px 0px 10px #00000029;
  padding: 30px;

  span {
    display: block;
    &.title {
      font-weight: bold;
    }
  }

  &-table {
    margin-top: 20px;
    border-bottom: 2px solid var(--primary);

    .head {
      border-bottom: 2px solid var(--primary);
      font-size: 0.8em;

      span {
        font-weight: bold;
      }
    }

    .srow {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
}

  .product_card {
    padding: 0;

    .card-body {
      padding: 0;
      padding-right: 30px;
    }

    .card-img {
      margin-top: 30px;
      width: 100%;
    }

    .card-title {
      margin-top: 30px;
      margin-bottom: 5px;
      font-size: 1rem;
      font-weight: 900;
    }

    &__title {
      font-size: 1rem;
      min-height: 3rem;
      font-weight: 500;
    }

    &__label {
      min-height: 1.5rem;
      font-weight: 500;
      font-size: 0.875rem;
      margin-bottom: 0.75rem;
    }

    &__ean {
      margin-bottom: 30px;
    }

    &__topinfo {
      span {
        font-weight: 900;
      }

      .controls {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        img {
          width: 25px;
          height: 25px;
          margin-left: 10px;

          cursor: pointer;
        }
      }
    }
  }
</style>
