<template>
<div class="staticpage container">
  <div v-html="staticPages[this.pslug]['de'].content"></div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CMSStaticPage',
  props: {
    pslug: String
  },
  computed: {
    ...mapState({
      staticPages: state => state.cms.staticPages
    })
  },
  mounted () {
    // TO-DO: Handle missing translations/fallback
    this.fetchStaticPage({ pslug: this.pslug, language: this.$i18n.locale }).then(response => console.log('staticPageFetched'))
  },
  methods: {
    ...mapActions({
      fetchStaticPage: 'cms/fetchStaticPage'
    })
  }
}
</script>

<style lang="scss">
</style>
