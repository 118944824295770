<template>
<b-container fluid class="footer">
  <b-container>
    <b-row class="footer__info">
      <b-col cols="5">
        <h4>{{$t('I-DI GmbH')}}</h4>
        <span>{{$t('Otto-Baumgartner-Strasse 10-11')}}</span>
        <span>{{$t('8055 Graz-Seiersberg, Austria')}}</span>
      </b-col>
      <b-col cols="3">
        <h4>{{$t('OPENING HOURS')}}</h4>
        <span>{{$t('Mo-Fr: 09:00-17:00')}}</span>
        <router-link :to="{ name: 'staticpage',  params: { pslug: 'team' } }">{{$t('Unser Team')}}<b-img :src="require('@/assets/icons/arrow_white.png')"></b-img> </router-link>
      </b-col>
      <b-col cols="4">
        <h4>{{$t('Unser Team')}}</h4>
        <h2><a href="tel:+43316826211200">+43 316 826211-200</a></h2>
        <router-link to="/kontakt">{{$t('Get in contact')}}<b-img :src="require('@/assets/icons/arrow_white.png')"></b-img></router-link>
      </b-col>
    </b-row>
    <b-row align-h="between">
      <b-col cols="5">
        <span class="footer__copyright">Copyright © 2020 I-DI GmbH</span>
      </b-col>
      <b-col cols="4">
        <div class="footer__links">
          <router-link :to="{ name: 'staticpage',  params: { pslug: 'agb' } }">{{$t('AGB')}}</router-link>
          <router-link :to="{ name: 'staticpage',  params: { pslug: 'datenschutz' } }">{{$t('DATENSCHUTZ')}}</router-link>
          <router-link :to="{ name: 'staticpage',  params: { pslug: 'impressum' } }">{{$t('Impressum')}}</router-link>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'FooterHolder',
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn
    })
  }
}
</script>

<style lang="scss">
.footer {
  background-color: var(--primary);
  padding-top: 80px;
  padding-bottom: 35px;
  color: white;

  a {
    color: white;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }

  h4 {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 30px;
  }

  &__info {
    margin-bottom: 70px;

    & > * {
      display: flex;
      flex-direction: column;
    }
  }

  &__links {
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
  }
}
</style>
