<template>
<b-card-group deck style="text-align: center;">
  <b-card bg-variant="light" :title="`${adminMeta.counts.users}`">
    <b-card-text>
      {{ $t('Users')}}
    </b-card-text>
  </b-card>
  <b-card bg-variant="light" :title="`${adminMeta.counts.products}`">
    <b-card-text>
      {{ $t('Products')}}
    </b-card-text>
  </b-card>
  <b-card bg-variant="light" :title="`${adminMeta.counts.orders}`">
    <b-card-text>
      {{ $t('Orders')}}
    </b-card-text>
  </b-card>
</b-card-group>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AdminPanelHome',
  computed: {
    ...mapState({
      adminMeta: state => state.admin.adminMeta
    })
  },
  methods: {
    ...mapActions({
      fetchAdminMeta: 'admin/fetchAdminMeta'
    })
  },
  created () {
    this.fetchAdminMeta()
  }
}
</script>

<style lang="scss">

</style>
