<template>
  <b-container class="textimage">
    <div class="small_separator"> </div>
    <b-row>
      <b-col class="textimage__content">
        <h2>{{ title }}</h2>
        <div class="textimage__text">{{ text }}</div>
        <b-button v-if="button">{{ button.text }}</b-button>
      </b-col>
      <b-col>
        <b-img :src="image"></b-img>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'TextImage',
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    image: {
      type: String
    },
    button: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.textimage {
  font-size: 20px;
  margin-bottom: 75px;
  text-transform: uppercase;

  h2 {
    margin-bottom: 30px;
  }

  &__content {
    padding-right: 120px;
  }

  &__text {
    margin-bottom: 40px;
  }
}

</style>
